@keyframes panel-slide-up {

    0%,
    30% {
        margin-top: 8px;
        opacity: 0
    }

    to {
        margin-top: 0;
        opacity: 1
    }
}

@keyframes panel-slide-down {

    0%,
    30% {
        margin-top: -8px;
        opacity: 0
    }

    to {
        margin-top: 0;
        opacity: 1
    }
}

@keyframes panel-slide-left {

    0%,
    30% {
        margin-left: 8px;
        opacity: 0
    }

    to {
        margin-left: 0;
        opacity: 1
    }
}

@keyframes panel-slide-right {

    0%,
    30% {
        margin-left: -8px;
        opacity: 0
    }

    to {
        margin-left: 0;
        opacity: 1
    }
}

.invoice-item-row-tax-section {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start
}

.invoice-item-row-tax-section__button,
.invoice-item-row-tax-section__button--open {
    margin-left: -8px
}

.invoice-item-row-tax-section__taxes {
    flex: 1
}

.invoice-item-row-tax-section__tax {
    display: flex;
    flex-flow: row nowrap
}

.invoice-item-row-tax-section__tax__add {
    display: flex;
    align-items: center;
    flex-flow: row;
    margin-left: auto
}

.invoice-item-row-tax-section__tax__add__label {
    margin-right: 10px
}

.invoice-item-row-tax-section__tax__add .wv-hotspot__connector,
.invoice-item-row-tax-section__tax__add .wv-hotspot__connector.is-open,
.invoice-item-row-tax-section__tax__add .wv-hotspot__wrapper {
    left: -20px !important
}

.invoice-item-row-tax-section__tax__add .wv-hotspot__content.is-open {
    left: -79px !important
}

.invoice-item-row-tax-section__tax__add .wv-select {
    width: 174px
}

.invoice-item-row-tax-section__tax__add .wv-select .wv-select__menu {
    padding: 0;
    overflow: hidden
}

.invoice-item-row-tax-section__tax__amount {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 132px;
    padding-left: 10px;
    padding-right: 4px
}

.invoice-item-row-tax-section__tax__remove {
    display: flex;
    align-items: center;
    width: 40px
}

.invoice-item-row-tax-section__tax__remove__cancel {
    margin-left: 4px
}

.invoice-item-row-tax-section__tax__remove__icon {
    margin-left: 8px;
    margin-top: 5px
}

.invoice-item-row-tax-section__tax__remove__icon .wv-svg-icon {
    cursor: pointer;
    color: #344551
}

.invoice-item-row-tax-section__tax__remove__icon .wv-svg-icon:hover {
    color: #d74242
}

.invoice-item-row-tax-section .wv-divider {
    margin: 15px 0
}

.invoice-item-row-tax-section__income-category {
    display: flex;
    align-items: center;
    float: right;
    margin-right: 179px;
    margin-bottom: 16px
}