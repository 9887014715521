@keyframes panel-slide-up {
  0%,
  30% {
    margin-top: 8px;
    opacity: 0;
  }

  to {
    margin-top: 0;
    opacity: 1;
  }
}

@keyframes panel-slide-down {
  0%,
  30% {
    margin-top: -8px;
    opacity: 0;
  }

  to {
    margin-top: 0;
    opacity: 1;
  }
}

@keyframes panel-slide-left {
  0%,
  30% {
    margin-left: 8px;
    opacity: 0;
  }

  to {
    margin-left: 0;
    opacity: 1;
  }
}

@keyframes panel-slide-right {
  0%,
  30% {
    margin-left: -8px;
    opacity: 0;
  }

  to {
    margin-left: 0;
    opacity: 1;
  }
}

.invoice-view-send-section {
  min-height: 50px;
}

.invoice-view-send-section.is-disabled {
  opacity: 0.4;
}

.invoice-view-send-section .wv-modal__content {
  padding: 12px 0 0;
}

.invoice-view-send-section__header {
  display: flex;
  align-items: center;
}
.invoice-view-send-section__header mat-icon {
  transform: rotate(-35deg);
  margin-left: 3px;
  margin-bottom: 3px;
}

.invoice-view-send-section__body {
  padding-left: 70px;
}

.invoice-view-send-section__body .mark-as-sent-error-notification {
  padding-top: 12px;
}

.invoice-view-send-section__options {
  flex: 1;
}

.invoice-view-send-section__options__label .wv-heading--subtitle {
  margin: 0;
}

.invoice-view-send-section__options__without-payments {
  display: flex;
  flex: 1;
  align-items: center;
}

.invoice-view-send-section__options__with-payments,
.invoice-view-send-section__options__without-payments__send-your-invoice {
  flex: 1;
}

.invoice-view-send-section__options__with-payments .wv-divider {
  margin: 11px 0;
}

.invoice-view-send-section__options__actions {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.invoice-view-send-section__options__actions__text {
  flex: 1;
  padding: 10px 0px;
}

.invoice-view-send-section__options__actions__text__last-viewed .wv-text {
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  flex-direction: row;
}

.invoice-view-send-section__options__actions__text__last-viewed img {
  height: auto;
  width: 20px;
  margin-right: 9px;
  margin-left: -1px;
}

.invoice-view-send-section__options__actions__text__last-viewed__date {
  display: flex;
  align-items: center;
}

.invoice-view-send-section__options__actions__buttons {
  display: flex;
  align-items: flex-start;
}

.invoice-view-send-section__options__actions__buttons__get-share-link,
.invoice-view-send-section__options__actions__buttons__mark-as-sent,
.invoice-view-send-section__options__actions__buttons__skip-sending {
  margin-left: 10px;
}

.invoice-view-send-section__options__actions__buttons__send-and-mark-as-sent {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0;
}

.invoice-view-send-section__options__actions__edit-sent {
  float: left;
}

.invoice-view-send-section__options__actions__edit-sent .wv-button--link {
  margin: 0;
  vertical-align: inherit;
}

.invoice-view-send-section__options__actions__edit-sent__clear {
  clear: both;
}

.invoice-view-send-section__options__actions__edit-sent__editor {
  float: right;
  margin: -13px 0;
}

.invoice-view-send-section__options__actions__edit-sent__editor .wv-datepicker {
  margin: 0 8px;
}

.invoice-view-send-section__options__actions__edit-sent__editor
  .wv-button--link,
.invoice-view-send-section__options__actions__edit-sent__editor .wv-text--body {
  vertical-align: middle;
}

.invoice-view-send-section__options__actions__edit-sent__error {
  margin-top: 5px;
}

.invoice-view-send-section__options__actions__edit-sent__error .wv-text--body {
  color: #c22929;
}

.invoice-view-send-section__options--disabled {
  align-self: center;
}

.invoice-view-send-section__invoice-skip-sending-text {
  margin: 7px 0;
}

.invoice-view-send-section__invoice-skip-sending-text__link {
  margin-top: -2px;
  padding-left: 5px;
}

.invoice-view-send-section__invoice-mark-as-sent-text__link {
  margin-top: -4px;
}

.invoice-view-send-section .variant .step-badge__inner .wv-svg-icon {
  margin-top: 2px;
}
