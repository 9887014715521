// Import Fuse core library
@import '@fuse/scss/core';

// Import app.theme.scss
@import 'app/app.theme';
@import 'node_modules/@mat-datetimepicker/core/datetimepicker/datetimepicker-theme.scss';
//@import 'dist/core/datetimepicker/datetimepicker-theme.scss';

// Using the $theme variable from the pre-built theme you can call the theming function
@include mat-datetimepicker-theme($theme);

@import 'app/main/main-building-blocks/svg/svg.scss';

@font-face {
  font-family: 'Averta';
  src: url('/assets/fonts/Averta-Regular.woff2') format('woff2');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Averta';
  src: url('/assets/fonts/Averta-SemiBold.woff2') format('woff2');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Averta';
  src: url('/assets/fonts/Averta-Bold.woff2') format('woff2');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: 'Averta';
  src: url('/assets/fonts/Averta-RegularItalic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
}

:host ::ng-deep {
  .cdk-overlay-pane {
    /* Do you changes here */
    max-width: 100vw !important;
  }
}

body {
  --device_pixel_ratio: 1;
}

.codeInput {
  width: 12% !important;
  margin: 0px 40px 0px 10px;
  text-align: center;
}

:host ::ng-deep {
  .my-custom-component-small {
    // add my-custom-component-small class to your mat-form-field element
    .mat-form-field-flex > .mat-form-field-infix {
      padding: 0.4em 0 !important;
    }

    .mat-form-field-label-wrapper {
      top: -1.5em;
    }

    &.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
      .mat-form-field-label {
      transform: translateY(-1.1em) scale(0.75);
      width: 133.33333%;
    }
  }
}

#invoice-add {
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.4em 0 !important;
  }
}

.page-layout.carded > .top-bg {
  height: 110px;
}

.page-layout.carded.fullwidth > .center > app-header > .header {
  width: 100%;
  height: 70px !important;
  min-height: 70px !important;
  max-height: 70px !important;
}

.mat-tab-body-wrapper {
  height: 100%;
  background: #f0fff9;

  .mat-tab-body-content {
    display: flex;
  }
}

.label-accent {
  .mat-tab-label:hover {
    background-color: #039be530;
  }
  .mat-tab-label-active {
    background-color: #039be51a;
  }
}
.label-warning {
  .mat-tab-label:hover {
    background-color: #ffc10730;
  }
  .mat-tab-label-active {
    background-color: #ffc1071a;
  }
}
.label-primary {
  .mat-tab-label:hover {
    background-color: #3c425230;
  }
  .mat-tab-label-active {
    background-color: #3c42521a;
  }
}
.label-success {
  .mat-tab-label:hover {
    background-color: #4caf5030;
  }
  .mat-tab-label-active {
    background-color: #4caf501a;
  }
}

.label-danger {
  .mat-tab-label:hover {
    background-color: #ef535030;
  }
  .mat-tab-label-active {
    background-color: #ef53501a;
  }
}
.mat-menu-item {
  a:hover {
    text-decoration: none;
  }
}

//===========================================

.mat-menu-panel {
  width: auto !important;
  max-width: 500px !important;
  .mat-menu-content {
    .mat-nav-list {
      max-height: 520px;
      .mat-list-item {
        height: 42px !important;
      }

      .mat-divider {
        margin: 15px 0;
      }
    }
  }
}
.subdomain-mat-menu {
  min-width: 300px !important;

  .mat-menu-content {
    overflow-x: hidden;
    .mat-nav-list {
      overflow-x: hidden;
      .mat-list-item {
        font-size: 18px !important;
      }
    }
  }
}

/*  */
//input underline color

.mat-form-field-underline {
  background-color: transparent !important;
}

.excel-form-field {
  line-height: 2.1;
  .mat-form-field-wrapper {
    padding-bottom: 0;
    .mat-form-field-flex {
      .mat-form-field-infix {
        border-top: none;
        padding: 0;
        .mat-form-field-label-wrapper {
          top: 0;
          padding-top: 0;
        }
      }
    }
    .mat-form-field-underline {
      height: 0;
      width: 0;
    }
  }
}
.end {
  justify-content: flex-end;
  display: flex;
}

.mr-4 {
  margin-right: 4em !important;
}

.sc-ul {
  .mat-form-field-underline {
    background-color: #222 !important;
    font-weight: normal;
  }
}

.s-2 {
  width: 2em;
}

.s-3 {
  width: 2.75em;
}

.s-4 {
  width: 3.5em;
}

.s-6 {
  width: 4.5em;
}

.s-8 {
  width: 6em;
}

.s-10 {
  width: 7em;
}

.s-12 {
  width: 7.5em;
}

.s-14 {
  width: 9.2em;
}
.s-16 {
  width: 10.1em;
}

.s-18 {
  width: 11em;
}

.s-20 {
  width: 13em;
}

.s-21 {
  width: 13vw;
}
.s-22 {
  width: 14vw;
}
.s-24 {
  width: 15vw;
}
.s-26 {
  width: 16vw;
}

.s-28 {
  width: 17vw;
}

.s-30 {
  width: 18vw;
}

.s-32 {
  width: 19vw;
}

.s-34 {
  width: 23em;
}
.s-36 {
  width: 25em;
}

.s-38 {
  width: 27em;
}

.s-40 {
  width: 29em;
}

.s-60 {
  width: 30vw;
}

.s-80 {
  width: 38vw;
}

.s-110 {
  width: 60vw;
}
.s-full {
  width: 100%;
}
.s-c {
  //  border: 2px solid red;
  text-align: center !important;
  font-size: 1vw;
  color: #222;
  font-weight: bold;

  .mat-form-field-wrapper {
    margin-bottom: -1em;
    margin-top: -1em;

    .mat-form-field-flex {
      margin-bottom: -0.4em;
    }
  }
}
.s-l {
  //  border: 2px solid red;
  text-align: left !important;
  font-size: 1vw;
  color: #222;
  font-weight: bold;

  .mat-form-field-wrapper {
    margin-bottom: -1em;
    margin-top: -1em;

    .mat-form-field-flex {
      margin-bottom: -0.4em;
    }
  }
}

.s-r {
  //  border: 2px solid red;
  text-align: right !important;
  font-size: 1vw;
  color: #222;
  font-weight: bold;

  .mat-form-field-wrapper {
    margin-bottom: -1em;
    margin-top: -1em;

    .mat-form-field-flex {
      margin-bottom: -0.4em;
    }
  }
}

.mat-form-field-wrapper {
  .mat-form-field-subscript-wrapper {
    width: 120%;
    margin-left: -10%;
    text-align: center;
  }
} //form field reset
.reset {
  font-weight: normal !important;
  padding-top: 1.2vw;
}
//SUBMIT BUTTON===================

#b-circle {
  width: 80px;
  height: 80px;
  position: relative;
}

.circle,
.sendButton {
  width: 40px;
  height: 40px;
  position: absolute;
  top: calc(40px - 20px) !important;
  left: calc(40px - 20px) !important;
  border-radius: 50%;
  background: inherit;
}
.outer-circle {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 15px;
  left: 15px;
  border-radius: 50%;
  background: white;
}

#b-circle button mat-icon {
  font-size: 22px;
  color: #fff;
  position: relative;
  top: 1px;
}

.sendButton {
  width: 80px;
  height: 80px;
  outline: none;
}

.sendButton {
  /* top: -3px; */
  top: 0px;
  vertical-align: middle;
  margin: 0 auto;
  width: 40px;
  height: 40px;
  position: absolute;
  top: calc(40px - 20px);
  left: calc(40px - 20px);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
}

/* styles.css | http://localhost:4200/styles.css */

#b-circle button mat-icon {
  /* top: calc(40px - 12px); */
  /* left: calc(40px - 11px); */
}

/* Element | http://localhost:4200/ */

.action-button {
  line-height: 36px;
  border-radius: 20px !important;
}
.sendButton > span:nth-child(1) {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  //text-shadow: 1px 1px gray;
}

@keyframes animate-zooming {
  from {
    width: 40px;
    height: 40px;
    position: absolute;
    top: calc(40px - 20px);
    left: calc(40px - 20px);
    background-color: #ffffff;
    border-radius: 50%;
    opacity: 1;
  }

  to {
    width: 80px;
    height: 80px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ffffff;
    border-radius: 50%;
    opacity: 0;
  }
}

.mat-tab-label-content {
  height: inherit;
  width: 100%;
}

.add_del_buttons {
  width: 10%;
  padding-top: 0.5vw;
  display: flex;
  align-items: center;
}

.disabled {
  color: rgba(0, 0, 0, 0.38) !important;
}
.disabled-field {
  .mat-form-field-outline {
    background: #efefef;
  }
}
.buttonDisabled {
  color: rgba(0, 0, 0, 0.3) !important;
  pointer-events: none;
}
.mat-radio-checked.mat-radio-disabled
  .mat-radio-label
  .mat-radio-container
  .mat-radio-outer-circle {
  border-color: #b0b0b0 !important;
  background-color: #b0b0b0 !important;
}

//syncfudion input underline
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(
    .e-float-input
  )::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(
    .e-float-input
  )::after {
  background-color: #039be5 !important;
}

//syncfusion autofill selection
input.e-input::selection,
textarea.e-input::selection,
.e-input-group input.e-input::selection,
.e-input-group.e-control-wrapper input.e-input::selection,
.e-float-input input::selection,
.e-float-input.e-control-wrapper input::selection,
.e-input-group textarea.e-input::selection,
.e-input-group.e-control-wrapper textarea.e-input::selection,
.e-float-input textarea::selection,
.e-float-input.e-control-wrapper textarea::selection {
  background-color: #039be5 !important;
}

.e-dropdownbase .e-list-item.e-active,
.e-dropdownbase .e-list-item.e-active.e-hover {
  background-color: #eee;
  border-color: #fff;
  color: #039be5;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #039be5 !important;
}

// This fixes https://github.com/angular/components/issues/4609
.mat-dialog-override {
  .mat-dialog-container {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
    padding: 0;

    > :first-child {
      background: whitesmoke;
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      //overflow: auto;
    }

    > :second-child {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .mat-dialog-title {
      display: flex;
      justify-content: space-between;
      padding: 20px;
    }

    .mat-dialog-content,
    div[mat-dialog-content] {
      display: flex;
      flex-direction: column; /* Changed from row to column */
      flex-grow: 1;
      justify-content: flex-start;
      overflow-y: auto; /* Allow vertical scrolling */
      overflow-x: hidden; /* Prevent horizontal scrolling */
      max-height: calc(
        100vh - 100px
      ); /* Adjust the height calculation to account for the title */
      margin: 0;
      padding: 0;
    }

    .mat-dialog-actions {
      display: flex;
      justify-content: center;
      margin: 0;

      button {
        min-width: 15%;
        max-width: 25%;
        border-radius: 20px;
      }
    }
  }
}

.mat-dialog-override-danger {
  mat-dialog-container {
    border: 0.5em solid #ef5350 !important;
    border-radius: 15px;
  }
}
.mat-dialog-white {
  .mat-dialog-container {
    > :first-child {
      background: white;
    }
  }
}

.efk-helpers-dialog {
  max-width: 90vw !important;
}
.men-doc-dialog-content {
  flex-direction: row !important;
  flex-grow: 1;
  padding: 0;

  .sidebar {
    width: 18%;
    display: flex;
    flex-direction: column;
    margin: 0.5%;

    .sidebar-2 {
      flex-grow: 1;
      margin: 2% 0 0 0;
    }
  }

  .content {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: 0.5%;

    mat-card {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: 0;

      div {
        mat-tab-group {
          overflow: hidden;
          height: 100%;
        }
      }
    }
  }
}

app-men-doc-dialog,
app-marks-numbers,
app-defk-marks-numbers,
app-defk-mentions-docs {
  display: flex;
  flex-grow: 1;

  mat-dialog-content,
  div[mat-dialog-content] {
    .mat-tab-label {
      background-color: #333;
      color: white;
    }

    /* focus style */
    .mat-tab-group.mat-primary .mat-tab-label:not(.mat-tab-disabled):focus,
    .mat-tab-group.mat-primary .mat-tab-link:not(.mat-tab-disabled):focus,
    .mat-tab-nav-bar.mat-primary .mat-tab-label:not(.mat-tab-disabled):focus,
    .mat-tab-nav-bar.mat-primary .mat-tab-link:not(.mat-tab-disabled):focus {
      background: #333;
    }

    /* ink bar style */
    .mat-tab-group.mat-primary .mat-ink-bar,
    .mat-tab-nav-bar.mat-primary .mat-ink-bar {
      background: #039be5;
      height: 5px;
    }

    /* Styles for the active tab label */
    .mat-tab-group.mat-primary .mat-tab-label.mat-tab-label-active {
      background-color: #000;
      color: white;
    }

    .mat-tab-label.mat-tab-header-pagination {
      background-color: #333;
      color: white;
    }

    .mat-tab-body-wrapper {
      width: 100%;
      margin: 0 auto;
      padding: 0 1em;
      background-color: #f5f5f5;
    }
  }
}

.dialog-close-button {
  font-size: 36px;
  line-height: 36px !important;
  width: 36px !important;
  height: 36px !important;
}
app-acc-dialog-content,
app-cancel-declaration,
contacts-contact-form-dialog {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.overlay-container {
  .toast-container.toast-bottom-center .ngx-toastr,
  .toast-container.toast-top-center .ngx-toastr {
    width: 35%;
    font-size: 1em;
  }
}

.mat-icon-button {
  //2.3vw = 40px
  padding: 0;
  min-width: 0;
  width: 2.3vw;
  height: 2.3vw;
  flex-shrink: 0;
  line-height: 2.3vw;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.warning {
  background: #ffc107 !important;
  //border-left-color: #FFC107;
  color: rgba(0, 0, 0, 0.87) !important;
}
.danger {
  background: #ef5350 !important;
  //   border-left-color: #B71C1C;
  color: white !important;
}
.success {
  background: #4caf50 !important;
  color: white !important;
  // border-left-color: #2E7D32;
}
.check {
  color: white;
  font-size: 24px;
  border-radius: 5px;
}

mat-error {
  font-size: 0.75vw;
}

.loading {
  width: 100%;
  position: absolute;
  top: 1.3%;
  left: 19%;
}
.loading-short {
  width: 60% !important;
}

.loading-white {
  color: white;
  mat-progress-bar {
    .mat-progress-bar-buffer {
      background: white !important;
    }
    /*  .mat-progress-bar-background {
      fill: white !important;
    } */
  }
  .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
    background-color: white !important;
  }
}

//=====================END PRINT MEDIA================

//========================================================

.text-bigger {
  font-size: 1.1vw;
}
.tab-document {
  margin: 0 auto;
  width: 100%;
}

.tab-document-normal {
  width: 85%;
  max-width: 1250px;

  #users,
  #goods-desc,
  .goods-desc-container,
  .declarationGrid,
  .s-c,
  .s-l,
  .s-r {
    font-size: calc(15px / var(--device_pixel_ratio)) !important;
  }
  .text-bigger {
    font-size: 17px !important;
  }
  .text-biggest {
    font-size: 19px !important;
  }
}
//=======================================
//INVOICES================
.invoice-details {
  .mat-form-field {
    margin-bottom: -15px;
  }
}

.customer-panel {
  width: 330px !important;
  max-width: 350px !important;
  max-height: 300px !important;
  margin-top: -5px;
  margin-left: -2px;
}

.product-panel {
  width: 800px !important;
  max-width: 800px !important;
  max-height: 300px !important;
  left: 31%;
  position: relative;

  .product-name {
    font-weight: bold;
  }

  .has-description {
    display: block;
    margin-bottom: -30px;
    margin-top: -10px;
  }

  .product-description {
    font-size: 13px;
    color: rgba(120, 120, 120, 1);
  }
}

.invoice-item-add-row:hover {
  background-color: #d4dde3;
}

.wv-table__cell {
  .mat-form-field {
    margin-bottom: -35px;

    .mat-form-field-wrapper {
      .mat-form-field-flex {
        background-color: white !important;
        padding: 0 0.75em 0 0.75em;
        //margin-top: -0.5em;
        position: relative;

        .mat-form-field-appearance-outline,
        .mat-form-field-outline {
          display: flex;
          position: absolute;
          top: 0.1em;

          left: 0;
          right: 0;
          bottom: 0.05em;
          pointer-events: none;
        }
      }
    }
  }
}

.wv-table--condensed .wv-table__header [class*='wv-table__cell'] {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.invoice-list-table,
.archive-list-table {
  mat-header-row {
    display: flex;
    border-width: 0;
    border-bottom-width: 3px;
    border-style: solid;
    align-items: center;
    box-sizing: border-box;
  }

  mat-header-row {
    min-height: 45px;
    padding: 0 0.5%;
  }

  .mat-row {
    height: 45px;
  }

  .mat-cell {
    font-size: 13px;
  }

  .mat-header-cell {
    font-size: 14px;
    font-weight: bold;
    color: black !important;
  }

  .mat-column-status {
    width: 40px !important;
  }

  .mat-column-total,
  .mat-column-buttons,
  .mat-column-amount_due {
    justify-content: right;
  }
}

.invoice-list-table.tab-unpaid .mat-header-row .mat-header-cell:first-child,
.invoice-list-table.tab-unpaid .mat-row .mat-cell:first-child {
  min-width: 78px;
  max-width: 10% !important;
}

.invoice-list-table.tab-unpaid .mat-header-row .mat-header-cell:nth-child(2),
.invoice-list-table.tab-unpaid .mat-row .mat-cell:nth-child(2) {
  // min-width: 138px
  // max-width: 12% !important;
}

.invoice-list-table.tab-unpaid .mat-header-row .mat-header-cell:nth-child(3),
.invoice-list-table.tab-unpaid .mat-row .mat-cell:nth-child(3) {
  //  max-width: 11% !important;

  //   min-width: 94px
}

.invoice-list-table.tab-unpaid .mat-header-row .mat-header-cell:nth-child(4),
.invoice-list-table.tab-unpaid .mat-row .mat-cell:nth-child(4) {
  //  max-width: 9% !important;

  //   min-width: 94px
}

.invoice-list-table.tab-unpaid .mat-header-row .mat-header-cell:nth-child(5),
.invoice-list-table.tab-unpaid .mat-row .mat-cell:nth-child(5) {
  // max-width: 26% !important;

  //  min-width: 176px
}

.invoice-list-table.tab-unpaid .mat-header-row .mat-header-cell:nth-child(6),
.invoice-list-table.tab-unpaid .mat-row .mat-cell:nth-child(6) {
  // max-width: 13% !important;
  padding-right: 2%;

  //  width: 125px
}

.invoice-list-table.tab-unpaid .mat-header-row .mat-header-cell:nth-child(7),
.invoice-list-table.tab-unpaid .mat-row .mat-cell:nth-child(7) {
  // max-width: 19% !important;
  justify-content: right;
  padding-right: 0;
  //  min-width: 164px
}

.invoice-list-table.tab-draft .mat-header-row .mat-header-cell:first-child,
.invoice-list-table.tab-draft .mat-row .mat-cell:first-child {
  // min-width: 78px;
  max-width: 10% !important;
}

.invoice-list-table.tab-draft .mat-header-row .mat-header-cell:nth-child(2),
.invoice-list-table.tab-draft .mat-row .mat-cell:nth-child(2) {
  // min-width: 138px
  // max-width: 12% !important;
}

.invoice-list-table.tab-draft .mat-header-row .mat-header-cell:nth-child(3),
.invoice-list-table.tab-draft .mat-row .mat-cell:nth-child(3) {
  // max-width: 11% !important;

  //   min-width: 94px
}

.invoice-list-table.tab-draft .mat-header-row .mat-header-cell:nth-child(4),
.invoice-list-table.tab-draft .mat-row .mat-cell:nth-child(4) {
  // max-width: 35% !important;

  //   min-width: 94px
}

.invoice-list-table.tab-draft .mat-header-row .mat-header-cell:nth-child(5),
.invoice-list-table.tab-draft .mat-row .mat-cell:nth-child(5) {
  // max-width: 14% !important;

  //  min-width: 176px
}

.invoice-list-table.tab-draft .mat-header-row .mat-header-cell:nth-child(6),
.invoice-list-table.tab-draft .mat-row .mat-cell:nth-child(6) {
  // max-width: 18% !important;
  justify-content: right;
  padding-right: 0;
  //  min-width: 164px
}

.invoice-list-table.tab-all .mat-header-row .mat-header-cell:first-child,
.invoice-list-table.tab-all .mat-row .mat-cell:first-child {
  min-width: 78px;
  max-width: 10% !important;
}

.invoice-list-table.tab-all .mat-header-row .mat-header-cell:nth-child(2),
.invoice-list-table.tab-all .mat-row .mat-cell:nth-child(2) {
  // min-width: 138px
  // max-width: 12% !important;
}

.invoice-list-table.tab-all .mat-header-row .mat-header-cell:nth-child(3),
.invoice-list-table.tab-all .mat-row .mat-cell:nth-child(3) {
  //max-width: 9% !important;

  //   min-width: 94px
}

.invoice-list-table.tab-all .mat-header-row .mat-header-cell:nth-child(4),
.invoice-list-table.tab-all .mat-row .mat-cell:nth-child(4) {
  // max-width: 26% !important;

  //   min-width: 94px
}

.invoice-list-table.tab-all .mat-header-row .mat-header-cell:nth-child(5),
.invoice-list-table.tab-all .mat-row .mat-cell:nth-child(5) {
  //max-width: 10% !important;

  //  min-width: 176px
}

.invoice-list-table.tab-all .mat-header-row .mat-header-cell:nth-child(6),
.invoice-list-table.tab-all .mat-row .mat-cell:nth-child(6) {
  //max-width: 15% !important;
  padding-right: 2%;

  //  width: 125px
}

.invoice-list-table.tab-all .mat-header-row .mat-header-cell:nth-child(7),
.invoice-list-table.tab-all .mat-row .mat-cell:nth-child(7) {
  //max-width: 19% !important;
  justify-content: right;
  padding-right: 0;
  //  min-width: 164px
}

.invoiceActionMenu {
  .mat-menu-content {
    .mat-nav-list {
      .mat-list-item {
        height: 42px !important;
      }

      .mat-divider {
        margin: 15px 0;
      }
    }
  }
}

.invoice-view-body {
  .mat-card {
    border-radius: 15px;
    box-shadow: none;
  }
}

//=====================================================================
//Used by the mat menu directice when an arrow is shown above the menu
.dialog-arrow {
  border-color: transparent;
  border-bottom-color: #fff;
  border-style: dashed dashed solid;
  border-width: 0 8.5px 8.5px;
  position: absolute;
  top: 37px;
  z-index: 1001;
  height: 0;
  width: 0;
  -webkit-animation: gb__a 25ms;
  animation: gb__a 25ms;
  filter: drop-shadow(0 -2px 1px rgba(0, 0, 0, 0.05));
}

@-webkit-keyframes gb__a {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes gb__a {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

//=====================================================================

.popover {
  font-family: 'Averta' !important;
  background-color: #515151;
  font-size: 14px !important;
  min-width: 360px;
  border-radius: 5px;
}

.popover-body {
  color: #fff !important;
  font-weight: 600;
}

.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^='top'] .arrow::after {
  border-top-color: #515151; // Any color here
}

.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^='bottom'] .arrow::after {
  border-bottom-color: #515151; // Any color here
}

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^='right'] .arrow::after {
  border-right-color: #515151; // Any color here
}

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^='left'] .arrow::after {
  border-left-color: #515151; // Any color here
}

//BYPASS BOOTSTRAP DEFAULT CONTAINER
#main {
  > .container {
    max-width: 100% !important;

    > .container {
      max-width: 100% !important;
    }
  }
}

// MAT AUTOCOMPLETE MULTI LINE
.mat-option {
  white-space: normal !important;
  min-height: 40px;
  line-height: 1.2em !important;
  height: auto !important;
  padding: 15px !important;
}
.mat-option-text {
  display: flex !important;
}
.mat-autocomplete-panel-above,
.mat-autocomplete-panel-below {
  max-width: 60% !important;
}

//used on export-info.ts
pre {
  font-family: 'Averta';
}
.flex {
  display: flex;
}

.tooltip {
  font-size: 13px !important;
  white-space: pre-line;
}
.tooltip-large {
  font-size: 15px !important;
  white-space: pre-wrap;

  max-width: 500px !important; /* or any width you prefer */
  width: auto;
}
.disabled,
.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled
  .mat-form-field-infix::after {
  color: #994a84 !important;
}

/*Arrival At Exit*/
.arrAtExitHea,
.eAitisiHea {
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0 0 9px 0 !important;
  }
  .mat-form-field {
    font-size: 13px !important;
  }
}

app-export {
  width: 100%;
}
/*Import */
app-import {
  .mat-tab-body-wrapper {
    height: 100%;
    background: #f0fff9;

    .mat-tab-body-content {
      display: flex;
    }
  }
}
@media print {
  .goods-accounting {
    app-accounting {
      .accounting-import {
        table {
          font-size: 9px !important;
        }
      }
    }
  }
}

.goods-accounting-over-9 {
  app-accounting {
    .accounting {
      table {
        grid-template-columns: repeat(3, max-content);
        grid-template-rows: repeat(4, 1.3em);
      }
    }
  }
}

.descCheckbox .mat-checkbox-label {
  white-space: break-spaces;
}

/*Contact Form*/
#country-select {
  mat-form-field {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        .mat-form-field-infix {
          display: flex !important;
        }
      }
    }
  }
}
#add-contact-button-md {
  width: 42px;
  height: 42px;
  .mat-button-wrapper {
    padding: 0px 2px 8px 0px !important;
  }

  .person_icon {
    font-size: 24px !important;
  }
}

.barcode {
  svg {
    width: 100%;
  }
  rect {
    // fill: blue;
  }
}

.divider-bold {
  border-top-color: rgba(0, 0, 0, 0.52) !important;
}
.atrGrid {
  #cu-placedate {
    top: 58% !important;
    left: 26% !important;
  }
  #cu-customs {
    top: 31% !important;
    left: 24% !important;
  }
  #cu-country {
    top: 37% !important;
    left: 38% !important;
  }
  #cu-form {
    top: 23.8% !important;
    left: 23% !important;
  }
  #cu-mrn {
    top: 23.8% !important;
    left: 50% !important;
  }
}
//apply the ScreenMedia class to whatever component you want hidden during print
// and the print class to the component you want to print
@media print {
  @page {
    size: A4 portrait;
    margin: 0mm 0mm 0mm 0mm;
  }
  .cdk-overlay-container {
    display: none !important;
  }
  .cdk-describedby-message-container {
    display: none !important;
  }
  #container-2 {
    background: white !important;
  }
  .toast-container {
    display: none !important;
  }
  #testdiv {
    display: none;
  }
  .container content {
    margin: 0 auto !important;
  }
  .wv-table {
    overflow: visible !important;
  }

  .wv-table__header {
    display: table-header-group;
  }

  tfoot {
    display: table-row-group;
  }

  .wv-table__row {
    page-break-inside: avoid !important;
  }

  .wv-table__cell {
    page-break-inside: avoid !important;
  }

  html,
  body,
  body app,
  vertical-layout-3,
  #main,
  #main .container,
  #main .container content,
  #main .container content export {
    display: block !important;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    left: 4mm;
    margin: 0 auto;
    text-rendering: optimizelegibility;
  }

  body.theme-default {
    background: #fff;
  }

  .container > .container > content {
    box-shadow: none !important;
  }

  .ScreenMedia {
    display: none !important;
  }

  .print {
    display: block !important;

    width: 100%;
    height: 297mm;

    .s-c,
    .s-r,
    .s-l {
      font-size: 12px;
    }

    //DECLARATION ADJUSTMENTS FOR PRINTING

    .edeimg {
      //margin-top: -0.5%;
      opacity: 1 !important;
    }
  }

  #fuse-splash-screen {
    display: none !important;
  }

  .mat-input-element:disabled {
    color: rgba(0, 0, 0, 0.6) !important;
  }

  //invoice readonly(pdf)
  .read-only-view .export-template {
    border: none !important;
  }

  /* Tarif Calculate Diaolg Print*/
  #printable,
  #printable * {
    visibility: visible;
  }
  #printable,
  #printableApplication {
    position: absolute;
    left: 0;
    top: 0;
  }
  .disabled {
    color: #000 !important;
  }
}

.printOnly {
  display: none !important;
}

/*----------------------------*/

.mat-form-field-outline,
.mat-form-field-appearence-outline,
.mat-checkbox-inner-container {
  background-color: white !important;
}

::ng-deep .mat-select-value.mat-select-value-text {
  white-space: normal !important;
}

.show-country-name {
  margin-top: 1.5px;
  z-index: -1;
  white-space: pre;
  overflow: hidden;
  margin-right: 5px;
}

.row-inactive {
  font-style: italic;
  td,
  .mat-cell {
    color: #999 !important;
  }
}

/*Font size of syncfusion's combobox*/
.e-ddl.e-input-group.e-control-wrapper .e-input {
  font-size: 15px;
}
.e-ddl.e-input-group .e-input-group-icon,
.e-ddl.e-popup,
.e-ddl.e-input-group.e-control-wrapper .e-input-group-icon:hover {
  font-size: 15px !important;
}

.e-daterangepicker.e-popup .e-presets .e-list-parent,
.e-bigger.e-small .e-daterangepicker.e-popup .e-presets .e-list-parent {
  max-width: 200px;
}

.e-daterangepicker.e-popup .e-presets .e-ul li.e-list-item,
.e-bigger.e-small .e-daterangepicker.e-popup .e-presets .e-ul li.e-list-item {
  font-size: 14px;
}
.e-daterangepicker.e-popup .e-presets,
.e-bigger.e-small .e-daterangepicker.e-popup .e-presets {
  max-height: 350px;
}

.autocomplete-excel-input {
  input {
    background: rgba(173, 216, 230, 0.27);
    font-size: 14px;
    font-weight: bold;
  }
}
.excel-input {
  background: rgba(173, 216, 230, 0.27);
}
.excel-yellow {
  background-color: yellow;
}
.excel-pink {
  background-color: pink;
}
.excel-green {
  background-color: #90ee9080;
}
.excel-disabled {
  background-color: #efefef;
  color: #8f8f8f;
}
/*

EMCS DEKF AND DAO MEN_DOC DIALOG

*/
.defk-prostheta-dialog,
.excise-men-doc-dialog,
.excise-vehicle-details {
  td {
    height: 35px;
    vertical-align: baseline;
  }
  .fourColumns {
    td:nth-child(1) {
      width: 57%;
    }
    td:nth-child(2) {
      width: 19%;
    }
    td:nth-child(3) {
      width: 0%;
    }
    td:nth-child(4) {
      width: 13%;
    }
  }

  .fourColumns-tasks {
    td:nth-child(1) {
      width: 24%;
    }
    td:nth-child(2) {
      width: 22%;
    }
    td:nth-child(3) {
      width: 20%;
    }
    td:nth-child(4) {
      width: 13%;
    }
  }

  .add_del_buttons {
    padding: 0;

    .mat-icon-button {
      width: 30px;
      height: 30px;
      line-height: 30px;
    }
  }
  .threeColumns {
    td:nth-child(1) {
      width: 30%;
    }
    td:nth-child(2) {
      width: 12%;
    }
    td:nth-child(3) {
      width: 58%;
    }
  }

  .twoColumns {
    td:nth-child(1) {
      width: 30%;
    }
    td:nth-child(2) {
      width: 70%;
    }
  }

  .twoColumns-goods {
    td:nth-child(1) {
      width: 85%;
    }
    td:nth-child(2) {
      width: 15%;
    }
  }

  tr {
    border-bottom: 1px solid #aaa;
  }
  table {
    width: 100%;
    padding: 10px;
    border-collapse: separate;
  }
  table,
  mat-expansion-panel-header {
    font-size: 13px !important;
  }

  mat-expansion-panel-header {
    .mat-expansion-indicator {
      padding: 20px 30px !important;
    }
  }

  mat-expansion-panel {
    width: 96% !important;
  }

  .dont-float {
    &.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
      .mat-form-field-label {
      transform: none;
      width: initial;
      opacity: 0;
    }
    &.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
      .mat-form-field-outline-gap {
      border-top-color: initial;
    }
    /* Not required, only used to reposition the label slightly
                  &.mat-form-field-appearance-outline .mat-form-field-label {
                    top: 1.54375em;
                    margin-top: -0.25em;
                  }
                  */
  }
  .mat-form-field-outline,
  .mat-form-field-appearence-outline {
    background-color: white !important;
  }
  .mat-form-field-label-wrapper {
    left: 14px;
    top: -20px;
  }
  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: -1em 0 !important;
  }
  mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-body {
    background: #f5f5f5;
  }
  .group {
    width: 100%;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    position: relative;
    border-radius: 3px;
    padding: 16px 16px 8px 16px;
    margin: 16px 0;
    border: 1px solid #333;

    h2 {
      position: absolute;
      top: -11px;
      left: 8px;
      margin: 0;
      padding: 0 8px;
      font-size: 14px;
      font-weight: 600;
      background-color: #f5f5f5;
    }

    h3 {
      font-size: 14px;
      font-weight: 600;
      margin: 24px 0 16px 0;
      padding: 0;

      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  .mat-checkbox-inner-container {
    width: 20px;
    height: 20px;
    margin-left: 2px !important;
  }
}
.excise-men-doc-dialog {
  .mat-tab-body-wrapper {
    background-color: #ececec73;
  }
}
.excise-men-doc-dialog,
app-defk-declaration {
  .ede-procedure {
    .proc-item {
      .proc1 {
        position: absolute;
        left: 10%;
      }

      .proc2 {
        position: absolute;
        left: 37.5%;
      }
    }
  }
}

//cars=================================
.cars-header {
  .mat-datepicker-toggle {
    button {
      height: 30px;
      line-height: 30px;
    }
  }
}

.general-applications .mat-dialog-content > :first-child {
  width: 100%;
}
.general-applications {
  .s-l,
  .s-c {
    font-size: 0.8vw;
  }
}

.distillation-licence-app {
  .s-l,
  .s-c {
    font-size: 18px !important;
  }
}

.tsipouroCalculationData {
  .mat-select-value-text {
    white-space: normal !important;
  }
}
