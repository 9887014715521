@keyframes panel-slide-up {

    0%,
    30% {
        margin-top: 8px;
        opacity: 0
    }

    to {
        margin-top: 0;
        opacity: 1
    }
}

@keyframes panel-slide-down {

    0%,
    30% {
        margin-top: -8px;
        opacity: 0
    }

    to {
        margin-top: 0;
        opacity: 1
    }
}

@keyframes panel-slide-left {

    0%,
    30% {
        margin-left: 8px;
        opacity: 0
    }

    to {
        margin-left: 0;
        opacity: 1
    }
}

@keyframes panel-slide-right {

    0%,
    30% {
        margin-left: -8px;
        opacity: 0
    }

    to {
        margin-left: 0;
        opacity: 1
    }
}

.invoice-view-payment-section.is-disabled {
    opacity: .4
}

.invoice-view-payment-section__header {
    display: flex;
    align-items: center
}

.invoice-view-payment-section__header .wv-heading--subtitle {
    margin: 0
}

.invoice-view-payment-section__content {
    padding-left: 70px
}

.invoice-view-payment-section__content .bullet-divider {
    margin: 0 8px;
    font-weight: 700
}

.invoice-view-payment-section__content--disabled {
    flex-grow: 1;
    align-self: center
}

.invoice-view-payment-section__content__info {
    margin-top: 15px
}

.invoice-view-payment-section__content__info__wrapper {
    display: flex;
    justify-content: space-between
}

.invoice-view-payment-section__content__invoice-advance-cta,
.invoice-view-payment-section__content__invoice-advance-cta__failed {
    margin-top: 20px
}

.invoice-view-payment-section__content__invoice-advance-cta__failed,
.invoice-view-payment-section__content__invoice-advance-cta__failed__failed {
    border-color: #c22929;
    margin-top: 20px
}

.invoice-view-payment-section__content__invoice-advance-cta__failed .wv-button--secondary,
.invoice-view-payment-section__content__invoice-advance-cta__failed .wv-text,
.invoice-view-payment-section__content__invoice-advance-cta__failed .wv-text--strong,
.invoice-view-payment-section__content__invoice-advance-cta__failed__failed .wv-button--secondary,
.invoice-view-payment-section__content__invoice-advance-cta__failed__failed .wv-text,
.invoice-view-payment-section__content__invoice-advance-cta__failed__failed .wv-text--strong {
    border-color: #c22929;
    color: #c22929
}

.invoice-view-payment-section__content__invoice-advance-cta__failed__wrapper,
.invoice-view-payment-section__content__invoice-advance-cta__wrapper {
    display: flex;
    justify-content: space-between
}

.invoice-view-payment-section__content__invoice-advance-cta__amount>.wv-svg-icon,
.invoice-view-payment-section__content__invoice-advance-cta__failed__amount>.wv-svg-icon {
    fill: #136acd;
    margin-bottom: -.5em;
    margin-right: 1em;
    height: 1.7em;
    width: 1.7em
}

.invoice-view-payment-section__content__invoice-advance-cta .wv-button--secondary,
.invoice-view-payment-section__content__invoice-advance-cta__failed .wv-button--secondary {
    background: #fff;
    margin-left: 7px
}

.invoice-view-payment-section__content__actions {
    display: flex;
    flex-direction: row
}

.invoice-view-payment-section__content__actions__call-to-action {
    flex-grow: 1
}

.invoice-view-payment-section__content__payment-details__description {
    margin-bottom: 12px
}