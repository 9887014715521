@keyframes panel-slide-up {

    0%,
    30% {
        margin-top: 8px;
        opacity: 0
    }

    to {
        margin-top: 0;
        opacity: 1
    }
}

@keyframes panel-slide-down {

    0%,
    30% {
        margin-top: -8px;
        opacity: 0
    }

    to {
        margin-top: 0;
        opacity: 1
    }
}

@keyframes panel-slide-left {

    0%,
    30% {
        margin-left: 8px;
        opacity: 0
    }

    to {
        margin-left: 0;
        opacity: 1
    }
}

@keyframes panel-slide-right {

    0%,
    30% {
        margin-left: -8px;
        opacity: 0
    }

    to {
        margin-left: 0;
        opacity: 1
    }
}

.invoice-view-payment-section__header {
    display: flex;
    align-items: center
}

.invoice-view-payment-section__header .wv-heading--subtitle {
    margin: 0
}

.invoice-view-payment-section__header__left {
    flex-grow: 1;
    display: flex;
    align-items: center
}

.invoice-view-payment-section__header__right {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center
}

.invoice-view-payment-section__header__right__button-right {
    margin-left: 10px
}

.invoice-view-payment-section__header__right .variant .wv-button--secondary {
    border: 1px solid #136acd;
    color: #136acd
}

.invoice-view-payment-section__header__right .variant .wv-button--secondary:hover {
    border: 1px solid #103265;
    color: #103265
}