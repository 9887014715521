/* You can add global styles to this file, and also import other style files */
/* @import '~bootstrap/dist/css/bootstrap.min.css'; */
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

@-webkit-keyframes panel-slide-up {
  0%,
  30% {
    margin-top: 8px;
    opacity: 0;
  }

  100% {
    margin-top: 0;
    opacity: 1;
  }
}

@keyframes panel-slide-up {
  0%,
  30% {
    margin-top: 8px;
    opacity: 0;
  }

  100% {
    margin-top: 0;
    opacity: 1;
  }
}

@-webkit-keyframes panel-slide-down {
  0%,
  30% {
    margin-top: -8px;
    opacity: 0;
  }

  100% {
    margin-top: 0;
    opacity: 1;
  }
}

@keyframes panel-slide-down {
  0%,
  30% {
    margin-top: -8px;
    opacity: 0;
  }

  100% {
    margin-top: 0;
    opacity: 1;
  }
}

@-webkit-keyframes panel-slide-left {
  0%,
  30% {
    margin-left: 8px;
    opacity: 0;
  }

  100% {
    margin-left: 0;
    opacity: 1;
  }
}

@keyframes panel-slide-left {
  0%,
  30% {
    margin-left: 8px;
    opacity: 0;
  }

  100% {
    margin-left: 0;
    opacity: 1;
  }
}

@-webkit-keyframes panel-slide-right {
  0%,
  30% {
    margin-left: -8px;
    opacity: 0;
  }

  100% {
    margin-left: 0;
    opacity: 1;
  }
}

@keyframes panel-slide-right {
  0%,
  30% {
    margin-left: -8px;
    opacity: 0;
  }

  100% {
    margin-left: 0;
    opacity: 1;
  }
}

/* .wv-close:focus,
.wv-close--medium:focus,
.wv-close--large:focus,
.wv-toast__close--medium:focus,
.wv-toast__close--simple:focus,
.wv-close:focus:active,
.wv-close--medium:focus:active,
.wv-close--large:focus:active,
.wv-toast__close--medium:focus:active,
.wv-toast__close--simple:focus:active,
input:focus:not(.is-disabled),
textarea:focus:not(.is-disabled),
[class*="wv-select"]:focus:not(.is-disabled),
.wv-checkbox [type="checkbox"]:focus~.wv-radiocheck--faux,
.wv-radio [type="checkbox"]:focus~.wv-radiocheck--faux,
.wv-checkbox [type="radio"]:focus~.wv-radiocheck--faux,
.wv-radio [type="radio"]:focus~.wv-radiocheck--faux {
    box-shadow: 0 0 0 3px rgba(19, 106, 205, 0.2)
} */
input.has-error:focus:not(.is-disabled),
.has-error input:focus:not(.is-disabled),
textarea.has-error:focus:not(.is-disabled),
.has-error textarea:focus:not(.is-disabled),
.has-error[class*='wv-select']:focus:not(.is-disabled),
.has-error [class*='wv-select']:focus:not(.is-disabled),
.has-error.wv-checkbox [type='checkbox']:focus ~ .wv-radiocheck--faux,
.has-error.wv-radio [type='checkbox']:focus ~ .wv-radiocheck--faux,
.has-error.wv-checkbox [type='radio']:focus ~ .wv-radiocheck--faux,
.has-error.wv-radio [type='radio']:focus ~ .wv-radiocheck--faux {
  box-shadow: 0 0 0 3px rgba(215, 66, 66, 0.4);
}

input.has-warning:focus:not(.is-disabled),
.has-warning input:focus:not(.is-disabled),
textarea.has-warning:focus:not(.is-disabled),
.has-warning textarea:focus:not(.is-disabled),
.has-warning[class*='wv-select']:focus:not(.is-disabled),
.has-warning [class*='wv-select']:focus:not(.is-disabled) {
  box-shadow: 0 0 0 3px rgba(220, 211, 24, 0.4);
}

input.has-success:focus:not(.is-disabled),
.has-success input:focus:not(.is-disabled),
textarea.has-success:focus:not(.is-disabled),
.has-success textarea:focus:not(.is-disabled),
.has-success[class*='wv-select']:focus:not(.is-disabled),
.has-success [class*='wv-select']:focus:not(.is-disabled) {
  box-shadow: 0 0 0 3px rgba(35, 199, 112, 0.4);
}

.no-focus:focus,
.no-focus:focus:active {
  box-shadow: none !important;
  outline: none !important;
}

.wv-close,
.wv-close--medium,
.wv-close--large,
.wv-toast__close--medium,
.wv-toast__close--simple {
  position: absolute;
  cursor: pointer;
  width: 16px;
  height: 16px;
  border: 0;
  background: none;
  padding: 0;
}

.wv-close:focus,
.wv-close--medium:focus,
.wv-close--large:focus,
.wv-toast__close--medium:focus,
.wv-toast__close--simple:focus,
.wv-close:focus:active,
.wv-close--medium:focus:active,
.wv-close--large:focus:active,
.wv-toast__close--medium:focus:active,
.wv-toast__close--simple:focus:active {
  outline: none;
}

.wv-close:hover::before,
.wv-close--medium:hover::before,
.wv-close--large:hover::before,
.wv-toast__close--medium:hover::before,
.wv-toast__close--simple:hover::before,
.wv-close:hover::after,
.wv-close--medium:hover::after,
.wv-close--large:hover::after,
.wv-toast__close--medium:hover::after,
.wv-toast__close--simple:hover::after {
  background-color: #4d6575;
}

.wv-close::before,
.wv-close--medium::before,
.wv-close--large::before,
.wv-toast__close--medium::before,
.wv-toast__close--simple::before,
.wv-close::after,
.wv-close--medium::after,
.wv-close--large::after,
.wv-toast__close--medium::after,
.wv-toast__close--simple::after {
  content: '';
  position: absolute;
  left: 7px;
  top: 2px;
  height: 12px;
  width: 2px;
  background-color: #8aa2b2;
}

.wv-close::before,
.wv-close--medium::before,
.wv-close--large::before,
.wv-toast__close--medium::before,
.wv-toast__close--simple::before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.wv-close::after,
.wv-close--medium::after,
.wv-close--large::after,
.wv-toast__close--medium::after,
.wv-toast__close--simple::after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.wv-close--medium,
.wv-toast__close--medium,
.wv-toast__close--simple {
  width: 24px;
  height: 24px;
}

.wv-close--medium::before,
.wv-toast__close--medium::before,
.wv-toast__close--simple::before,
.wv-close--medium::after,
.wv-toast__close--medium::after,
.wv-toast__close--simple::after {
  left: 11px;
  top: 5px;
  height: 16px;
}

.wv-close--large {
  width: 24px;
  height: 24px;
}

.wv-close--large::before,
.wv-close--large::after {
  left: 11px;
  top: 0;
  height: 24px;
}

body.prevent-scroll {
  overflow: hidden;
}

input:focus:not(.is-disabled),
textarea:focus:not(.is-disabled),
[class*='wv-select']:focus:not(.is-disabled) {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
}

.visible-phone-block,
.visible-phone-inline,
.visible-phone-inline-block,
.visible-tablet-block,
.visible-tablet-inline,
.visible-tablet-inline-block,
.visible-desktop-block,
.visible-desktop-inline,
.visible-desktop-inline-block {
  display: none !important;
}

@media (max-width: 480px) {
  .hidden-phone {
    display: none !important;
  }
}

@media (max-width: 1024px) {
  .hidden-tablet {
    display: none !important;
  }
}

@media (min-width: 1025px) {
  .hidden-desktop {
    display: none !important;
  }
}

@media (max-width: 480px) {
  .visible-phone-block {
    display: block !important;
  }
}

@media (max-width: 480px) {
  .visible-phone-inline {
    display: inline !important;
  }
}

@media (max-width: 480px) {
  .visible-phone-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 481px) and (max-width: 1024px) {
  .visible-tablet-block {
    display: block !important;
  }
}

@media (min-width: 481px) and (max-width: 1024px) {
  .visible-tablet-inline {
    display: inline !important;
  }
}

@media (min-width: 481px) and (max-width: 1024px) {
  .visible-tablet-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1025px) {
  .visible-desktop-block {
    display: block !important;
  }
}

@media (min-width: 1025px) {
  .visible-desktop-inline {
    display: inline !important;
  }
}

@media (min-width: 1025px) {
  .visible-desktop-inline-block {
    display: inline-block !important;
  }
}

/*
@font-face {
    font-family: "wv-icons";
    font-weight: normal;
    font-style: normal;
    src: url("fonts/wv-icons.woff") format("woff")
}

@font-face {
    font-family: "Caveat";
    font-weight: normal;
    font-style: normal;
    src: url("fonts/Caveat-Regular.woff2") format("woff2"),
        url("fonts/Caveat-Regular.woff") format("woff")
}
/* 

@font-face {
    font-family: "Averta";
    src: url("fonts/Averta-SemiboldItalic.woff2") format("woff2"),
        url("fonts/Averta-SemiboldItalic.woff") format("woff");
    font-weight: 600;
    font-style: italic
}


@font-face {
    font-family: "Averta";
    src: url("fonts/Averta-BoldItalic.woff2") format("woff2"),
        url("fonts/Averta-BoldItalic.woff") format("woff");
    font-weight: bold;
    font-style: italic
} */

@font-face {
  font-family: 'Averta';
  src: url('./../fonts/Averta-RegularItalic.woff2') format('woff2');
  /*       url("fonts/Averta-RegularItalic.woff") format("woff");
   */
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Averta';
  src: url('./../fonts/Averta-Regular.woff2') format('woff2');
  /*        url("fonts/Averta-Regular.woff") format("woff");
  */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Averta';
  src: url('./../fonts/Averta-SemiBold.woff2') format('woff2');
  /*         url("fonts/Averta-Semibold.woff") format("woff");
 */
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Averta';
  src: url('./../fonts/Averta-Bold.woff2') format('woff2');
  /*         url("fonts/Averta-Bold.woff") format("woff");
 */
  font-weight: bold;
  font-style: normal;
}

.wv-heading--title,
.wv-heading--subtitle,
.wv-heading--section-title,
.wv-heading--subsection-title,
.wv-form-legend,
.wv-tile-button__label,
.wv-nav--tertiary .wv-nav__heading {
  display: block;
  margin: 32px 0 16px;
  font-family: Averta, sans-serif;
  color: #1c252c;
  letter-spacing: -0.015em;
}

.wv-heading--title {
  font-size: 26px;
  line-height: 1.4;
  font-weight: bold;
}

@media (min-width: 480px) {
  .wv-heading--title {
    font-size: 33px;
  }
}

.wv-heading--subtitle,
.wv-heading--section-title,
.wv-modal__header__title,
.wv-fullscreen-modal__header__title,
.wv-nav--tertiary .wv-nav__heading,
.wv-toast__content__title,
.wv-business-switcher__business-menu__item__label {
  font-size: 20px;
  line-height: 1.4;
  font-weight: 600;
}

@media (min-width: 480px) {
  .wv-heading--subtitle,
  .wv-heading--section-title,
  .wv-modal__header__title,
  .wv-fullscreen-modal__header__title,
  .wv-nav--tertiary .wv-nav__heading,
  .wv-toast__content__title,
  .wv-business-switcher__business-menu__item__label {
    font-size: 23px;
  }
}

.wv-heading--subsection-title,
.wv-form-legend,
.wv-tile-button__label,
.wv-modal--danger .wv-modal__header__title,
.wv-workflow__task__title {
  font-size: 18px;
  line-height: 1.4;
  font-weight: 600;
}

@media (min-width: 480px) {
  .wv-heading--subsection-title,
  .wv-form-legend,
  .wv-tile-button__label,
  .wv-modal--danger .wv-modal__header__title,
  .wv-workflow__task__title {
    font-size: 19px;
  }
}

.wv-number,
.wv-number--positive,
.wv-number--negative {
  display: block;
  margin: 32px 0 16px;
  font-family: Averta, sans-serif;
  color: #1c252c;
  font-weight: normal;
}

.wv-number.wv-number--small,
.wv-number--small.wv-number--positive,
.wv-number--small.wv-number--negative {
  font-size: 20px;
  line-height: 1.4;
}

@media (min-width: 480px) {
  .wv-number.wv-number--small,
  .wv-number--small.wv-number--positive,
  .wv-number--small.wv-number--negative {
    font-size: 23px;
  }
}

.wv-number,
.wv-number--positive,
.wv-number--negative,
.wv-number.wv-number--medium,
.wv-number--medium.wv-number--positive,
.wv-number--medium.wv-number--negative {
  font-size: 26px;
  line-height: 1.4;
}

@media (min-width: 480px) {
  .wv-number,
  .wv-number--positive,
  .wv-number--negative,
  .wv-number.wv-number--medium,
  .wv-number--medium.wv-number--positive,
  .wv-number--medium.wv-number--negative {
    font-size: 33px;
  }
}

.wv-number.wv-number--large,
.wv-number--large.wv-number--positive,
.wv-number--large.wv-number--negative {
  font-size: 41px;
  line-height: 1.4;
  font-weight: normal;
}

@media (min-width: 480px) {
  .wv-number.wv-number--large,
  .wv-number--large.wv-number--positive,
  .wv-number--large.wv-number--negative {
    font-size: 69px;
  }
}

.wv-heading--pre-title,
.wv-text,
.wv-text--body,
.wv-text--reverse,
body,
.wv-list--plain--vertical,
.wv-list--plain--horizontal,
.wv-nav--secondary--horizontal .wv-nav__section,
.wv-nav--secondary .wv-nav__section,
.wv-nav--tertiary .wv-nav__section,
.wv-list,
.wv-list--bulleted,
.wv-list--numbered,
.wv-list--alpha,
.wv-list--icon,
.wv-button--link,
.wv-input,
.wv-textarea,
.wv-input--signature::-webkit-input-placeholder,
.wv-checkbox .wv-form-field__label,
.wv-radio .wv-form-field__label,
.wv-toggle .wv-toggle__label,
.wv-datepicker__day,
.wv-select__input,
.wv-form-field__label,
.wv-form-field__label--align-top,
.wv-form-field__read-only,
.wv-tile-button__description,
.wv-table,
.wv-table--fixed-width,
.wv-table--skeleton,
.wv-table--plain,
.wv-table--condensed,
.wv-tooltip,
.wv-tooltip--top,
.wv-tooltip--left,
.wv-tooltip--bottom,
.wv-tooltip--right,
.wv-modal,
.wv-modal--danger,
.wv-nav__menu--apps .wv-nav__menu__item__label,
.wv-toast__content__text,
.wv-business-switcher__panel__header__title {
  font-family: Averta, sans-serif;
  color: #1c252c;
  font-weight: normal;
}

.wv-heading--pre-title,
.wv-text,
.wv-text--body,
.wv-text--reverse,
body,
.wv-list--plain--vertical,
.wv-list--plain--horizontal,
.wv-nav--secondary--horizontal .wv-nav__section,
.wv-nav--secondary .wv-nav__section,
.wv-nav--tertiary .wv-nav__section,
.wv-list,
.wv-list--bulleted,
.wv-list--numbered,
.wv-list--alpha,
.wv-list--icon,
.wv-button--link,
.wv-input,
.wv-textarea,
.wv-input--signature:-ms-input-placeholder,
.wv-checkbox .wv-form-field__label,
.wv-radio .wv-form-field__label,
.wv-toggle .wv-toggle__label,
.wv-datepicker__day,
.wv-select__input,
.wv-form-field__label,
.wv-form-field__label--align-top,
.wv-form-field__read-only,
.wv-tile-button__description,
.wv-table,
.wv-table--fixed-width,
.wv-table--skeleton,
.wv-table--plain,
.wv-table--condensed,
.wv-tooltip,
.wv-tooltip--top,
.wv-tooltip--left,
.wv-tooltip--bottom,
.wv-tooltip--right,
.wv-modal,
.wv-modal--danger,
.wv-nav__menu--apps .wv-nav__menu__item__label,
.wv-toast__content__text,
.wv-business-switcher__panel__header__title {
  font-family: Averta, sans-serif;
  color: #1c252c;
  font-weight: normal;
}

.wv-heading--pre-title,
.wv-text,
.wv-text--body,
.wv-text--reverse,
body,
.wv-list--plain--vertical,
.wv-list--plain--horizontal,
.wv-nav--secondary--horizontal .wv-nav__section,
.wv-nav--secondary .wv-nav__section,
.wv-nav--tertiary .wv-nav__section,
.wv-list,
.wv-list--bulleted,
.wv-list--numbered,
.wv-list--alpha,
.wv-list--icon,
.wv-button--link,
.wv-input,
.wv-textarea,
.wv-input--signature::placeholder,
.wv-checkbox .wv-form-field__label,
.wv-radio .wv-form-field__label,
.wv-toggle .wv-toggle__label,
.wv-datepicker__day,
.wv-select__input,
.wv-form-field__label,
.wv-form-field__label--align-top,
.wv-form-field__read-only,
.wv-tile-button__description,
.wv-table,
.wv-table--fixed-width,
.wv-table--skeleton,
.wv-table--plain,
.wv-table--condensed,
.wv-tooltip,
.wv-tooltip--top,
.wv-tooltip--left,
.wv-tooltip--bottom,
.wv-tooltip--right,
.wv-modal,
.wv-modal--danger,
.wv-nav__menu--apps .wv-nav__menu__item__label,
.wv-toast__content__text,
.wv-business-switcher__panel__header__title {
  font-family: Averta, sans-serif;
  color: #1c252c;
  font-weight: normal;
}

.wv-text.wv-text--large,
.wv-button--large[class*='wv-button'],
.is-current .wv-wizard__task__title,
.wv-insight .wv-insight__content__value,
.wv-insight--large .wv-insight__content__value,
.wv-card--bank__title {
  font-size: 18px;
  line-height: 1.4;
}

@media (min-width: 480px) {
  .wv-text.wv-text--large,
  .wv-button--large[class*='wv-button'],
  .is-current .wv-wizard__task__title,
  .wv-insight .wv-insight__content__value,
  .wv-insight--large .wv-insight__content__value,
  .wv-card--bank__title {
    font-size: 19px;
  }
}

.wv-text.wv-text--large > .wv-svg-icon,
.wv-button--large[class*='wv-button'] > .wv-svg-icon,
.is-current .wv-wizard__task__title > .wv-svg-icon,
.wv-insight .wv-insight__content__value > .wv-svg-icon,
.wv-insight--large .wv-insight__content__value > .wv-svg-icon,
.wv-card--bank__title > .wv-svg-icon {
  position: relative;
  bottom: -3px;
}

.wv-text.wv-text--large > .wv-svg-icon--large,
.wv-button--large[class*='wv-button'] > .wv-svg-icon--large,
.is-current .wv-wizard__task__title > .wv-svg-icon--large,
.wv-insight .wv-insight__content__value > .wv-svg-icon--large,
.wv-insight--large .wv-insight__content__value > .wv-svg-icon--large,
.wv-card--bank__title > .wv-svg-icon--large {
  position: relative;
  bottom: -5px;
}

.wv-heading--pre-title,
.wv-text,
.wv-text--body,
.wv-list--plain--vertical,
.wv-list--plain--horizontal,
.wv-nav--secondary--horizontal .wv-nav__section,
.wv-nav--secondary .wv-nav__section,
.wv-nav--tertiary .wv-nav__section,
.wv-list,
.wv-list--bulleted,
.wv-list--numbered,
.wv-list--alpha,
.wv-list--icon,
.wv-button--google,
.wv-button--yahoo,
.wv-button--facebook,
.wv-button,
.wv-button--submit,
.wv-button--primary,
.wv-button--secondary,
.wv-button--danger,
.wv-button--secondary--danger,
.is-current .wv-workflow__task__action,
.is-complete .wv-workflow__task__action,
.wv-pagination__control,
.wv-button--link,
.wv-input,
.wv-textarea,
.wv-checkbox .wv-form-field__label,
.wv-radio .wv-form-field__label,
.wv-toggle .wv-toggle__label,
.wv-datepicker__panel,
.wv-select__input,
.wv-select__menu,
.wv-select__footer,
.wv-form-field__label,
.wv-form-field__label--align-top,
.wv-form-field__read-only,
.wv-tile-button__description,
.wv-table,
.wv-table--fixed-width,
.wv-table--skeleton,
.wv-table--plain,
.wv-table--condensed,
.wv-dropdown__menu,
.wv-dropdown__menu--right,
.wv-notify__content,
.wv-topbar__menu-toggle__label,
.wv-topbar--app .wv-topbar__business-name {
  font-size: 16px;
  line-height: 1.4;
}

@media (min-width: 480px) {
  .wv-heading--pre-title,
  .wv-text,
  .wv-text--body,
  .wv-list--plain--vertical,
  .wv-list--plain--horizontal,
  .wv-nav--secondary--horizontal .wv-nav__section,
  .wv-nav--secondary .wv-nav__section,
  .wv-nav--tertiary .wv-nav__section,
  .wv-list,
  .wv-list--bulleted,
  .wv-list--numbered,
  .wv-list--alpha,
  .wv-list--icon,
  .wv-button--google,
  .wv-button--yahoo,
  .wv-button--facebook,
  .wv-button,
  .wv-button--submit,
  .wv-button--primary,
  .wv-button--secondary,
  .wv-button--danger,
  .wv-button--secondary--danger,
  .is-current .wv-workflow__task__action,
  .is-complete .wv-workflow__task__action,
  .wv-pagination__control,
  .wv-button--link,
  .wv-input,
  .wv-textarea,
  .wv-checkbox .wv-form-field__label,
  .wv-radio .wv-form-field__label,
  .wv-toggle .wv-toggle__label,
  .wv-datepicker__panel,
  .wv-select__input,
  .wv-select__menu,
  .wv-select__footer,
  .wv-form-field__label,
  .wv-form-field__label--align-top,
  .wv-form-field__read-only,
  .wv-tile-button__description,
  .wv-table,
  .wv-table--fixed-width,
  .wv-table--skeleton,
  .wv-table--plain,
  .wv-table--condensed,
  .wv-dropdown__menu,
  .wv-dropdown__menu--right,
  .wv-notify__content,
  .wv-topbar__menu-toggle__label,
  .wv-topbar--app .wv-topbar__business-name {
    font-size: 16px;
  }
}

.wv-heading--pre-title > .wv-svg-icon,
.wv-text > .wv-svg-icon,
.wv-text--body > .wv-svg-icon,
.wv-list--plain--vertical > .wv-svg-icon,
.wv-list--plain--horizontal > .wv-svg-icon,
.wv-nav--secondary--horizontal .wv-nav__section > .wv-svg-icon,
.wv-nav--secondary .wv-nav__section > .wv-svg-icon,
.wv-nav--tertiary .wv-nav__section > .wv-svg-icon,
.wv-list > .wv-svg-icon,
.wv-list--bulleted > .wv-svg-icon,
.wv-list--numbered > .wv-svg-icon,
.wv-list--alpha > .wv-svg-icon,
.wv-list--icon > .wv-svg-icon,
.wv-button--google > .wv-svg-icon,
.wv-button--yahoo > .wv-svg-icon,
.wv-button--facebook > .wv-svg-icon,
.wv-button > .wv-svg-icon,
.wv-button--submit > .wv-svg-icon,
.wv-button--primary > .wv-svg-icon,
.wv-button--secondary > .wv-svg-icon,
.wv-button--danger > .wv-svg-icon,
.wv-button--secondary--danger > .wv-svg-icon,
.is-current .wv-workflow__task__action > .wv-svg-icon,
.is-complete .wv-workflow__task__action > .wv-svg-icon,
.wv-pagination__control > .wv-svg-icon,
.wv-button--link > .wv-svg-icon,
.wv-input > .wv-svg-icon,
.wv-textarea > .wv-svg-icon,
.wv-checkbox .wv-form-field__label > .wv-svg-icon,
.wv-radio .wv-form-field__label > .wv-svg-icon,
.wv-toggle .wv-toggle__label > .wv-svg-icon,
.wv-datepicker__panel > .wv-svg-icon,
.wv-select__input > .wv-svg-icon,
.wv-select__menu > .wv-svg-icon,
.wv-select__footer > .wv-svg-icon,
.wv-form-field__label > .wv-svg-icon,
.wv-form-field__label--align-top > .wv-svg-icon,
.wv-form-field__read-only > .wv-svg-icon,
.wv-tile-button__description > .wv-svg-icon,
.wv-table > .wv-svg-icon,
.wv-table--fixed-width > .wv-svg-icon,
.wv-table--skeleton > .wv-svg-icon,
.wv-table--plain > .wv-svg-icon,
.wv-table--condensed > .wv-svg-icon,
.wv-dropdown__menu > .wv-svg-icon,
.wv-dropdown__menu--right > .wv-svg-icon,
.wv-notify__content > .wv-svg-icon,
.wv-topbar__menu-toggle__label > .wv-svg-icon,
.wv-topbar--app .wv-topbar__business-name > .wv-svg-icon {
  position: relative;
  bottom: -4px;
}

.wv-heading--pre-title > .wv-svg-icon--large,
.wv-text > .wv-svg-icon--large,
.wv-text--body > .wv-svg-icon--large,
.wv-list--plain--vertical > .wv-svg-icon--large,
.wv-list--plain--horizontal > .wv-svg-icon--large,
.wv-nav--secondary--horizontal .wv-nav__section > .wv-svg-icon--large,
.wv-nav--secondary .wv-nav__section > .wv-svg-icon--large,
.wv-nav--tertiary .wv-nav__section > .wv-svg-icon--large,
.wv-list > .wv-svg-icon--large,
.wv-list--bulleted > .wv-svg-icon--large,
.wv-list--numbered > .wv-svg-icon--large,
.wv-list--alpha > .wv-svg-icon--large,
.wv-list--icon > .wv-svg-icon--large,
.wv-button--google > .wv-svg-icon--large,
.wv-button--yahoo > .wv-svg-icon--large,
.wv-button--facebook > .wv-svg-icon--large,
.wv-button > .wv-svg-icon--large,
.wv-button--submit > .wv-svg-icon--large,
.wv-button--primary > .wv-svg-icon--large,
.wv-button--secondary > .wv-svg-icon--large,
.wv-button--danger > .wv-svg-icon--large,
.wv-button--secondary--danger > .wv-svg-icon--large,
.is-current .wv-workflow__task__action > .wv-svg-icon--large,
.is-complete .wv-workflow__task__action > .wv-svg-icon--large,
.wv-pagination__control > .wv-svg-icon--large,
.wv-button--link > .wv-svg-icon--large,
.wv-input > .wv-svg-icon--large,
.wv-textarea > .wv-svg-icon--large,
.wv-checkbox .wv-form-field__label > .wv-svg-icon--large,
.wv-radio .wv-form-field__label > .wv-svg-icon--large,
.wv-toggle .wv-toggle__label > .wv-svg-icon--large,
.wv-datepicker__panel > .wv-svg-icon--large,
.wv-select__input > .wv-svg-icon--large,
.wv-select__menu > .wv-svg-icon--large,
.wv-select__footer > .wv-svg-icon--large,
.wv-form-field__label > .wv-svg-icon--large,
.wv-form-field__label--align-top > .wv-svg-icon--large,
.wv-form-field__read-only > .wv-svg-icon--large,
.wv-tile-button__description > .wv-svg-icon--large,
.wv-table > .wv-svg-icon--large,
.wv-table--fixed-width > .wv-svg-icon--large,
.wv-table--skeleton > .wv-svg-icon--large,
.wv-table--plain > .wv-svg-icon--large,
.wv-table--condensed > .wv-svg-icon--large,
.wv-dropdown__menu > .wv-svg-icon--large,
.wv-dropdown__menu--right > .wv-svg-icon--large,
.wv-notify__content > .wv-svg-icon--large,
.wv-topbar__menu-toggle__label > .wv-svg-icon--large,
.wv-topbar--app .wv-topbar__business-name > .wv-svg-icon--large {
  position: relative;
  bottom: -8px;
}

.wv-text--block-label,
.wv-dropdown__menu__head,
.wv-nav--tertiary .wv-nav__title,
.wv-text--hint,
.wv-text--fine-print,
.wv-divider--options,
.wv-divider--options--fluid,
.wv-input__message,
.wv-select__menu__option__description,
.wv-form-field__hint,
.wv-progress__label,
.wv-table__cell--nested,
.wv-timeline__cell--date,
.wv-business-switcher__legal__link,
.wv-text--code,
.wv-text.wv-text--small,
.wv-text--link--small,
.wv-button--small[class*='wv-button'],
[class*='wv-button--with-actions'].wv-button--small .wv-button,
.wv-button-group--segmented > .wv-button-group__button,
.wv-nav__promo .wv-nav__promo__button,
.wv-nav__promo--topbar .wv-nav__promo__button,
.wv-button--link--small,
.wv-datepicker__filters .wv-dropdown__menu .wv-dropdown__menu__link,
.wv-form-field.is-floating
  > .wv-form-field__wrapper
  > .wv-form-field__label
  [class*='wv-icon'],
.wv-tooltip,
.wv-tooltip--top,
.wv-tooltip--left,
.wv-tooltip--bottom,
.wv-tooltip--right,
.tippy-tooltip.wv-tooltip-theme,
.wv-notify--small .wv-notify__content-wrapper,
.wv-notify--small .wv-notify__content-wrapper .wv-notify__content,
.wv-wizard__task__title,
.wv-nav__menu--extras .wv-nav__menu__item__label,
.wv-business-switcher__toggle__info__user,
.wv-business-switcher__context-menu__item__link {
  font-size: 14px;
  line-height: 1.4;
}

.wv-text--block-label > .wv-svg-icon,
.wv-dropdown__menu__head > .wv-svg-icon,
.wv-nav--tertiary .wv-nav__title > .wv-svg-icon,
.wv-text--hint > .wv-svg-icon,
.wv-text--fine-print > .wv-svg-icon,
.wv-divider--options > .wv-svg-icon,
.wv-divider--options--fluid > .wv-svg-icon,
.wv-input__message > .wv-svg-icon,
.wv-select__menu__option__description > .wv-svg-icon,
.wv-form-field__hint > .wv-svg-icon,
.wv-progress__label > .wv-svg-icon,
.wv-table__cell--nested > .wv-svg-icon,
.wv-timeline__cell--date > .wv-svg-icon,
.wv-business-switcher__legal__link > .wv-svg-icon,
.wv-text--code > .wv-svg-icon,
.wv-text.wv-text--small > .wv-svg-icon,
.wv-text--link--small > .wv-svg-icon,
.wv-button--small[class*='wv-button'] > .wv-svg-icon,
[class*='wv-button--with-actions'].wv-button--small .wv-button > .wv-svg-icon,
.wv-button-group--segmented > .wv-button-group__button > .wv-svg-icon,
.wv-nav__promo .wv-nav__promo__button > .wv-svg-icon,
.wv-nav__promo--topbar .wv-nav__promo__button > .wv-svg-icon,
.wv-button--link--small > .wv-svg-icon,
.wv-datepicker__filters
  .wv-dropdown__menu
  .wv-dropdown__menu__link
  > .wv-svg-icon,
.wv-form-field.is-floating
  > .wv-form-field__wrapper
  > .wv-form-field__label
  [class*='wv-icon']
  > .wv-svg-icon,
.wv-tooltip > .wv-svg-icon,
.wv-tooltip--top > .wv-svg-icon,
.wv-tooltip--left > .wv-svg-icon,
.wv-tooltip--bottom > .wv-svg-icon,
.wv-tooltip--right > .wv-svg-icon,
.tippy-tooltip.wv-tooltip-theme > .wv-svg-icon,
.wv-notify--small .wv-notify__content-wrapper > .wv-svg-icon,
.wv-notify--small
  .wv-notify__content-wrapper
  .wv-notify__content
  > .wv-svg-icon,
.wv-wizard__task__title > .wv-svg-icon,
.wv-nav__menu--extras .wv-nav__menu__item__label > .wv-svg-icon,
.wv-business-switcher__toggle__info__user > .wv-svg-icon,
.wv-business-switcher__context-menu__item__link > .wv-svg-icon {
  position: relative;
  bottom: -5px;
}

.wv-datepicker__week-day {
  text-transform: uppercase;
  font-weight: bold;
}

.wv-text--block-label,
.wv-dropdown__menu__head,
.wv-nav--tertiary .wv-nav__title {
  font-weight: 600;
}

.wv-text--link,
.wv-text--link-external,
.wv-button--link,
.wv-datepicker__quicklinks .wv-datepicker__quicklink,
.wv-table__action,
.wv-business-switcher__context-menu__item__link {
  text-decoration: none;
  color: #136acd;
  font-weight: bold;
  cursor: pointer;
}

.wv-text--link:hover,
.wv-text--link-external:hover,
.wv-button--link:hover,
.wv-datepicker__quicklinks .wv-datepicker__quicklink:hover,
.wv-table__action:hover,
.wv-business-switcher__context-menu__item__link:hover {
  color: #1f7eea;
  text-decoration: underline;
}

.wv-text--block-label,
.wv-dropdown__menu__head,
.wv-nav--tertiary .wv-nav__title,
.wv-text--hint,
.wv-text--fine-print,
.wv-divider--options,
.wv-divider--options--fluid,
.wv-input__message,
.wv-select__menu__option__description,
.wv-form-field__hint,
.wv-progress__label,
.wv-table__cell--nested,
.wv-timeline__cell--date,
.wv-business-switcher__legal__link {
  color: #4d6575;
}

.wv-text--code {
  font-family: Consolas, Monaco, 'Courier New', Courier, monospace;
}

.wv-text--danger {
  color: #c22929;
}

.wv-text--highlight {
  background-color: #f5f1a3;
}

.wv-input--signature {
  font-family: Caveat, cursive;
  font-size: 26px;
  line-height: 1.4;
}

@media (min-width: 480px) {
  .wv-input--signature {
    font-size: 33px;
  }
}

.wv-heading--subtitle,
.wv-heading--section-title {
  color: #1c252c;
}

.wv-heading--subtitle {
  color: #718fa2;
  font-weight: normal;
}

.wv-heading--subsection-title {
  color: #1c252c;
}

.wv-heading--title + .wv-heading--subtitle {
  margin-top: 0;
}

.wv-heading--pre-title {
  display: block;
  color: #7f309c;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 16px;
  letter-spacing: 0.05em;
}

/* .wv-text,
.wv-text--body {
    margin: 16px 0
} */
/* .wv-text--link-external {
    background-image: url(images/icon--external-link.svg);
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 11px 11px;
    padding-right: 15px;
    display: inline-block
}
 */
.wv-text--hint .wv-text--link-external,
.wv-text--fine-print .wv-text--link-external,
.wv-text.wv-text--small .wv-text--link-external {
  background-size: 10px 10px;
  padding-right: 14px;
}

.wv-text.wv-text--large .wv-text--link-external {
  background-size: 13px 13px;
  padding-right: 18px;
}

.wv-text--hint .wv-text--link,
.wv-text--hint .wv-text--link-external,
.wv-text--fine-print .wv-text--link,
.wv-text--fine-print .wv-text--link-external,
.wv-text--danger .wv-text--link,
.wv-text--danger .wv-text--link-external {
  color: inherit;
  font-size: inherit;
  text-decoration: underline;
}

.wv-text--hint .wv-text--link:hover,
.wv-text--hint .wv-text--link-external:hover,
.wv-text--fine-print .wv-text--link:hover,
.wv-text--fine-print .wv-text--link-external:hover,
.wv-text--danger .wv-text--link:hover,
.wv-text--danger .wv-text--link-external:hover {
  text-decoration: none;
}

.wv-text--strong {
  font-weight: bold;
}

.wv-text--emphasized {
  font-style: italic;
}

.wv-text--reverse {
  color: white;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

.wv-number,
.wv-number--positive,
.wv-number--negative {
  margin: 0;
}

.wv-number--positive {
  color: #18864b;
}

.wv-number--negative {
  color: #c22929;
}

.wv-icon--add,
.wv-icon--attachment,
.wv-icon--attention,
.wv-icon--back,
.wv-icon--cancel,
.wv-icon--check,
.is-complete .wv-workflow__task__number:empty::before,
.is-complete .wv-wizard__task__number:empty::before,
.wv-icon--close-menu,
.wv-icon--collapse,
.wv-icon--comment,
.wv-icon--creditcard,
.wv-icon--date,
.wv-icon--decreased,
.wv-icon--delete,
.wv-icon--desktop,
.wv-icon--document,
.wv-icon--download,
.wv-icon--duplicate,
.wv-icon--edit,
.wv-icon--error,
.wv-icon--expand,
.wv-icon--facebook,
.wv-icon--forward,
.wv-icon--google,
.wv-icon--help,
.wv-icon--home,
.wv-icon--image,
.wv-icon--increased,
.wv-icon--info,
.wv-notify__icon-holder .wv-icon,
.wv-icon--link,
.wv-icon--linkedin,
.wv-icon--location,
.wv-icon--messages,
.wv-icon--mobile,
.wv-icon--more-actions,
.wv-icon--navigation,
.wv-icon--open-menu,
.wv-select__input__icon:empty,
.wv-icon--print,
.wv-icon--reminder,
.wv-icon--search,
.wv-icon--secure,
.wv-icon--send,
.wv-icon--settings,
.wv-icon--star,
.wv-icon--success,
.wv-notify--success .wv-notify__icon-holder .wv-icon,
.wv-icon--tablet,
.wv-icon--tag,
.wv-icon--time,
.wv-icon--twitter,
.wv-icon--undo,
.wv-icon--user,
.wv-icon--video,
.wv-icon--wave,
.wv-icon--yahoo {
  display: inline-block;
  font-family: 'wv-icons';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: inherit;
  vertical-align: top;
  color: inherit;
  font-size: 18px;
}

[class*='wv-icon--bank'],
[class*='wv-icon--third-party'] {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

[class*='wv-icon--payment-method'] {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
}

.wv-svg-icon,
.wv-svg-icon--large {
  fill: currentColor;
}

.wv-svg-icon {
  width: 20px;
  height: 20px;
}

.wv-svg-icon--large {
  width: 26px;
  height: 26px;
}

.wv-icon--add::before {
  content: '';
}

.wv-icon--attachment::before {
  content: '';
}

.wv-icon--attention::before {
  content: '';
}

.wv-icon--back::before {
  content: '';
}

.wv-icon--cancel::before {
  content: '';
}

.wv-icon--check::before,
.is-complete .wv-workflow__task__number:empty::before,
.is-complete .wv-wizard__task__number:empty::before {
  content: '';
}

.wv-icon--close-menu::before {
  content: '';
}

.wv-icon--collapse::before {
  content: '';
}

.wv-icon--comment::before {
  content: '';
}

.wv-icon--creditcard::before {
  content: '';
}

.wv-icon--date::before {
  content: '';
}

.wv-icon--decreased::before {
  content: '';
}

.wv-icon--delete::before {
  content: '';
}

.wv-icon--desktop::before {
  content: '';
}

.wv-icon--document::before {
  content: '';
}

.wv-icon--download::before {
  content: '';
}

.wv-icon--duplicate::before {
  content: '';
}

.wv-icon--edit::before {
  content: '';
}

.wv-icon--error::before {
  content: '';
}

.wv-icon--expand::before {
  content: '';
}

.wv-icon--facebook::before {
  content: '';
}

.wv-icon--forward::before {
  content: '';
}

.wv-icon--google::before {
  content: '';
}

.wv-icon--help::before {
  content: '';
}

.wv-icon--home::before {
  content: '';
}

.wv-icon--image::before {
  content: '';
}

.wv-icon--increased::before {
  content: '';
}

.wv-icon--info::before,
.wv-notify__icon-holder .wv-icon::before {
  content: '';
}

.wv-icon--link::before {
  content: '';
}

.wv-icon--linkedin::before {
  content: '';
}

.wv-icon--location::before {
  content: '';
}

.wv-icon--messages::before {
  content: '';
}

.wv-icon--mobile::before {
  content: '';
}

.wv-icon--more-actions::before {
  content: '';
}

.wv-icon--navigation::before {
  content: '';
}

.wv-icon--open-menu::before,
.wv-select__input__icon:empty::before {
  content: '';
}

.wv-icon--print::before {
  content: '';
}

.wv-icon--reminder::before {
  content: '';
}

.wv-icon--search::before {
  content: '';
}

.wv-icon--secure::before {
  content: '';
}

.wv-icon--send::before {
  content: '';
}

.wv-icon--settings::before {
  content: '';
}

.wv-icon--star::before {
  content: '';
}

.wv-icon--success::before,
.wv-notify--success .wv-notify__icon-holder .wv-icon::before {
  content: '';
}

.wv-icon--tablet::before {
  content: '';
}

.wv-icon--tag::before {
  content: '';
}

.wv-icon--time::before {
  content: '';
}

.wv-icon--twitter::before {
  content: '';
}

.wv-icon--undo::before {
  content: '';
}

.wv-icon--user::before {
  content: '';
}

.wv-icon--video::before {
  content: '';
}

.wv-icon--wave::before {
  content: '';
}

.wv-icon--yahoo::before {
  content: '';
}

.wv-icon--bank--small {
  width: 24px;
  height: 24px;
  border-radius: 3px;
}

.wv-icon--bank,
.wv-icon--bank--medium {
  width: 40px;
  height: 40px;
  border-radius: 4px;
}

.wv-icon--bank--large {
  width: 64px;
  height: 64px;
  border-radius: 4px;
}

/* .wv-icon--bank--affinity {
    background-image: url(images/icons-bank/affinity.svg)
}

.wv-icon--bank--ally {
    background-image: url(images/icons-bank/ally.svg)
}

.wv-icon--bank--assiniboine {
    background-image: url(images/icons-bank/assiniboine.svg)
}

.wv-icon--bank--bank-of-america {
    background-image: url(images/icons-bank/bank-of-america.svg)
}

.wv-icon--bank--bb-and-t {
    background-image: url(images/icons-bank/bb-and-t.svg)
}

.wv-icon--bank--bmo {
    background-image: url(images/icons-bank/bmo.svg)
}

.wv-icon--bank--capital-one {
    background-image: url(images/icons-bank/capital-one.svg)
}

.wv-icon--bank--chase {
    background-image: url(images/icons-bank/chase.svg)
}

.wv-icon--bank--cibc {
    background-image: url(images/icons-bank/cibc.svg)
}

.wv-icon--bank--citi {
    background-image: url(images/icons-bank/citi.svg)
}

.wv-icon--bank--citizens {
    background-image: url(images/icons-bank/citizens.svg)
}

.wv-icon--bank--coast-capital {
    background-image: url(images/icons-bank/coast-capital.svg)
}

.wv-icon--bank--conexus {
    background-image: url(images/icons-bank/conexus.svg)
}

.wv-icon--bank--connect-first {
    background-image: url(images/icons-bank/connect-first.png)
}

.wv-icon--bank--desjardins {
    background-image: url(images/icons-bank/desjardins.svg)
}

.wv-icon--bank--equitable {
    background-image: url(images/icons-bank/equitable.svg)
}

.wv-icon--bank--fifth-third {
    background-image: url(images/icons-bank/fifth-third.svg)
}

.wv-icon--bank--first-republic {
    background-image: url(images/icons-bank/first-republic.svg)
}

.wv-icon--bank--first-west {
    background-image: url(images/icons-bank/first-west.svg)
}

.wv-icon--bank--laurentien {
    background-image: url(images/icons-bank/laurentien.svg)
}

.wv-icon--bank--meridian {
    background-image: url(images/icons-bank/meridian.svg)
}

.wv-icon--bank--national-bank {
    background-image: url(images/icons-bank/national-bank.svg)
}

.wv-icon--bank--no-bank {
    background-image: url(images/icons-bank/no-bank.svg)
}

.wv-icon--bank--pc-financial {
    background-image: url(images/icons-bank/pcfinancial.svg)
}

.wv-icon--bank--pnc {
    background-image: url(images/icons-bank/pnc.svg)
}

.wv-icon--bank--rbc {
    background-image: url(images/icons-bank/rbc.svg)
}

.wv-icon--bank--regions-financial {
    background-image: url(images/icons-bank/regions-financial.svg)
}

.wv-icon--bank--scotiabank {
    background-image: url(images/icons-bank/scotiabank.svg)
}

.wv-icon--bank--servus {
    background-image: url(images/icons-bank/servus.svg)
}

.wv-icon--bank--silicon-valley {
    background-image: url(images/icons-bank/silicon-valley.svg)
}

.wv-icon--bank--simplii {
    background-image: url(images/icons-bank/simplii.svg)
}

.wv-icon--bank--steinbach {
    background-image: url(images/icons-bank/steinbach.svg)
}

.wv-icon--bank--suntrust {
    background-image: url(images/icons-bank/suntrust.svg)
}

.wv-icon--bank--tangerine {
    background-image: url(images/icons-bank/tangerine.svg)
}

.wv-icon--bank--td {
    background-image: url(images/icons-bank/td.svg)
}

.wv-icon--bank--us-bank {
    background-image: url(images/icons-bank/us-bank.svg)
}

.wv-icon--bank--usaa {
    background-image: url(images/icons-bank/usaa.svg)
}

.wv-icon--bank--vancity {
    background-image: url(images/icons-bank/vancity.svg)
}

.wv-icon--bank--wells-fargo {
    background-image: url(images/icons-bank/wells-fargo.svg)
}
 */
.wv-icon--payment-method--small {
  height: 24px;
  width: 38px;
  background-size: 38px 24px;
  border-radius: 3px;
  margin: 0 1px;
}

.wv-icon--payment-method,
.wv-icon--payment-method--medium {
  height: 40px;
  width: 64px;
  background-size: 64px 40px;
  border-radius: 4px;
}

.wv-icon--payment-method--large {
  height: 64px;
  width: 102px;
  background-size: 102px 64px;
  border-radius: 4px;
}

/* .wv-icon--payment-method--cc-visa {
    background-image: url(images/icons-payment-methods/cc-visa.svg);
    border: 1px solid #dcdfe0
}

.wv-icon--payment-method--cc-visa-debit {
    background-image: url(images/icons-payment-methods/cc-visa-debit.svg);
    border: 1px solid #dcdfe0
}

.wv-icon--payment-method--cc-mastercard {
    background-image: url(images/icons-payment-methods/cc-mastercard.svg);
    border: 1px solid #dcdfe0;
    background-color: #fff
}

.wv-icon--payment-method--cc-amex {
    background-image: url(images/icons-payment-methods/cc-amex.svg);
    border: 1px solid #dcdfe0
}

.wv-icon--payment-method--cc-discover {
    background-image: url(images/icons-payment-methods/cc-discover.svg);
    border: 1px solid #dcdfe0
}

.wv-icon--payment-method--cc-diners {
    background-image: url(images/icons-payment-methods/cc-diners.svg);
    border: 1px solid #dcdfe0
}

.wv-icon--payment-method--cc-jcb {
    background-image: url(images/icons-payment-methods/cc-jcb.svg);
    border: 1px solid #dcdfe0
}

.wv-icon--payment-method--cc-paypal {
    background-image: url(images/icons-payment-methods/cc-paypal.svg);
    border: 1px solid #dcdfe0
}

.wv-icon--payment-method--cc-unknown {
    background-image: url(images/icons-payment-methods/cc-unknown.svg);
    border: 1px solid #dcdfe0
}

.wv-icon--payment-method--plus-debit {
    background-image: url(images/icons-payment-methods/plus-debit.svg);
    border: 1px solid #dcdfe0;
    background-color: #fff
}

.wv-icon--payment-method--bank-payment {
    background-image: url(images/icons-payment-methods/bank-payment.svg);
    border: 1px solid #dcdfe0
}

 */
.wv-icon--payment-method--cc-amex,
.wv-icon--payment-method--bank-payment {
  border-color: transparent;
  border-radius: 6px;
}

.wv-icon--payment-method--cc-amex.wv-icon--payment-method--large,
.wv-icon--payment-method--bank-payment.wv-icon--payment-method--large {
  border-radius: 8px;
}

.wv-icon--third-party--small {
  width: 24px;
  height: 24px;
  border-radius: 3px;
}

.wv-icon--third-party,
.wv-icon--third-party--medium {
  width: 40px;
  height: 40px;
  border-radius: 4px;
}

.wv-icon--third-party--large {
  width: 64px;
  height: 64px;
  border-radius: 4px;
}

/* .wv-icon--third-party--etsy {
    background-image: url(images/icons-third-party/etsy.svg)
}

.wv-icon--third-party--fundbox {
    background-image: url(images/icons-third-party/fundbox.svg)
}

.wv-icon--third-party--google-sheets {
    background-image: url(images/icons-third-party/google-sheets.svg)
}

.wv-icon--third-party--paypal {
    background-image: url(images/icons-third-party/paypal.svg)
}

.wv-icon--third-party--shoeboxed {
    background-image: url(images/icons-third-party/shoeboxed.svg)
}

.wv-icon--third-party--shopify {
    background-image: url(images/icons-third-party/shopify.svg)
}

.wv-icon--third-party--zapier {
    background-image: url(images/icons-third-party/zapier.svg)
}

.wv-icon--third-party--blue-camroo {
    background-image: url(images/icons-third-party/blue-camroo.png)
}
 */
.wv-decor-icon {
  width: 64px;
  height: 64px;
}

.wv-decor-icon--large {
  width: 80px;
  height: 80px;
}

@media (max-width: 480px) {
  .wv-decor-icon--large {
    width: 64px;
    height: 64px;
  }
}

.wv-status-icon--error {
  width: 120px;
  height: 120px;
  display: inline-block;
  fill: none;
  stroke: #d74242;
  stroke-miterlimit: 10;
  stroke-width: 6px;
  stroke-linejoin: round;
  stroke-linecap: round;
}

@media (max-width: 480px) {
  .wv-status-icon--error {
    width: 96px;
    height: 96px;
  }
}

.wv-animated-icon--checkmark,
.wv-status-icon--success {
  width: 120px;
  height: 120px;
  display: inline-block;
  fill: none;
  stroke: #23c770;
  stroke-miterlimit: 10;
  stroke-width: 6px;
  stroke-linejoin: round;
  stroke-linecap: round;
}

@media (max-width: 480px) {
  .wv-animated-icon--checkmark,
  .wv-status-icon--success {
    width: 96px;
    height: 96px;
  }
}

.wv-animated-icon--checkmark.bounce,
.wv-status-icon--success.bounce {
  -webkit-animation: 0.3s checkmarkBounce;
  animation: 0.3s checkmarkBounce;
}

.wv-animated-icon--checkmark__circle,
.wv-status-icon--success__circle {
  stroke-dasharray: 700;
}

.wv-animated-icon--checkmark__check,
.wv-status-icon--success__check {
  stroke-dasharray: 150;
}

@-webkit-keyframes checkmarkBounce {
  0%,
  100% {
    -webkit-transform: none;
    transform: none;
  }

  40% {
    -webkit-transform: scale3d(0.95, 0.95, 0.95);
    transform: scale3d(0.95, 0.95, 0.95);
  }

  80% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
}

@keyframes checkmarkBounce {
  0%,
  100% {
    -webkit-transform: none;
    transform: none;
  }

  40% {
    -webkit-transform: scale3d(0.95, 0.95, 0.95);
    transform: scale3d(0.95, 0.95, 0.95);
  }

  80% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
}

[class*='wv-image'] {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

[class*='wv-image--cc'],
[class*='wv-image--bank-payment'] {
  width: 48px;
  height: 30px;
}

/* .wv-image--cc-visa {
    background-image: url("images/cc-icon--visa.png")
}

.wv-image--cc-visa-debit {
    background-image: url("images/cc-icon--visa-debit.png")
}

.wv-image--cc-mastercard {
    background-image: url("images/cc-icon--mastercard.png")
}

.wv-image--cc-amex {
    background-image: url("images/cc-icon--amex.png")
}

.wv-image--cc-discover {
    background-image: url("images/cc-icon--discover.png")
}

.wv-image--cc-diners {
    background-image: url("images/cc-icon--diners.png")
}

.wv-image--cc-jcb {
    background-image: url("images/cc-icon--jcb.png")
}

.wv-image--cc-paypal {
    background-image: url("images/cc-icon--paypal.png")
}

.wv-image--cc-unknown {
    background-image: url("images/cc-icon--unknown.png")
}

.wv-image--bank-payment {
    background-image: url("images/icon--bank-payment.png")
}
 */
.wv-spinner,
.wv-spinner--inverse,
.wv-spinner--small {
  border-style: solid;
  border-radius: 50%;
  border-color: #ecf0f3;
  border-right-color: #136acd;
  border-top-color: #136acd;
  border-width: 2px;
  font-size: 22px;
  width: 22px;
  height: 22px;
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  -webkit-animation: spin-animation 0.7s linear infinite;
  animation: spin-animation 0.7s linear infinite;
}

@-webkit-keyframes spin-animation {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin-animation {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.wv-spinner--inverse {
  border-right-color: #fff;
  border-top-color: #fff;
  border-left-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgba(0, 0, 0, 0);
}

.wv-spinner.wv-spinner--large,
.wv-spinner--large.wv-spinner--inverse,
.wv-spinner--large.wv-spinner--small {
  border-width: 5px;
  font-size: 60px;
  width: 60px;
  height: 60px;
}

.wv-spinner--centered {
  position: relative;
  width: 100%;
  height: 100%;
}

.wv-spinner--centered [class*='wv-spinner'] {
  position: absolute;
  top: 50%;
  left: 50%;
}

.wv-spinner--centered .wv-spinner,
.wv-spinner--centered .wv-spinner--inverse,
.wv-spinner--centered .wv-spinner--small {
  margin: -11px 0 0 -11px;
}

.wv-spinner--centered .wv-spinner--large {
  margin: -30px 0 0 -30px;
}

.wv-divider,
.wv-divider--whitespace {
  display: block;
  height: 0;
  padding: 0;
  border: none;
  border-bottom: 1px solid #d4dde3;
  overflow: hidden;
  margin: 24px auto;
}

.wv-divider--whitespace {
  border-bottom-width: 0;
}

.wv-divider--options,
.wv-divider--options--fluid {
  text-align: center;
  text-transform: lowercase;
  margin: 24px auto;
  position: relative;
  display: block;
  overflow: hidden;
  width: 30%;
}

.wv-divider--options::before,
.wv-divider--options::after,
.wv-divider--options--fluid::before,
.wv-divider--options--fluid::after {
  display: block;
  height: 0;
  padding: 0;
  border: none;
  border-bottom: 1px solid #d4dde3;
  overflow: hidden;
  content: '';
  position: absolute;
  top: 50%;
  width: 50%;
}

.wv-divider--options::before,
.wv-divider--options--fluid::before {
  left: 0;
  margin-left: -1em;
}

.wv-divider--options::after,
.wv-divider--options--fluid::after {
  right: 0;
  margin-right: -1em;
}

.wv-divider--options--fluid {
  width: 100%;
}

.wv-list--plain--vertical,
.wv-list--plain--horizontal,
.wv-nav--secondary--horizontal .wv-nav__section,
.wv-nav--secondary .wv-nav__section,
.wv-nav--tertiary .wv-nav__section,
.wv-list,
.wv-list--bulleted,
.wv-list--numbered,
.wv-list--alpha,
.wv-list--icon {
  list-style-type: none;
  margin: 16px 0 16px 1.6em;
  padding: 0;
}

.wv-list--plain--vertical li > .wv-svg-icon,
.wv-list--plain--horizontal li > .wv-svg-icon,
.wv-nav--secondary--horizontal .wv-nav__section li > .wv-svg-icon,
.wv-nav--secondary .wv-nav__section li > .wv-svg-icon,
.wv-nav--tertiary .wv-nav__section li > .wv-svg-icon,
.wv-list li > .wv-svg-icon,
.wv-list--bulleted li > .wv-svg-icon,
.wv-list--numbered li > .wv-svg-icon,
.wv-list--alpha li > .wv-svg-icon,
.wv-list--icon li > .wv-svg-icon {
  margin-top: -1px;
  margin-bottom: -1px;
  position: relative;
  bottom: -3px;
}

.wv-list--plain--vertical,
.wv-list--plain--horizontal,
.wv-nav--secondary--horizontal .wv-nav__section,
.wv-nav--secondary .wv-nav__section,
.wv-nav--tertiary .wv-nav__section {
  margin: 0;
}

.wv-list--plain--horizontal li {
  display: inline;
}

.wv-list,
.wv-list--bulleted {
  list-style-type: disc;
}

.wv-list--numbered {
  list-style-type: decimal;
}

.wv-list--alpha {
  list-style-type: lower-alpha;
}

.wv-list--icon {
  margin-left: 0;
}

.wv-list--icon li {
  margin: 16px 0 16px 26px;
}

.wv-list--icon li .wv-svg-icon:first-child {
  margin-right: 8px;
  margin-left: -28px;
  fill: #23c770;
}

.wv-list--icon i {
  margin-right: 8px;
  margin-left: -26px;
}

.wv-list--icon i.wv-icon--check,
.wv-list--icon .is-complete i.wv-workflow__task__number:empty::before,
.is-complete .wv-list--icon i.wv-workflow__task__number:empty::before,
.wv-list--icon .is-complete i.wv-wizard__task__number:empty::before,
.is-complete .wv-list--icon i.wv-wizard__task__number:empty::before {
  color: #23c770;
}

.wv-logo,
.wv-logo--light,
.wv-logo--mark {
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  text-indent: -500%;
  background: url('assets/icons/tradext-logo-72.png') left top no-repeat;
  background-size: cover;
}
/*
.wv-logo--mono,
.wv-logo--mono-light {
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  text-indent: -500%;
  background: url('images/wave-logo-mono.svg') left top no-repeat;
  background-size: auto 200%;
}

.wv-logo--powered-by {
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  text-indent: -500%;
  background: url('images/wave-logo--powered-by.svg') left top no-repeat;
  background-size: auto 100%;
}

.wv-logo--powered-by--mono {
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  text-indent: -500%;
  background: url('images/wave-logo--powered-by--mono.svg') left top no-repeat;
  background-size: auto 100%;
}
*/
.wv-logo--light,
.wv-logo--mono-light {
  background-position: left bottom;
}

.wv-logo.wv-logo--small,
.wv-logo--light.wv-logo--small,
.wv-logo--mono.wv-logo--small,
.wv-logo--mono-light.wv-logo--small {
  width: 60.16px;
  height: 16px;
}

.wv-logo--mark.wv-logo--small {
  width: 18.56px;
  height: 16px;
}

.wv-logo--powered-by.wv-logo--small {
  width: 132.8px;
  height: 16px;
}

.wv-logo--powered-by--mono.wv-logo--small {
  width: 123.84px;
  height: 16px;
}

.wv-logo,
.wv-logo--light,
.wv-logo--mono,
.wv-logo--mono-light {
  width: 120.32px;
  height: 32px;
}

.wv-logo--mark {
  width: 37.12px;
  height: 32px;
}

.wv-logo--powered-by {
  width: 265.6px;
  height: 32px;
}

.wv-logo--powered-by--mono {
  width: 247.68px;
  height: 32px;
}

.wv-logo.wv-logo--large,
.wv-logo--light.wv-logo--large,
.wv-logo--mono.wv-logo--large,
.wv-logo--mono-light.wv-logo--large {
  width: 180.48px;
  height: 48px;
}

.wv-logo--mark.wv-logo--large {
  width: 55.68px;
  height: 48px;
}

.wv-logo--powered-by.wv-logo--large {
  width: 398.4px;
  height: 48px;
}

@media (max-width: 550px) {
  .wv-logo--powered-by.wv-logo--large {
    width: 265.6px;
    height: 32px;
  }
}

.wv-logo--powered-by--mono.wv-logo--large {
  width: 371.52px;
  height: 48px;
}

@media (max-width: 550px) {
  .wv-logo--powered-by--mono.wv-logo--large {
    width: 247.68px;
    height: 32px;
  }
}

.wv-button--google,
.wv-button--yahoo,
.wv-button--facebook,
.wv-button,
.wv-button--submit,
.wv-button--primary,
.wv-button--secondary,
.wv-button--danger,
.wv-button--secondary--danger,
.wv-button-group--segmented > .wv-button-group__button,
.is-current .wv-workflow__task__action,
.is-complete .wv-workflow__task__action,
.wv-pagination__control,
.wv-nav__promo .wv-nav__promo__button,
.wv-nav__promo--topbar .wv-nav__promo__button {
  text-align: center;
  min-width: 100px;
  border-radius: 500px;
  margin: 0;
  display: inline-block;
  box-sizing: border-box;
  vertical-align: middle;
  outline: 0;
  font-family: Averta, sans-serif;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.is-disabled.wv-button--google,
.is-disabled.wv-button--yahoo,
.is-disabled.wv-button--facebook,
.is-disabled.wv-button,
.is-disabled.wv-button--submit,
.is-disabled.wv-button--primary,
.is-disabled.wv-button--secondary,
.is-disabled.wv-button--danger,
.is-disabled.wv-button--secondary--danger,
.wv-button-group--segmented > .is-disabled.wv-button-group__button,
.is-current .is-disabled.wv-workflow__task__action,
.is-complete .is-disabled.wv-workflow__task__action,
.is-disabled.wv-pagination__control,
.wv-nav__promo .is-disabled.wv-nav__promo__button,
.wv-nav__promo--topbar .is-disabled.wv-nav__promo__button {
  cursor: not-allowed;
  opacity: 0.3;
}

.wv-button--small[class*='wv-button'],
[class*='wv-button--with-actions'].wv-button--small .wv-button,
.wv-button-group--segmented > .wv-button-group__button,
.wv-nav__promo .wv-nav__promo__button,
.wv-nav__promo--topbar .wv-nav__promo__button {
  padding: 4px 16px;
  min-width: 80px;
}

.wv-button--google,
.wv-button--yahoo,
.wv-button--facebook,
.wv-button,
.wv-button--submit,
.wv-button--primary,
.wv-button--secondary,
.wv-button--danger,
.wv-button--secondary--danger,
.is-current .wv-workflow__task__action,
.is-complete .wv-workflow__task__action,
.wv-pagination__control {
  padding: 8px 20px;
}

.wv-button--google .wv-svg-icon,
.wv-button--yahoo .wv-svg-icon,
.wv-button--facebook .wv-svg-icon,
.wv-button .wv-svg-icon,
.wv-button--submit .wv-svg-icon,
.wv-button--primary .wv-svg-icon,
.wv-button--secondary .wv-svg-icon,
.wv-button--danger .wv-svg-icon,
.wv-button--secondary--danger .wv-svg-icon,
.is-current .wv-workflow__task__action .wv-svg-icon,
.is-complete .wv-workflow__task__action .wv-svg-icon,
.wv-pagination__control .wv-svg-icon {
  margin-top: -1px;
  margin-bottom: -1px;
  position: relative;
  bottom: -3px;
}

.wv-button--large[class*='wv-button'] {
  padding: 15px 32px;
}

.wv-button--fluid {
  width: 100%;
}

.wv-button,
.wv-button--with-actions .wv-button {
  color: #4d6575;
  background: #ecf0f3;
  border: 1px solid transparent;
}

.wv-button:hover:not(.is-disabled),
.wv-button:focus:not(.is-disabled) {
  background: #e0f4fb;
  color: #136acd;
}

.wv-button:focus:not(.is-disabled),
.wv-button:active:not(.is-disabled) {
  box-shadow: 0 0 0 3px #ceeef8;
  outline: 0;
}

.wv-button--submit,
.wv-button--primary,
.wv-button--with-actions--primary .wv-button,
.is-current .wv-workflow__task__action {
  color: #fff;
  background: #136acd;
  border: 1px solid transparent;
}

.wv-button--submit:hover:not(.is-disabled),
.wv-button--primary:hover:not(.is-disabled),
.wv-button--with-actions--primary .wv-button:hover:not(.is-disabled),
.is-current .wv-workflow__task__action:hover:not(.is-disabled),
.wv-button--submit:focus:not(.is-disabled),
.wv-button--primary:focus:not(.is-disabled),
.wv-button--with-actions--primary .wv-button:focus:not(.is-disabled),
.is-current .wv-workflow__task__action:focus:not(.is-disabled) {
  background: #0f529f;
}

.wv-button--submit:focus:not(.is-disabled),
.wv-button--primary:focus:not(.is-disabled),
.wv-button--with-actions--primary .wv-button:focus:not(.is-disabled),
.is-current .wv-workflow__task__action:focus:not(.is-disabled),
.wv-button--submit:active:not(.is-disabled),
.wv-button--primary:active:not(.is-disabled),
.wv-button--with-actions--primary .wv-button:active:not(.is-disabled),
.is-current .wv-workflow__task__action:active:not(.is-disabled) {
  box-shadow: 0 0 0 3px rgba(19, 106, 205, 0.3);
  outline: 0;
}

.wv-button--google,
.wv-button--yahoo,
.wv-button--facebook,
.wv-button--secondary,
.wv-button--secondary--danger,
.wv-button--with-actions--secondary .wv-button,
.wv-button-group--segmented > .wv-button-group__button,
.is-complete .wv-workflow__task__action,
.wv-pagination__control,
.wv-nav__promo,
.wv-nav__promo--topbar {
  color: #4d6575;
  background: rgba(0, 0, 0, 0);
  border: 1px solid #b2c2cd;
}

.wv-button--google:hover:not(.is-disabled),
.wv-button--yahoo:hover:not(.is-disabled),
.wv-button--facebook:hover:not(.is-disabled),
.wv-button--secondary:hover:not(.is-disabled),
.wv-button--secondary--danger:hover:not(.is-disabled),
.wv-button--with-actions--secondary .wv-button:hover:not(.is-disabled),
.wv-button-group--segmented > .wv-button-group__button:hover:not(.is-disabled),
.is-complete .wv-workflow__task__action:hover:not(.is-disabled),
.wv-pagination__control:hover:not(.is-disabled),
.wv-nav__promo:hover:not(.is-disabled),
.wv-nav__promo--topbar:hover:not(.is-disabled),
.wv-button--google:focus:not(.is-disabled),
.wv-button--yahoo:focus:not(.is-disabled),
.wv-button--facebook:focus:not(.is-disabled),
.wv-button--secondary:focus:not(.is-disabled),
.wv-button--secondary--danger:focus:not(.is-disabled),
.wv-button--with-actions--secondary .wv-button:focus:not(.is-disabled),
.wv-button-group--segmented > .wv-button-group__button:focus:not(.is-disabled),
.is-complete .wv-workflow__task__action:focus:not(.is-disabled),
.wv-pagination__control:focus:not(.is-disabled),
.wv-nav__promo:focus:not(.is-disabled),
.wv-nav__promo--topbar:focus:not(.is-disabled) {
  color: #136acd;
  border-color: #136acd;
}

/* .wv-button--google:focus:not(.is-disabled),
.wv-button--yahoo:focus:not(.is-disabled),
.wv-button--facebook:focus:not(.is-disabled),
.wv-button--secondary:focus:not(.is-disabled),
.wv-button--secondary--danger:focus:not(.is-disabled),
.wv-button--with-actions--secondary .wv-button:focus:not(.is-disabled),
.wv-button-group--segmented>.wv-button-group__button:focus:not(.is-disabled),
.is-complete .wv-workflow__task__action:focus:not(.is-disabled),
.wv-pagination__control:focus:not(.is-disabled),
.wv-nav__promo:focus:not(.is-disabled),
.wv-nav__promo--topbar:focus:not(.is-disabled),
.wv-button--google:active:not(.is-disabled),
.wv-button--yahoo:active:not(.is-disabled),
.wv-button--facebook:active:not(.is-disabled),
.wv-button--secondary:active:not(.is-disabled),
.wv-button--secondary--danger:active:not(.is-disabled),
.wv-button--with-actions--secondary .wv-button:active:not(.is-disabled),
.wv-button-group--segmented>.wv-button-group__button:active:not(.is-disabled),
.is-complete .wv-workflow__task__action:active:not(.is-disabled),
.wv-pagination__control:active:not(.is-disabled),
.wv-nav__promo:active:not(.is-disabled),
.wv-nav__promo--topbar:active:not(.is-disabled) {
    box-shadow: 0 0 0 3px rgba(19, 106, 205, 0.2);
    outline: 0
} */
.wv-button--danger {
  color: #fff;
  background: #d74242;
  border: 1px solid transparent;
}

.wv-button--danger:hover:not(.is-disabled),
.wv-button--danger:focus:not(.is-disabled) {
  background: #bd2828;
}

.wv-button--danger:focus:not(.is-disabled),
.wv-button--danger:active:not(.is-disabled) {
  box-shadow: 0 0 0 3px rgba(215, 66, 66, 0.3);
  outline: 0;
}

.wv-button--narrow.wv-button--google,
.wv-button--narrow.wv-button--yahoo,
.wv-button--narrow.wv-button--facebook {
  width: 40px;
  height: 40px;
}

.wv-button--with-actions,
.wv-button--with-actions--primary,
.wv-button--with-actions--secondary {
  display: inline-block;
  vertical-align: middle;
}

.is-disabled.wv-button--with-actions .wv-button,
.is-disabled.wv-button--with-actions--primary .wv-button,
.is-disabled.wv-button--with-actions--secondary .wv-button,
.is-disabled.wv-button--with-actions .wv-dropdown__toggle,
.is-disabled.wv-button--with-actions--primary .wv-dropdown__toggle,
.is-disabled.wv-button--with-actions--secondary .wv-dropdown__toggle {
  cursor: not-allowed;
  opacity: 0.3;
}

.is-disabled.wv-button--with-actions .wv-button:hover,
.is-disabled.wv-button--with-actions--primary .wv-button:hover,
.is-disabled.wv-button--with-actions--secondary .wv-button:hover,
.is-disabled.wv-button--with-actions .wv-button:active,
.is-disabled.wv-button--with-actions--primary .wv-button:active,
.is-disabled.wv-button--with-actions--secondary .wv-button:active,
.is-disabled.wv-button--with-actions .wv-dropdown__toggle:hover,
.is-disabled.wv-button--with-actions--primary .wv-dropdown__toggle:hover,
.is-disabled.wv-button--with-actions--secondary .wv-dropdown__toggle:hover,
.is-disabled.wv-button--with-actions .wv-dropdown__toggle:active,
.is-disabled.wv-button--with-actions--primary .wv-dropdown__toggle:active,
.is-disabled.wv-button--with-actions--secondary .wv-dropdown__toggle:active {
  box-shadow: none;
}

.wv-button--with-actions > .wv-button,
.wv-button--with-actions--primary > .wv-button,
.wv-button--with-actions--secondary > .wv-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
  padding-right: 16px;
  margin-right: 0;
}

.wv-button--with-actions > .wv-button:focus,
.wv-button--with-actions--primary > .wv-button:focus,
.wv-button--with-actions--secondary > .wv-button:focus {
  position: relative;
  z-index: 1;
}

.wv-button--with-actions .wv-dropdown,
.wv-button--with-actions--primary .wv-dropdown,
.wv-button--with-actions--secondary .wv-dropdown {
  float: right;
}

.wv-button--with-actions .wv-dropdown__toggle,
.wv-button--with-actions--primary .wv-dropdown__toggle,
.wv-button--with-actions--secondary .wv-dropdown__toggle {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 8px 0 7px;
  width: 36px;
  min-width: 0;
  border-left: 1px solid;
}

.wv-button--with-actions .wv-dropdown__toggle:hover,
.wv-button--with-actions--primary .wv-dropdown__toggle:hover,
.wv-button--with-actions--secondary .wv-dropdown__toggle:hover,
.wv-button--with-actions .wv-dropdown__toggle:active,
.wv-button--with-actions--primary .wv-dropdown__toggle:active,
.wv-button--with-actions--secondary .wv-dropdown__toggle:active,
.wv-button--with-actions .wv-dropdown__toggle:focus,
.wv-button--with-actions--primary .wv-dropdown__toggle:focus,
.wv-button--with-actions--secondary .wv-dropdown__toggle:focus {
  border-left: 1px solid;
}

.wv-button--with-actions .wv-dropdown__toggle .wv-icon--open-menu,
.wv-button--with-actions--primary .wv-dropdown__toggle .wv-icon--open-menu,
.wv-button--with-actions--secondary .wv-dropdown__toggle .wv-icon--open-menu,
.wv-button--with-actions .wv-dropdown__toggle .wv-select__input__icon:empty,
.wv-button--with-actions--primary
  .wv-dropdown__toggle
  .wv-select__input__icon:empty,
.wv-button--with-actions--secondary
  .wv-dropdown__toggle
  .wv-select__input__icon:empty {
  margin: 0;
}

.wv-button--with-actions .wv-dropdown__toggle.wv-button .wv-svg-icon,
.wv-button--with-actions--primary .wv-dropdown__toggle.wv-button .wv-svg-icon,
.wv-button--with-actions--secondary
  .wv-dropdown__toggle.wv-button
  .wv-svg-icon {
  margin-right: 2px;
}

.wv-button--with-actions .wv-button + .wv-dropdown,
.wv-button--with-actions--primary .wv-button + .wv-dropdown,
.wv-button--with-actions--secondary .wv-button + .wv-dropdown {
  margin-left: 0;
}

.wv-button--secondary--danger:hover:not(.is-disabled),
.wv-button--secondary--danger:focus:not(.is-disabled) {
  color: #fff;
  background: #d74242;
  border-color: #d74242;
}

.wv-button--secondary--danger:focus:not(.is-disabled),
.wv-button--secondary--danger:active:not(.is-disabled) {
  box-shadow: 0 0 0 3px rgba(215, 66, 66, 0.3);
  outline: 0;
}

[class*='wv-button'] + [class*='wv-button'] {
  margin-left: 4px;
}

.wv-button--narrow {
  min-width: auto;
  padding: 8px 11px;
}

.wv-button--link {
  border: none;
  padding: 0;
  background: transparent;
}

.wv-button--link:hover:not(.is-disabled):not(:focus) {
  outline: 0;
}

.wv-button--link.is-disabled {
  cursor: not-allowed;
  opacity: 0.3;
  text-decoration: none;
  color: #136acd;
}

.wv-button--link[class*='wv-button'] {
  margin: 0;
  padding: 0;
}

.wv-button--google {
  color: white;
  background: #4285f4;
  border: 1px solid #4285f4;
  border-radius: 500px;
  position: relative;
  padding-left: 50px;
  font-weight: 600;
}

/* .wv-button--google::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 3px;
    left: 3px;
    background: white url(images/social-auth-icon--google.svg) no-repeat top left;
    width: 32px;
    height: 32px;
    border-radius: 500px
}
 */
.wv-button--google:hover:not(.is-disabled),
.wv-button--google:focus:not(.is-disabled),
.wv-button--google:active:not(.is-disabled) {
  color: white;
  border-color: #1b6cf2;
  background: #1b6cf2;
}

.wv-button--yahoo {
  color: white;
  background: #410093;
  border: 1px solid #410093;
  border-radius: 500px;
  position: relative;
  padding-left: 50px;
  font-weight: 600;
}

/* .wv-button--yahoo::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 3px;
    left: 3px;
    background: white url(images/social-auth-icon--yahoo.svg) no-repeat top left;
    width: 32px;
    height: 32px;
    border-radius: 500px
}
 */
.wv-button--yahoo:hover:not(.is-disabled),
.wv-button--yahoo:focus:not(.is-disabled),
.wv-button--yahoo:active:not(.is-disabled) {
  color: white;
  border-color: #2f006a;
  background: #2f006a;
}

.wv-button--facebook {
  color: white;
  background: #3b5998;
  border: 1px solid #3b5998;
  border-radius: 500px;
  position: relative;
  padding-left: 50px;
  font-weight: 600;
}

/* .wv-button--facebook::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 3px;
    left: 3px;
    background: white url(images/social-auth-icon--facebook.svg) no-repeat top left;
    width: 32px;
    height: 32px;
    border-radius: 500px
}

 */
.wv-button--facebook:hover:not(.is-disabled),
.wv-button--facebook:focus:not(.is-disabled),
.wv-button--facebook:active:not(.is-disabled) {
  color: white;
  border-color: #30487b;
  background: #30487b;
}

.wv-button--google::before {
  background-position: -3px -3px;
}

.wv-button--yahoo::before {
  background-position: -3px -2px;
}

.wv-button--facebook::before {
  background-position: 10px 6px;
}

[class*='wv-button'].has-loader [class*='wv-spinner'] {
  position: absolute;
  top: 8px;
  left: 50%;
  margin-left: -13px;
  display: none;
}

[class*='wv-button'].has-loader [class*='wv-spinner'] .wv-button__content {
  visibility: visible;
}

[class*='wv-button'].has-loader.is-loading {
  position: relative;
  cursor: default;
}

[class*='wv-button'].has-loader.is-loading .wv-button__content {
  visibility: hidden;
}

[class*='wv-button'].has-loader.is-loading [class*='wv-spinner'] {
  display: block;
}

.wv-button.is-loading {
  background: #e0f4fb;
  box-shadow: 0 0 0 3px #ceeef8;
}

.wv-button.is-loading .wv-spinner,
.wv-button.is-loading .wv-spinner--inverse,
.wv-button.is-loading .wv-spinner--small {
  border-left-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgba(0, 0, 0, 0);
}

.wv-button--primary.is-loading {
  box-shadow: 0 0 0 3px rgba(19, 106, 205, 0.3);
}

.wv-button--secondary.is-loading {
  box-shadow: 0 0 0 3px #ceeef8;
}

.wv-button--large.has-loader [class*='wv-spinner'] {
  top: 18px;
}

.wv-button--with-actions .wv-dropdown__toggle {
  border-left-color: #bac9d4;
}

.wv-button--with-actions .wv-dropdown__toggle:hover,
.wv-button--with-actions .wv-dropdown__toggle:active,
.wv-button--with-actions .wv-dropdown__toggle:focus {
  border-left-color: #bac9d4;
}

.wv-button--with-actions--primary .wv-button:hover,
.wv-button--with-actions--primary .wv-button:active,
.wv-button--with-actions--primary .wv-button:focus {
  color: #fff;
}

.wv-button--with-actions--primary .wv-dropdown__toggle {
  border-left-color: rgba(255, 255, 255, 0.5);
}

.wv-button--with-actions--primary .wv-dropdown__toggle:hover,
.wv-button--with-actions--primary .wv-dropdown__toggle:active,
.wv-button--with-actions--primary .wv-dropdown__toggle:focus {
  border-left-color: rgba(255, 255, 255, 0.5);
}

.wv-button--with-actions--secondary .wv-button:hover,
.wv-button--with-actions--secondary .wv-button:focus,
.wv-button--with-actions--secondary .wv-button:active {
  background-color: transparent;
}

.wv-button--with-actions--secondary .wv-dropdown__toggle {
  border-left-color: #b2c2cd;
}

.wv-button--with-actions--secondary .wv-dropdown__toggle:hover,
.wv-button--with-actions--secondary .wv-dropdown__toggle:active,
.wv-button--with-actions--secondary .wv-dropdown__toggle:focus {
  border-left-color: #b2c2cd;
}

[class*='wv-button--with-actions'] {
  display: -webkit-inline-flex;
  display: inline-flex;
}

[class*='wv-button--with-actions'] .wv-dropdown__toggle {
  height: 100%;
}

[class*='wv-button--with-actions'].wv-button--fluid {
  width: 100%;
}

[class*='wv-button--with-actions'].wv-button--fluid > .wv-button {
  width: 100%;
}

[class*='wv-button--with-actions'].wv-button--small {
  padding: 0;
}

[class*='wv-button--with-actions'].wv-button--small .wv-button {
  min-width: auto;
  margin: 0;
}

[class*='wv-button--with-actions'].wv-button--small .wv-dropdown__toggle {
  min-width: auto;
  margin-left: 0;
  margin-right: 0;
  padding: 3px 0;
}

[class*='wv-button--with-actions'].wv-button--small
  .wv-dropdown__toggle
  .wv-svg-icon {
  bottom: -3px;
}

[class*='wv-button--icon'] {
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 2px;
  margin-top: -2px;
  margin-bottom: -2px;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  border-radius: 24px;
}

[class*='wv-button--icon']:focus,
[class*='wv-button--icon']:active {
  outline: 0;
}

[class*='wv-button--icon'] .wv-svg-icon {
  pointer-events: none;
}

.wv-button--icon:hover,
.wv-button--icon:focus {
  color: #136acd;
}

.wv-button--icon:focus,
.wv-button--icon:active {
  box-shadow: 0 0 0 3px rgba(19, 106, 205, 0.2);
}

.wv-button--icon--danger:hover,
.wv-button--icon--danger:focus {
  color: #d74242;
}

.wv-button--icon--danger:focus,
.wv-button--icon--danger:active {
  box-shadow: 0 0 0 3px rgba(215, 66, 66, 0.3);
}

.wv-button-group--segmented {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: baseline;
  align-items: baseline;
}

.wv-button-group--segmented > .wv-button-group__button {
  -webkit-flex: 0 1 auto;
  flex: 0 1 auto;
  border-radius: 0;
  min-width: 0;
  margin: 0;
  padding-left: 8px;
  padding-right: 8px;
  color: white;
  font-weight: normal;
}

.wv-button-group--segmented > .wv-button-group__button:hover,
.wv-button-group--segmented > .wv-button-group__button:focus,
.wv-button-group--segmented > .wv-button-group__button:active,
.wv-button-group--segmented > .wv-button-group__button.is-active {
  z-index: 2;
}

.wv-button-group--segmented > .wv-button-group__button:first-child {
  border-radius: 500px 0 0 500px;
  padding-left: 12px;
}

.wv-button-group--segmented > .wv-button-group__button:last-child {
  border-radius: 0 500px 500px 0;
  padding-right: 12px;
}

.wv-button-group--segmented
  > .wv-button-group__button
  + .wv-button-group__button {
  margin-left: -1px;
}

.wv-button-group--segmented > .wv-button-group__button:not(.is-active) {
  color: #3c5b8b;
}

.wv-button-group--segmented > .wv-button-group__button:not(.is-active):hover {
  color: #3c5b8b;
  border-color: #3c5b8b;
}

.wv-button-group--segmented > .wv-button-group__button.is-active {
  background: #3c5b8b;
  border-color: #3c5b8b;
  color: white;
}

.wv-button-group--segmented > .wv-button-group__button.is-active:hover,
.wv-button-group--segmented > .wv-button-group__button.is-active:active,
.wv-button-group--segmented > .wv-button-group__button.is-active:focus,
.wv-button-group--segmented > .wv-button-group__button.is-active:hover:active {
  color: white;
  border-color: #3c5b8b;
}

.wv-badge,
.wv-badge--info,
.wv-badge--success,
.wv-badge--alert,
.wv-badge--warning,
.wv-badge--danger,
.wv-badge--muted {
  font-size: 13px;
  line-height: 16px;
  font-weight: bold;
  display: inline-block;
  margin: 2px 0;
  padding: 5px;
  border-radius: 3px;
  white-space: nowrap;
  text-transform: capitalize;
}

.wv-badge,
.wv-badge--info,
.wv-badge--success,
.wv-badge--alert,
.wv-badge--warning,
.wv-badge--danger,
.wv-badge--muted {
  background-color: #c7d3dc;
  color: #395260;
}

.wv-badge--info {
  background-color: #b3e4f5;
  color: #11637e;
}

.wv-badge--success {
  background-color: #adf0cc;
  color: #14713f;
}

.wv-badge--alert {
  background-color: #eee991;
  color: #686307;
}

.wv-badge--warning {
  background-color: #f9dcb3;
  color: #7b4c09;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23B55700' fill-opacity='0.2' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
}

.wv-badge--danger {
  background-color: #f3d1d1;
  color: #a90909;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23C22909' fill-opacity='0.2' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
}

.wv-badge--muted {
  background-color: #d1d7d8;
  color: #485153;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23485153' fill-opacity='0.2' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
}
.wv-badge--cancelled {
  background-color: #242b52;
  color: #ebe5ff;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23C22909' fill-opacity='0.2' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
}

.wv-badge--large {
  font-size: 16px;
  line-height: 1.4;
  font-weight: 600;
  padding: 8px 16px;
}

@media (min-width: 480px) {
  .wv-badge--large {
    font-size: 16px;
  }
}

.wv-badge--small {
  font-size: 12px;
  padding: 0 8px;
}

.wv-connected-service {
  display: inline-block;
  white-space: nowrap;
  font-size: 0;
}

.wv-connected-service .wv-svg-icon {
  height: 18px;
  width: 18px;
  vertical-align: bottom;
}

.wv-connected-service .wv-text {
  display: inline;
  margin: 0 0 0 8px;
}

input[type='number'].wv-input {
  -moz-appearance: textfield;
}

input[type='number'].wv-input::-webkit-inner-spin-button,
input[type='number'].wv-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.wv-input {
  display: inline-block;
  box-sizing: border-box;
  margin: 4px 0;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #b2c2cd;
  border-radius: 4px;
  cursor: text;
  padding: 8px 10px 6px;
  transition: border 0.1s linear;
  min-height: 40px;
  width: 100%;
  min-width: 160px;
  max-width: 320px;
}

.wv-input::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.wv-input.is-disabled {
  background: #f1f3f3;
  color: #849194;
  border-color: #c6cccd;
  pointer-events: none;
}

.wv-input.is-disabled::-webkit-input-placeholder {
  color: #b2c2cd;
}

.wv-input.is-disabled:-ms-input-placeholder {
  color: #b2c2cd;
}

.wv-input.is-disabled::placeholder {
  color: #b2c2cd;
}

.wv-input.is-read-only {
  background-color: transparent;
  border-color: transparent;
  padding-left: 0;
  padding-right: 0;
  pointer-events: none;
}

.has-error .wv-input {
  border-color: #d74242;
}

.has-error .wv-input:focus:not(.is-disabled) {
  border-color: #c22929;
}

.has-warning .wv-input {
  border-color: #dcd318;
}

.has-warning .wv-input:focus:not(.is-disabled) {
  border-color: #c1b815;
}

.has-success .wv-input {
  border-color: #23c770;
}

.has-success .wv-input:focus:not(.is-disabled) {
  border-color: #18864b;
}

.wv-input::-webkit-input-placeholder {
  font-style: italic;
  color: #b2c2cd;
}

.wv-input:-ms-input-placeholder {
  font-style: italic;
  color: #b2c2cd;
}

.wv-input::placeholder {
  font-style: italic;
  color: #b2c2cd;
}

.wv-input:focus:not(.is-disabled) {
  border-color: #136acd;
}

.wv-input:focus:not(.is-disabled)::-webkit-input-placeholder {
  opacity: 0.7;
}

.wv-input:focus:not(.is-disabled):-ms-input-placeholder {
  opacity: 0.7;
}

.wv-input:focus:not(.is-disabled)::placeholder {
  opacity: 0.7;
}

.wv-input.wv-input--fluid {
  max-width: 100%;
  min-width: 0;
}

.wv-input.wv-input--xsmall {
  min-width: 80px;
  max-width: 80px;
}

.wv-input.wv-input--small {
  min-width: 160px;
  max-width: 160px;
}

.wv-input.wv-input--medium {
  max-width: 320px;
}

.wv-input.wv-input--large {
  max-width: 480px;
}

.wv-input.wv-input--xlarge {
  max-width: 480px;
}

.wv-input.wv-input--xlarge {
  padding: 12px 16px;
  font-size: 20px;
  line-height: 1.4;
}

@media (min-width: 480px) {
  .wv-input.wv-input--xlarge {
    font-size: 23px;
  }
}

.wv-input.wv-input--amount {
  text-align: right;
}

.wv-prefixed-input,
.wv-suffixed-input {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
  align-items: center;
}

.wv-prefixed-input .wv-input,
.wv-suffixed-input .wv-input {
  min-width: 0;
  max-width: none;
  width: 100%;
}

.wv-prefixed-input {
  width: 100%;
  min-width: 160px;
  max-width: 320px;
}

.wv-prefixed-input.wv-prefixed-input--fluid {
  max-width: 100%;
  min-width: 0;
}

.wv-prefixed-input.wv-prefixed-input--xsmall {
  min-width: 80px;
  max-width: 80px;
}

.wv-prefixed-input.wv-prefixed-input--small {
  min-width: 160px;
  max-width: 160px;
}

.wv-prefixed-input.wv-prefixed-input--medium {
  max-width: 320px;
}

.wv-prefixed-input.wv-prefixed-input--large {
  max-width: 480px;
}

.wv-prefixed-input.wv-prefixed-input--xlarge {
  max-width: 480px;
}

.wv-suffixed-input {
  width: 100%;
  min-width: 160px;
  max-width: 320px;
}

.wv-suffixed-input.wv-suffixed-input--fluid {
  max-width: 100%;
  min-width: 0;
}

.wv-suffixed-input.wv-suffixed-input--xsmall {
  min-width: 80px;
  max-width: 80px;
}

.wv-suffixed-input.wv-suffixed-input--small {
  min-width: 160px;
  max-width: 160px;
}

.wv-suffixed-input.wv-suffixed-input--medium {
  max-width: 320px;
}

.wv-suffixed-input.wv-suffixed-input--large {
  max-width: 480px;
}

.wv-suffixed-input.wv-suffixed-input--xlarge {
  max-width: 480px;
}

.wv-prefixed-input.wv-prefixed-input--xlarge .wv-input,
.wv-suffixed-input.wv-suffixed-input--xlarge .wv-input {
  padding: 12px 16px;
  font-size: 20px;
  line-height: 1.4;
}

@media (min-width: 480px) {
  .wv-prefixed-input.wv-prefixed-input--xlarge .wv-input,
  .wv-suffixed-input.wv-suffixed-input--xlarge .wv-input {
    font-size: 23px;
  }
}

.wv-prefixed-input__prefix {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  font-size: 19px;
  line-height: 19px;
  vertical-align: middle;
  color: #b2c2cd;
  pointer-events: none;
  text-align: left;
  width: 40px;
  margin-right: -40px;
  padding-left: 12px;
}

.wv-prefixed-input__prefix [class*='wv-icon'] {
  color: inherit;
}

.wv-prefixed-input__prefix .wv-svg-icon {
  margin-bottom: -3px;
}

.wv-prefixed-input--wide-prefix .wv-prefixed-input__prefix {
  width: 64px;
  margin-right: -64px;
}

.wv-prefixed-input .wv-input,
.wv-prefixed-input.wv-prefixed-input--xlarge .wv-input {
  padding-left: 40px;
}

.wv-prefixed-input--wide-prefix .wv-input,
.wv-prefixed-input--wide-prefix.wv-prefixed-input--xlarge .wv-input {
  padding-left: 64px;
}

.wv-suffixed-input__suffix {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  font-size: 19px;
  line-height: 19px;
  vertical-align: middle;
  color: #b2c2cd;
  pointer-events: none;
  text-align: right;
  width: 40px;
  margin-left: -40px;
  padding-right: 12px;
}

.wv-suffixed-input__suffix [class*='wv-icon'] {
  color: inherit;
}

.wv-suffixed-input__suffix .wv-svg-icon {
  margin-bottom: -3px;
}

.wv-suffixed-input--wide-suffix .wv-suffixed-input__suffix {
  width: 64px;
  margin-left: -64px;
}

.wv-suffixed-input .wv-input,
.wv-suffixed-input.wv-suffixed-input--xlarge .wv-input {
  padding-right: 40px;
}

.wv-suffixed-input--wide-suffix .wv-input,
.wv-suffixed-input--wide-suffix.wv-suffixed-input--xlarge .wv-input {
  padding-right: 64px;
}

.wv-input__message {
  display: block;
}

.has-error .wv-input__message {
  color: #c22929;
}

.has-success .wv-input__message {
  color: #18864b;
}

.has-warning .wv-input__message {
  color: #7c760e;
}

.wv-textarea {
  display: inline-block;
  box-sizing: border-box;
  margin: 4px 0;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #b2c2cd;
  border-radius: 4px;
  cursor: text;
  padding: 8px 10px 6px;
  transition: border 0.1s linear;
  min-height: 40px;
  width: 100%;
  min-width: 160px;
  resize: none;
}

.wv-textarea::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.wv-textarea.is-disabled {
  background: #f1f3f3;
  color: #849194;
  border-color: #c6cccd;
  pointer-events: none;
}

.wv-textarea.is-disabled::-webkit-input-placeholder {
  color: #b2c2cd;
}

.wv-textarea.is-disabled:-ms-input-placeholder {
  color: #b2c2cd;
}

.wv-textarea.is-disabled::placeholder {
  color: #b2c2cd;
}

.wv-textarea.is-read-only {
  background-color: transparent;
  border-color: transparent;
  padding-left: 0;
  padding-right: 0;
  pointer-events: none;
}

.has-error .wv-textarea {
  border-color: #d74242;
}

.has-error .wv-textarea:focus:not(.is-disabled) {
  border-color: #c22929;
}

.has-warning .wv-textarea {
  border-color: #dcd318;
}

.has-warning .wv-textarea:focus:not(.is-disabled) {
  border-color: #c1b815;
}

.has-success .wv-textarea {
  border-color: #23c770;
}

.has-success .wv-textarea:focus:not(.is-disabled) {
  border-color: #18864b;
}

.wv-textarea::-webkit-input-placeholder {
  font-style: italic;
  color: #b2c2cd;
}

.wv-textarea:-ms-input-placeholder {
  font-style: italic;
  color: #b2c2cd;
}

.wv-textarea::placeholder {
  font-style: italic;
  color: #b2c2cd;
}

.wv-textarea:focus:not(.is-disabled) {
  border-color: #136acd;
}

.wv-textarea:focus:not(.is-disabled)::-webkit-input-placeholder {
  opacity: 0.7;
}

.wv-textarea:focus:not(.is-disabled):-ms-input-placeholder {
  opacity: 0.7;
}

.wv-textarea:focus:not(.is-disabled)::placeholder {
  opacity: 0.7;
}

.wv-textarea,
.wv-textarea.wv-textarea--medium {
  max-width: 320px;
}

.wv-textarea.wv-textarea--large {
  max-width: 480px;
}

.wv-textarea.wv-textarea--fluid {
  max-width: 100%;
}

.wv-input--search {
  display: -webkit-inline-flex;
  display: inline-flex;
  position: relative;
  max-width: 304px;
  width: 100%;
}

.wv-input--search .wv-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  -webkit-flex-shrink: 1;
  flex-shrink: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}

.wv-input--search .wv-input:focus {
  position: relative;
  z-index: 1;
}

.wv-input--search.is-clearable .wv-input {
  padding-right: 40px;
}

.wv-input--search .wv-button {
  background: #ecf0f3;
  color: #849194;
  border-radius: 0 4px 4px 0;
  border-color: #b2c2cd;
  min-width: 0;
  margin-left: -1px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 4px;
  margin-bottom: 4px;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  -webkit-flex-grow: 0;
  flex-grow: 0;
}

.wv-input--search .wv-button:hover:not(.is-disabled),
.wv-input--search .wv-button:focus:not(.is-disabled) {
  background: #f1f3f3;
  color: #6f7d80;
  border-color: #136acd;
}

.wv-input--search .wv-close--medium {
  top: 11px;
  right: 60px;
  z-index: 1;
}

.wv-input--search .wv-icon--search {
  color: #8aa2b2;
}

.wv-form-field--signature {
  text-align: center;
  max-width: 460px;
  width: 100%;
}

.wv-form-field--signature .wv-form-field__label {
  margin-right: 0;
}

.wv-input--signature {
  box-sizing: border-box;
  border: 0;
  border-bottom: 1px solid #b2c2cd;
  cursor: text;
  padding: 4px;
  text-align: center;
  width: 100%;
  line-height: 42px;
  min-height: 42px;
  background-color: transparent;
  transition: border 0.1s linear;
}

.has-error .wv-input--signature {
  border-color: #c22929;
}

.has-error .wv-input--signature:focus:not(.is-disabled) {
  border-color: #c22929;
}

.wv-input--signature::-webkit-input-placeholder {
  font-style: italic;
  font-size: 18px;
  line-height: 1.4;
  color: #b2c2cd;
}

.wv-input--signature:-ms-input-placeholder {
  font-style: italic;
  font-size: 18px;
  line-height: 1.4;
  color: #b2c2cd;
}

.wv-input--signature::placeholder {
  font-style: italic;
  font-size: 18px;
  line-height: 1.4;
  color: #b2c2cd;
}

@media (min-width: 480px) {
  .wv-input--signature::-webkit-input-placeholder {
    font-size: 19px;
  }

  .wv-input--signature:-ms-input-placeholder {
    font-size: 19px;
  }

  .wv-input--signature::placeholder {
    font-size: 19px;
  }
}

.wv-input--signature:focus:not(.is-disabled) {
  border-color: #136acd;
}

.wv-input--signature:focus:not(.is-disabled)::-webkit-input-placeholder {
  opacity: 0.7;
}

.wv-input--signature:focus:not(.is-disabled):-ms-input-placeholder {
  opacity: 0.7;
}

.wv-input--signature:focus:not(.is-disabled)::placeholder {
  opacity: 0.7;
}

.wv-checkbox,
.wv-radio {
  display: -webkit-inline-flex;
  display: inline-flex;
  cursor: pointer;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

.is-disabled .wv-checkbox,
.is-disabled .wv-radio {
  cursor: not-allowed;
}

.wv-checkbox .wv-radiocheck--faux,
.wv-radio .wv-radiocheck--faux {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  border: 1px solid #b2c2cd;
  background: #fff;
  transition: border 0.1s linear, background-color 0.1s linear;
  margin-top: 3px;
  margin-right: 8px;
}

.wv-checkbox .wv-form-field__label,
.wv-radio .wv-form-field__label {
  display: inline-block;
  vertical-align: middle;
  margin-right: 25px;
}

.has-error.wv-checkbox .wv-radiocheck--faux,
.has-error.wv-radio .wv-radiocheck--faux {
  border-color: #d74242;
  border-width: 2px;
  margin-top: 2px;
}

.has-error.wv-checkbox .wv-form-field__label,
.has-error.wv-radio .wv-form-field__label {
  color: #c22929;
}

.has-error.wv-checkbox [type='checkbox']:focus ~ .wv-radiocheck--faux,
.has-error.wv-radio [type='checkbox']:focus ~ .wv-radiocheck--faux,
.has-error.wv-checkbox [type='radio']:focus ~ .wv-radiocheck--faux,
.has-error.wv-radio [type='radio']:focus ~ .wv-radiocheck--faux {
  border-color: #d74242;
}

.wv-checkbox [type='checkbox'],
.wv-radio [type='checkbox'],
.wv-checkbox [type='radio'],
.wv-radio [type='radio'] {
  width: 1px;
  height: 1px;
  border: 0;
  clip: rect(0 0 0 0);
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

.wv-checkbox [type='checkbox']:checked ~ .wv-radiocheck--faux,
.wv-radio [type='checkbox']:checked ~ .wv-radiocheck--faux,
.wv-checkbox [type='radio']:checked ~ .wv-radiocheck--faux,
.wv-radio [type='radio']:checked ~ .wv-radiocheck--faux {
  background: #1f7eea;
  border-color: #1f7eea;
}

.wv-checkbox [type='checkbox']:focus ~ .wv-radiocheck--faux,
.wv-radio [type='checkbox']:focus ~ .wv-radiocheck--faux,
.wv-checkbox [type='radio']:focus ~ .wv-radiocheck--faux,
.wv-radio [type='radio']:focus ~ .wv-radiocheck--faux {
  content: '';
  border-color: #1f7eea;
}

.wv-checkbox [type='checkbox'][disabled],
.wv-radio [type='checkbox'][disabled],
.wv-checkbox [type='radio'][disabled],
.wv-radio [type='radio'][disabled] {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.wv-checkbox [type='checkbox'][disabled] ~ *,
.wv-radio [type='checkbox'][disabled] ~ *,
.wv-checkbox [type='radio'][disabled] ~ *,
.wv-radio [type='radio'][disabled] ~ * {
  cursor: not-allowed;
}

.wv-checkbox .wv-radiocheck--faux {
  border-radius: 3px;
}

.wv-checkbox [type='checkbox']:checked ~ .wv-radiocheck--faux::after {
  display: block;
  content: '';
  height: 4px;
  width: 8px;
  position: absolute;
  top: 45%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(-45deg);
  transform: translate3d(-50%, -50%, 0) rotate(-45deg);
  border-bottom: 2px solid white;
  border-left: 2px solid white;
}

.wv-checkbox [type='checkbox']:focus:checked ~ .wv-radiocheck--faux {
  border-color: #1f7eea;
  background-color: #1f7eea;
}

.wv-checkbox [type='checkbox'][disabled] ~ .wv-radiocheck--faux {
  background-color: #dcdfe0;
  border-color: #c6cccd;
}

.wv-checkbox [type='checkbox'][disabled] ~ .wv-radiocheck--faux::after {
  border-color: #849194;
}

.has-error.wv-checkbox [type='checkbox']:checked ~ .wv-radiocheck--faux {
  border-color: #d74242;
  background-color: #fff;
}

.has-error.wv-checkbox [type='checkbox']:checked ~ .wv-radiocheck--faux::after {
  border-color: #d74242;
}

.has-selection.wv-checkbox
  [type='checkbox']:checked
  ~ .wv-radiocheck--faux::after {
  top: 20%;
  left: 20%;
  -webkit-transform: none;
  transform: none;
  border-left: 0;
  width: 10px;
}

.wv-radio .wv-radiocheck--faux {
  border-radius: 50%;
}

.wv-radio [type='radio']:checked ~ .wv-radiocheck--faux::after {
  width: 6px;
  height: 6px;
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  border-radius: 50%;
  background: #fff;
}

.wv-radio [type='radio'][disabled] ~ .wv-radiocheck--faux {
  background-color: #dcdfe0;
  border-color: #c6cccd;
}

.wv-radio [type='radio'][disabled] ~ .wv-radiocheck--faux::after {
  background: #849194;
}

.has-error.wv-radio [type='radio']:checked ~ .wv-radiocheck--faux {
  background: #fff;
  border-color: #d74242;
}

.has-error.wv-radio [type='radio']:checked ~ .wv-radiocheck--faux::after {
  background: #d74242;
}

.wv-toggle {
  display: -webkit-inline-flex;
  display: inline-flex;
  margin-bottom: 8px;
  position: relative;
  cursor: pointer;
}

.wv-toggle.is-disabled {
  cursor: not-allowed;
}

.wv-toggle.is-disabled > .wv-toggle__handle {
  opacity: 0.4;
  cursor: not-allowed;
}

.wv-toggle .wv-toggle__label {
  margin-left: 8px;
}

.wv-toggle .wv-toggle__checkbox + .wv-toggle__handle {
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 48px;
  height: 20px;
  border-radius: 500px;
  background: #8aa2b2;
  transition: all 240ms;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  line-height: 16px;
}

.wv-toggle .wv-toggle__checkbox + .wv-toggle__handle::before,
.wv-toggle .wv-toggle__checkbox + .wv-toggle__handle::after {
  position: relative;
  top: 0;
  font-size: 11px;
  display: inline-block;
  color: white;
  font-weight: bold;
}

.wv-toggle .wv-toggle__checkbox + .wv-toggle__handle::before {
  content: 'ON';
  text-indent: -22px;
  width: 16px;
  height: 16px;
  margin: 0 0 0 2px;
  border-radius: inherit;
  background: #fff;
}

.wv-toggle .wv-toggle__checkbox + .wv-toggle__handle::after {
  content: 'OFF';
  text-indent: 3px;
}

.wv-toggle .wv-toggle__checkbox {
  position: absolute;
  opacity: 0;
}

.wv-toggle .wv-toggle__checkbox:checked + .wv-toggle__handle {
  padding-left: 28px;
  background: #1f7eea;
}

.wv-datepicker,
.wv-datepicker--left,
.wv-datepicker--right {
  position: relative;
  white-space: nowrap;
}

@media only screen and (min-width: 400px) {
  .wv-datepicker,
  .wv-datepicker--left,
  .wv-datepicker--right {
    display: inline-block;
  }
}

.wv-datepicker .wv-suffixed-input,
.wv-datepicker--left .wv-suffixed-input,
.wv-datepicker--right .wv-suffixed-input {
  min-width: 0;
  max-width: none;
  width: 160px;
  position: relative;
}

.wv-datepicker .wv-suffixed-input__suffix,
.wv-datepicker--left .wv-suffixed-input__suffix,
.wv-datepicker--right .wv-suffixed-input__suffix {
  background: #ecf0f3;
  height: 38px;
  margin-left: -41px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0;
  pointer-events: initial;
  cursor: pointer;
  outline: none;
  border: 0;
  border-left: 1px solid #b2c2cd;
}

.wv-datepicker .wv-suffixed-input__suffix .wv-svg-icon,
.wv-datepicker--left .wv-suffixed-input__suffix .wv-svg-icon,
.wv-datepicker--right .wv-suffixed-input__suffix .wv-svg-icon {
  margin: 0;
  color: #8aa2b2;
  pointer-events: none;
}

.wv-datepicker .wv-suffixed-input__suffix:hover:not(.is-disabled) .wv-svg-icon,
.wv-datepicker--left
  .wv-suffixed-input__suffix:hover:not(.is-disabled)
  .wv-svg-icon,
.wv-datepicker--right
  .wv-suffixed-input__suffix:hover:not(.is-disabled)
  .wv-svg-icon {
  color: #1c252c;
}

.wv-datepicker .wv-suffixed-input__suffix:focus:not(.is-disabled),
.wv-datepicker--left .wv-suffixed-input__suffix:focus:not(.is-disabled),
.wv-datepicker--right .wv-suffixed-input__suffix:focus:not(.is-disabled) {
  background: #e0f4fb;
}

.wv-datepicker .wv-suffixed-input__suffix:focus:not(.is-disabled) .wv-svg-icon,
.wv-datepicker--left
  .wv-suffixed-input__suffix:focus:not(.is-disabled)
  .wv-svg-icon,
.wv-datepicker--right
  .wv-suffixed-input__suffix:focus:not(.is-disabled)
  .wv-svg-icon {
  color: #136acd;
}

.wv-datepicker .wv-suffixed-input__suffix.is-disabled,
.wv-datepicker--left .wv-suffixed-input__suffix.is-disabled,
.wv-datepicker--right .wv-suffixed-input__suffix.is-disabled {
  pointer-events: none;
  background: #f1f3f3;
}

.wv-datepicker .wv-suffixed-input__suffix.is-disabled .wv-svg-icon,
.wv-datepicker--left .wv-suffixed-input__suffix.is-disabled .wv-svg-icon,
.wv-datepicker--right .wv-suffixed-input__suffix.is-disabled .wv-svg-icon {
  color: #b2c2cd;
}

.wv-datepicker__day {
  text-align: center;
  line-height: 2.3;
  padding: 0 8px;
  cursor: pointer;
  border-radius: 3px;
}

.wv-datepicker__day:hover {
  background: #f0f4fa;
}

.is-selected.wv-datepicker__day {
  background: #136acd;
  color: #fff;
  font-weight: bold;
}

.is-today.wv-datepicker__day {
  color: #136acd;
  font-weight: bold;
}

.is-disabled.wv-datepicker__day {
  cursor: not-allowed;
  pointer-events: none;
  color: #b2c2cd;
  background-color: #f0f4fa;
  border-radius: 0;
}

.is-out-of-month.wv-datepicker__day {
  color: #8aa2b2;
  background-color: transparent;
}

.is-out-of-month.wv-datepicker__day:hover {
  background: #f0f4fa;
}

.is-disabled.is-out-of-month.wv-datepicker__day {
  cursor: not-allowed;
  pointer-events: none;
  color: #b2c2cd;
  background-color: #f0f4fa;
}

.wv-datepicker__week-day {
  font-size: 12px;
  line-height: 16px;
  color: #8aa2b2;
  text-align: center;
}

.wv-datepicker__week-day abbr {
  text-decoration: none;
}

.wv-datepicker__panel {
  list-style: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-align: left;
  display: block;
  visibility: hidden;
  position: absolute;
  top: 110%;
  z-index: 1000;
  min-width: 100%;
  padding: 8px 0;
  border-radius: 4px;
  color: #1c252c;
  background-color: white;
  box-shadow: 0 0 0 1px rgba(77, 101, 117, 0.1),
    0 3px 10px 0 rgba(77, 101, 117, 0.2);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  height: 0;
  -webkit-transform: translateY(4px);
  transform: translateY(4px);
  padding: 16px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.is-open > .wv-datepicker__panel {
  visibility: visible;
  height: auto;
  -webkit-animation: panel-slide-down 0.1s ease-in-out forwards;
  animation: panel-slide-down 0.1s ease-in-out forwards;
}

.is-open.input-toggle > .wv-datepicker__panel {
  visibility: hidden;
}

@media only screen and (min-width: 481px) {
  .is-open.input-toggle > .wv-datepicker__panel {
    visibility: visible;
    -webkit-animation: panel-slide-down 0.1s ease-in-out forwards;
    animation: panel-slide-down 0.1s ease-in-out forwards;
  }
}

@media only screen and (max-width: 480px) {
  .is-open.icon-toggle > .wv-datepicker__panel {
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.wv-datepicker__header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid #d4dde3;
}

@media only screen and (min-width: 481px) {
  .wv-datepicker__header {
    display: none;
  }
}

.wv-datepicker__header .wv-close--large {
  position: relative;
}

.wv-datepicker__header [class^='wv-text'] {
  margin: 0;
}

@media only screen and (max-width: 480px) {
  .wv-datepicker__main {
    overflow-y: auto;
    padding: 16px;
    -webkit-flex-grow: 1;
    flex-grow: 1;
  }
}

.wv-datepicker__clear {
  text-align: center;
  padding: 16px 0;
  border: 0;
  background: none;
  border-top: 1px solid #d4dde3;
  font-size: inherit;
  font-family: inherit;
}

@media only screen and (min-width: 481px) {
  .wv-datepicker__clear {
    display: none;
  }
}

.wv-datepicker__grid {
  display: inline-block;
  vertical-align: top;
}

@media only screen and (max-width: 480px) {
  .wv-datepicker__grid {
    display: block;
    margin: auto;
  }
}

@media only screen and (min-width: 481px) {
  .wv-datepicker__grid {
    width: 280px;
  }
}

.with-quicklinks .wv-datepicker__grid {
  padding-right: 16px;
  border-right: 1px solid #d4dde3;
}

@media only screen and (max-width: 480px) {
  .with-quicklinks .wv-datepicker__grid {
    border-width: 0;
    padding: 0 0 16px 0;
  }
}

.wv-datepicker__filters {
  padding-bottom: 16px;
  text-align: center;
  position: relative;
}

.wv-datepicker__filter--back,
.wv-datepicker__filter--forward {
  display: inline-block;
  color: #8aa2b2;
  cursor: pointer;
}

.wv-datepicker__filter--back .wv-svg-icon,
.wv-datepicker__filter--forward .wv-svg-icon {
  position: relative;
  bottom: -4px;
}

.wv-datepicker__filter--back:hover,
.wv-datepicker__filter--forward:hover {
  color: #4d6575;
}

.wv-datepicker__filter--back {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 8px;
}

.wv-datepicker__filter--forward {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 8px;
}

.wv-datepicker__filter--month,
.wv-datepicker__filter--year {
  margin: 0 4px;
  font-weight: 600;
  cursor: pointer;
  display: inline-block;
}

.wv-datepicker__filter--month .wv-svg-icon,
.wv-datepicker__filter--year .wv-svg-icon {
  position: relative;
  top: 5px;
}

.wv-datepicker__filters .wv-dropdown__menu {
  min-width: 0;
  max-height: 296px;
  overflow-y: auto;
  top: -10px;
  left: -12px;
  -webkit-overflow-scrolling: touch;
}

.wv-datepicker__filters .wv-dropdown__menu .wv-dropdown__menu__link {
  font-weight: normal;
  padding: 1px 20px;
  line-height: 28px;
}

@media only screen and (max-width: 480px) {
  .wv-datepicker__filters .wv-dropdown__menu .wv-dropdown__menu__link {
    padding: 6px 20px;
  }
}

.wv-datepicker__month {
  width: 100%;
  border-collapse: collapse;
  overflow: hidden;
}

.wv-datepicker__quicklinks {
  display: inline-block;
  vertical-align: top;
}

@media only screen and (max-width: 480px) {
  .wv-datepicker__quicklinks {
    display: block;
    padding-top: 16px;
    border-top: 1px solid #d4dde3;
    margin: 0 auto;
  }
}

.wv-datepicker__quicklinks .wv-datepicker__quicklink {
  padding: 0 12px;
  margin: 0 0 4px;
  font-size: 13px;
  line-height: 28px;
}

.wv-datepicker--range {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
}

@media only screen and (min-width: 400px) {
  .wv-datepicker--range {
    -webkit-flex-direction: row;
    flex-direction: row;
  }
}

@media only screen and (min-width: 400px) {
  .wv-datepicker__from .wv-input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .wv-datepicker__to .wv-input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .wv-datepicker__from .wv-suffixed-input__suffix {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .wv-datepicker__to {
    margin-left: -1px;
  }
}

.wv-datepicker__from .wv-input:focus,
.wv-datepicker__to .wv-input:focus {
  position: relative;
  z-index: 1;
}

.wv-datepicker__from .wv-input:focus ~ .wv-suffixed-input__suffix,
.wv-datepicker__to .wv-input:focus ~ .wv-suffixed-input__suffix {
  z-index: 2;
}

.wv-datepicker.with-clearing .wv-suffixed-input {
  width: 180px;
}

.wv-datepicker.with-clearing .wv-close--medium {
  position: absolute;
  right: 48px;
  top: 12px;
  z-index: 1;
}

.wv-datepicker .wv-datepicker__panel,
.wv-datepicker--left .wv-datepicker__panel {
  left: 0;
}

.wv-datepicker--right .wv-datepicker__panel {
  right: 0;
}

.wv-datepicker--distant {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
  align-items: baseline;
}

.wv-datepicker--distant .wv-select {
  min-width: 0;
  max-width: none;
}

.wv-datepicker--distant .wv-select .wv-select__input {
  border-radius: inherit;
}

.wv-datepicker--distant .wv-select.has-error .wv-select__input {
  border-color: #d74242;
  z-index: 1;
}

.wv-datepicker--distant
  .wv-select.has-error
  .wv-select__input:focus:not(.is-disabled) {
  border-color: #c22929;
}

.wv-datepicker--distant .wv-select.is-open,
.wv-datepicker--distant .wv-select:active,
.wv-datepicker--distant .wv-select:focus {
  position: relative;
  z-index: 2;
}

.wv-datepicker--distant .wv-select:nth-child(1) {
  width: 160px;
  border-radius: 4px 0 0 4px;
}

.wv-datepicker--distant .wv-select:nth-child(2) {
  margin: 0 -1px 0 -1px;
  width: 80px;
  border-radius: 0;
}

.wv-datepicker--distant .wv-select:nth-child(3) {
  width: 80px;
  border-radius: 0 4px 4px 0;
}

.wv-select {
  position: relative;
  border-radius: 4px;
  outline: none;
  margin: 4px 0;
  display: inline-block;
  width: 100%;
  min-width: 160px;
  max-width: 320px;
}

.wv-select.wv-select--fluid {
  max-width: 100%;
  min-width: 0;
}

.wv-select.wv-select--xsmall {
  min-width: 80px;
  max-width: 80px;
}

.wv-select.wv-select--small {
  min-width: 160px;
  max-width: 160px;
}

.wv-select.wv-select--medium {
  max-width: 320px;
}

.wv-select.wv-select--large {
  max-width: 480px;
}

.wv-select.wv-select--xlarge {
  max-width: 480px;
}

.wv-select.wv-select--fluid {
  min-width: 160px;
}

.wv-select.is-open {
  transition: all 0.1s ease-in-out;
}

.wv-select.is-disabled .wv-select__toggle {
  background: #f1f3f3;
  border-color: #c6cccd;
  cursor: not-allowed;
}

.wv-select__input {
  display: inline-block;
  box-sizing: border-box;
  margin: 4px 0;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #b2c2cd;
  border-radius: 4px;
  cursor: text;
  padding: 8px 10px 6px;
  transition: border 0.1s linear;
  min-height: 40px;
  width: 100%;
  max-width: none;
  box-shadow: none;
  position: relative;
  cursor: pointer;
  color: #c6cccd;
  font-style: italic;
  padding-right: 26px;
  background: white;
  margin: 0;
}

.wv-select__input::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.wv-select__input.is-disabled {
  background: #f1f3f3;
  color: #849194;
  border-color: #c6cccd;
  pointer-events: none;
}

.wv-select__input.is-disabled::-webkit-input-placeholder {
  color: #b2c2cd;
}

.wv-select__input.is-disabled:-ms-input-placeholder {
  color: #b2c2cd;
}

.wv-select__input.is-disabled::placeholder {
  color: #b2c2cd;
}

.wv-select__input.is-read-only {
  background-color: transparent;
  border-color: transparent;
  padding-left: 0;
  padding-right: 0;
  pointer-events: none;
}

.has-error .wv-select__input {
  border-color: #d74242;
}

.has-error .wv-select__input:focus:not(.is-disabled) {
  border-color: #c22929;
}

.has-warning .wv-select__input {
  border-color: #dcd318;
}

.has-warning .wv-select__input:focus:not(.is-disabled) {
  border-color: #c1b815;
}

.has-success .wv-select__input {
  border-color: #23c770;
}

.has-success .wv-select__input:focus:not(.is-disabled) {
  border-color: #18864b;
}

.wv-select__input::-webkit-input-placeholder {
  font-style: italic;
  color: #b2c2cd;
}

.wv-select__input:-ms-input-placeholder {
  font-style: italic;
  color: #b2c2cd;
}

.wv-select__input::placeholder {
  font-style: italic;
  color: #b2c2cd;
}

.wv-select__input:focus:not(.is-disabled) {
  border-color: #136acd;
}

.wv-select__input:focus:not(.is-disabled)::-webkit-input-placeholder {
  opacity: 0.7;
}

.wv-select__input:focus:not(.is-disabled):-ms-input-placeholder {
  opacity: 0.7;
}

.wv-select__input:focus:not(.is-disabled)::placeholder {
  opacity: 0.7;
}

.is-open > .wv-select__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.has-selection .wv-select__input {
  color: #1c252c;
  font-style: normal;
}

.is-clearable .wv-select__input {
  padding-right: 48px;
}

.wv-select.wv-select--xlarge .wv-select__input {
  padding: 12px 16px;
  font-size: 20px;
  line-height: 1.4;
  padding-right: 32px;
}

@media (min-width: 480px) {
  .wv-select.wv-select--xlarge .wv-select__input {
    font-size: 23px;
  }
}

.is-open:not(.is-disabled) > .wv-select__input,
.wv-select:focus:not(.is-disabled) .wv-select__input {
  transition: all 0.1s ease-in-out;
  border-color: #136acd;
  background: #fff;
}

.wv-select:focus.has-error .wv-select__input,
.wv-select.is-open.has-error .wv-select__input,
.has-error .wv-select.is-open .wv-select__input {
  border-color: #d74242;
}

.wv-select__label {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  color: #b2c2cd;
}

.has-selection .wv-select__label {
  color: #1c252c;
}

.is-disabled .wv-select__label {
  color: #849194;
}

.wv-select__menu {
  list-style: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-align: left;
  display: block;
  visibility: hidden;
  position: absolute;
  top: 110%;
  z-index: 1000;
  min-width: 100%;
  padding: 8px 0;
  border-radius: 4px;
  color: #1c252c;
  background-color: white;
  box-shadow: 0 0 0 1px rgba(77, 101, 117, 0.1),
    0 3px 10px 0 rgba(77, 101, 117, 0.2);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  height: 0;
  -webkit-transform: translateY(4px);
  transform: translateY(4px);
  overflow: hidden;
  padding: 0;
  right: 0;
  left: 0;
  top: 100%;
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.wv-select__menu > li {
  display: block;
}

.is-open > .wv-select__menu {
  visibility: visible;
  height: auto;
  -webkit-animation: panel-slide-down 0.1s ease-in-out forwards;
  animation: panel-slide-down 0.1s ease-in-out forwards;
}

.wv-select__menu__options {
  list-style: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-align: left;
  max-height: 460px;
  overflow: auto;
}

.wv-select__menu__options .wv-radiocheck--faux {
  margin-top: 0;
}

.wv-select__menu__options .wv-form-field__label {
  margin-right: 0;
}

.wv-select__menu__option {
  padding: 8px 10px;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  position: relative;
}

.wv-select__menu__option.is-selected:not(.is-disabled) {
  background-color: #136acd;
  color: #fff;
}

.wv-select__menu__option.is-active:not(.is-selected):not(.is-disabled) {
  background-color: #f0f4fa;
}

.wv-select__menu__option.is-disabled {
  opacity: 0.3;
  cursor: not-allowed;
  pointer-events: none;
  background-color: transparent;
}

.wv-select__menu__option .wv-checkbox {
  margin-top: 3px;
}

.wv-select__menu__option.is-group-option {
  padding-left: 24px;
}

.wv-select__menu__option.is-group-option.is-multiselect {
  padding-left: 36px;
}

.wv-select__menu__option.has-description {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-right: 32px;
}

.wv-select__menu__option.has-description .wv-select__menu__option__label {
  width: calc(100% - 26px);
}

.wv-select__menu__option__description {
  display: none;
  background: inherit;
  margin-right: -22px;
  margin-top: 4px;
  width: 100%;
}

.description-is-visible .wv-select__menu__option__description {
  display: block;
}

.is-selected .wv-select__menu__option__description {
  color: inherit;
}

.is-multiselect .wv-select__menu__option__description {
  margin-left: 26px;
}

.wv-select__menu__option__description-toggle {
  display: none;
  position: absolute;
  top: 11px;
  right: 10px;
  color: #8aa2b2;
}

.wv-select__menu__option__description-toggle:hover {
  color: #1c252c;
}

.description-is-visible .wv-select__menu__option__description-toggle {
  display: block;
}

.is-active .wv-select__menu__option__description-toggle {
  display: block;
}

.is-selected .wv-select__menu__option__description-toggle {
  color: inherit;
}

.wv-select__menu__title {
  font-weight: bold;
  padding: 16px 10px 4px;
  margin-top: 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  border-top: 1px solid #d4dde3;
}

.wv-select__menu__title .wv-form-element {
  display: -webkit-flex;
  display: flex;
}

.wv-select__menu__title:first-child {
  border-top-width: 0;
  margin-top: 0;
}

.wv-select__menu__title .wv-checkbox {
  vertical-align: top;
  margin-top: -1px;
  margin-right: 4px;
}

.wv-select__menu__title .wv-radiocheck--faux {
  margin-right: 4px;
}

.wv-select__menu__divider {
  display: block;
  height: 0;
  padding: 0;
  border: none;
  border-bottom: 1px solid #d4dde3;
  overflow: hidden;
  margin: 8px 0;
}

.wv-select__input__icon {
  color: #4d6575;
  position: absolute;
  top: 10px;
  right: 6px;
}

.wv-select__input__icon:empty {
  color: #4d6575;
  padding-top: 0;
}

.wv-select.wv-select--xlarge .wv-select__input__icon {
  font-size: 20px;
  line-height: 1.4;
  top: 14px;
}

@media (min-width: 480px) {
  .wv-select.wv-select--xlarge .wv-select__input__icon {
    font-size: 23px;
  }
}

.wv-select.wv-select--xlarge .wv-select__input__icon .wv-svg-icon {
  width: 26px;
  height: 26px;
}

.wv-select .wv-close--medium {
  top: 6px;
  right: 24px;
}

.wv-select__search-container {
  padding: 8px;
}

.wv-select__search-container .wv-prefixed-input {
  max-width: 100%;
  min-width: 0;
}

.wv-select__search-container .wv-prefixed-input .wv-input {
  margin: 0;
}

.wv-select__footer {
  text-align: center;
  border-top: 1px solid #b2c2cd;
  padding: 8px;
  cursor: pointer;
  color: #136acd;
  font-weight: bold;
}

.wv-select__footer [class*='wv-icon'] {
  margin-right: 8px;
}

.wv-select__footer .wv-svg-icon {
  position: relative;
  bottom: -4px;
  margin-right: 8px;
}

.wv-select__footer .wv-text--link-external {
  background-image: none;
}

.wv-select__footer:hover,
.wv-select__footer:focus {
  background: #136acd;
  color: white;
  border-top-color: #136acd;
}

.wv-select__footer:hover .wv-text--link,
.wv-select__footer:hover .wv-text--link-external,
.wv-select__footer:focus .wv-text--link,
.wv-select__footer:focus .wv-text--link-external {
  color: white;
}

.wv-select__footer:hover .wv-text--link:hover,
.wv-select__footer:hover .wv-text--link-external:hover,
.wv-select__footer:focus .wv-text--link:hover,
.wv-select__footer:focus .wv-text--link-external:hover {
  text-decoration: none;
}

.wv-select__footer:focus {
  outline: none;
}

.wv-select__menu__no-results {
  text-align: center;
  padding: 4px 8px 12px;
}

.wv-form-field__label,
.wv-form-field__label--align-top {
  vertical-align: top;
  position: relative;
  box-sizing: border-box;
}

.wv-form-fieldset {
  border: 0;
  padding: 0;
  margin: 0 0 24px 0;
  width: 100%;
}

.wv-form-legend {
  padding: 0;
  text-align: left;
  color: #1c252c;
}

.wv-form-field {
  display: -webkit-flex;
  display: flex;
}

.wv-form-field.is-required > [class*='wv-form-field__label']::after,
.wv-form-field.is-required
  .wv-form-fieldset--address
  [class*='wv-form-field__label']::after,
.wv-form-field.is-required.is-floating
  .wv-form-field__wrapper
  > [class*='wv-form-field__label']::after {
  content: '*';
  color: #c22929;
  vertical-align: bottom;
  margin-left: 1px;
  font-size: 1.25em;
  position: absolute;
}

.wv-form-field__label__text {
  display: inline;
}

.wv-form-field__label--blank {
  display: none;
}

.wv-form-field__label--align-top {
  padding-top: 0;
}

.wv-form-field__element {
  box-sizing: border-box;
}

.has-error .wv-form-field__hint {
  color: #c22929;
}

.has-success .wv-form-field__hint {
  color: #18864b;
}

.has-warning .wv-form-field__hint {
  color: #7c760e;
}

.wv-radio + .wv-form-field__hint,
.wv-checkbox + .wv-form-field__hint {
  margin-left: 26px;
  margin-bottom: 8px;
}

.wv-form-field__meta {
  display: inline-block;
  position: relative;
  top: 5px;
  left: 6px;
}

.wv-form-field__meta .wv-button--icon,
.wv-form-field__meta .wv-svg-icon {
  position: relative;
  bottom: -2px;
}

.wv-form-field__meta .wv-button--icon .wv-svg-icon {
  bottom: 0;
}

.wv-form-field__read-only {
  margin-top: 11px;
}

.wv-form--inline .wv-form-field {
  display: inline-block;
  margin: 0 2px;
}

.wv-form--vertical .wv-form-field {
  display: block;
}

.wv-form--vertical .wv-form-field + .wv-form-field:not(.is-stacked) {
  margin-top: 8px;
}

@media only screen and (min-width: 550px) {
  .wv-form--horizontal .wv-form-field > .wv-form-field__label,
  .wv-form--horizontal .wv-form-field > .wv-form-field__label--align-top,
  .wv-form--horizontal .wv-form-fieldset--address > .wv-form-field__label,
  .wv-form--horizontal
    .wv-form-fieldset--address
    > .wv-form-field__label--align-top {
    width: 35%;
    padding: 12px 14px 12px 0;
    margin-bottom: 0;
    text-align: right;
  }

  .wv-form--horizontal .wv-form-field > .wv-form-field__label--align-top {
    padding-top: 0;
  }

  .wv-form--horizontal .wv-form-field__element {
    vertical-align: top;
    width: 65%;
    margin-left: auto;
    text-align: left;
  }

  .wv-form--horizontal .wv-form-field {
    margin-bottom: 8px;
  }
}

@media only screen and (max-width: 549px) {
  .wv-form--horizontal .wv-form-field {
    display: block;
  }

  .wv-form--horizontal .wv-form-field + .wv-form-field:not(.is-stacked) {
    margin-top: 8px;
  }
}

.wv-form-field.is-floating {
  position: relative;
  margin-top: 8px;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.wv-form-field.is-floating > .wv-form-field__wrapper {
  display: inline-block;
  position: relative;
}

.wv-form-field.is-floating
  > .wv-form-field__wrapper.wv-form-field__wrapper--fluid,
.wv-form-field.is-floating
  > .wv-form-field__wrapper.wv-form-field__wrapper--xsmall,
.wv-form-field.is-floating
  > .wv-form-field__wrapper.wv-form-field__wrapper--small,
.wv-form-field.is-floating
  > .wv-form-field__wrapper.wv-form-field__wrapper--medium,
.wv-form-field.is-floating
  > .wv-form-field__wrapper.wv-form-field__wrapper--large,
.wv-form-field.is-floating
  > .wv-form-field__wrapper.wv-form-field__wrapper--xlarge {
  width: 100%;
  min-width: 160px;
}

.wv-form-field.is-floating
  > .wv-form-field__wrapper.wv-form-field__wrapper--date {
  width: 160px;
}

.wv-form-field.is-floating
  > .wv-form-field__wrapper.wv-form-field__wrapper--date
  .wv-form-field__label {
  right: 48px;
}

.wv-form-field.is-floating
  > .wv-form-field__wrapper.wv-form-field__wrapper--fluid {
  max-width: 100%;
  min-width: 0;
}

.wv-form-field.is-floating
  > .wv-form-field__wrapper.wv-form-field__wrapper--xsmall {
  min-width: 80px;
  max-width: 80px;
}

.wv-form-field.is-floating
  > .wv-form-field__wrapper.wv-form-field__wrapper--small {
  min-width: 160px;
  max-width: 160px;
}

.wv-form-field.is-floating
  > .wv-form-field__wrapper.wv-form-field__wrapper--medium {
  max-width: 320px;
}

.wv-form-field.is-floating
  > .wv-form-field__wrapper.wv-form-field__wrapper--large {
  max-width: 480px;
}

.wv-form-field.is-floating
  > .wv-form-field__wrapper.wv-form-field__wrapper--xlarge {
  max-width: 480px;
}

.wv-form-field.is-floating > .wv-form-field__wrapper > .wv-form-field__label {
  position: absolute;
  top: 5px;
  left: 2px;
  right: 22px;
  display: inline-block;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.025em;
  padding: 6px 10px 4px 10px;
  margin: 0;
  z-index: 2;
  color: #4d6575;
  border-radius: 3px;
  background-color: white;
  pointer-events: none;
}

.wv-form-field.is-floating
  > .wv-form-field__wrapper
  > .wv-form-field__label
  .wv-form-field__label__text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: calc(100% - 18px);
}

.wv-form-field.is-floating
  > .wv-form-field__wrapper
  > .wv-form-field__label
  [class*='wv-icon'] {
  line-height: inherit;
}

.wv-form-field.is-floating
  > .wv-form-field__wrapper
  > .wv-form-field__label
  .wv-svg-icon {
  width: 13px;
  height: 13px;
  bottom: -1px;
}

.wv-form-field.is-floating.is-focused > .wv-form-field__label {
  color: #136acd;
}

.wv-form-field.is-floating.has-success > .wv-form-field__label {
  color: #18864b;
}

.wv-form-field.is-floating.has-warning > .wv-form-field__label {
  color: #7c760e;
}

.wv-form-field.is-floating.has-error > .wv-form-field__label {
  color: #c22929;
}

.wv-form-field.is-floating.is-disabled .wv-input,
.wv-form-field.is-floating.is-disabled .wv-textarea,
.wv-form-field.is-floating.is-disabled .wv-select__toggle,
.wv-form-field.is-floating.is-disabled .wv-datepicker__toggle,
.wv-form-field.is-floating.is-disabled .wv-form-field__read-only {
  background: #f1f3f3;
  color: #849194;
  pointer-events: none;
}

.wv-form-field.is-floating.is-disabled .wv-form-field__label {
  background: #f1f3f3;
  color: #849194;
  pointer-events: none;
}

.wv-form-field.is-floating .wv-form-field__element .wv-input,
.wv-form-field.is-floating .wv-form-field__element .wv-textarea,
.wv-form-field.is-floating .wv-form-field__element .wv-select__toggle,
.wv-form-field.is-floating .wv-form-field__element .wv-datepicker__toggle,
.wv-form-field.is-floating .wv-form-field__element .wv-form-field__read-only {
  padding-top: 22px;
  padding-bottom: 6px;
  min-height: 56px;
}

.wv-form-field.is-floating .wv-form-field__element .wv-prefixed-input,
.wv-form-field.is-floating .wv-form-field__element .wv-suffixed-input {
  -webkit-align-items: baseline;
  align-items: baseline;
}

.wv-form-field.is-floating .wv-form-field__element .wv-form-field__read-only {
  padding-left: 12px;
}

.wv-form-field.is-floating .wv-form-field__element .wv-select__input__icon {
  top: auto;
  bottom: 4px;
}

.wv-form-field.is-floating .wv-form-field__element .wv-close--medium {
  top: auto;
  bottom: 12px;
}

.wv-form-field.is-floating .wv-form-field__element .wv-select {
  margin: 4px 0;
}

.wv-form-field.is-floating .wv-form-field__element .wv-select .wv-input {
  padding-top: 8px;
  padding-bottom: 8px;
}

.wv-form-field.is-floating
  .wv-form-field__element
  .wv-select__search-container
  .wv-input {
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 40px;
}

.wv-form-field.is-floating
  .wv-form-field__element
  .wv-datepicker
  .wv-suffixed-input__suffix {
  height: 54px;
  min-height: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 40px;
  padding-top: 0;
  -webkit-align-self: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.wv-form-field.is-floating .wv-form-field__hint,
.wv-form-field.is-floating .wv-input__message {
  padding-left: 4px;
}

.wv-form-field.is-floating .wv-input--xlarge {
  padding-left: 10px;
  padding-right: 10px;
}

@media only screen and (max-width: 420px) {
  .wv-form--vertical input[type='text'],
  .wv-form--vertical input[type='password'],
  .wv-form--vertical input[type='email'],
  .wv-form--vertical input[type='search'],
  .wv-form--vertical input[type='tel'],
  .wv-form--vertical input[type='url'],
  .wv-form--vertical input[type='submit'],
  .wv-form--vertical input[type='button'],
  .wv-form--vertical textarea,
  .wv-form--horizontal input[type='text'],
  .wv-form--horizontal input[type='password'],
  .wv-form--horizontal input[type='email'],
  .wv-form--horizontal input[type='search'],
  .wv-form--horizontal input[type='tel'],
  .wv-form--horizontal input[type='url'],
  .wv-form--horizontal input[type='submit'],
  .wv-form--horizontal input[type='button'],
  .wv-form--horizontal textarea,
  .wv-form--inline input[type='text'],
  .wv-form--inline input[type='password'],
  .wv-form--inline input[type='email'],
  .wv-form--inline input[type='search'],
  .wv-form--inline input[type='tel'],
  .wv-form--inline input[type='url'],
  .wv-form--inline input[type='submit'],
  .wv-form--inline input[type='button'],
  .wv-form--inline textarea {
    max-width: none;
  }
}

.wv-form--vertical .has-meta input[type='text'],
.wv-form--vertical .has-meta input[type='password'],
.wv-form--vertical .has-meta input[type='email'],
.wv-form--vertical .has-meta input[type='search'],
.wv-form--vertical .has-meta input[type='tel'],
.wv-form--vertical .has-meta input[type='url'],
.wv-form--vertical .has-meta input[type='submit'],
.wv-form--vertical .has-meta input[type='button'],
.wv-form--vertical .has-meta textarea,
.wv-form--horizontal .has-meta input[type='text'],
.wv-form--horizontal .has-meta input[type='password'],
.wv-form--horizontal .has-meta input[type='email'],
.wv-form--horizontal .has-meta input[type='search'],
.wv-form--horizontal .has-meta input[type='tel'],
.wv-form--horizontal .has-meta input[type='url'],
.wv-form--horizontal .has-meta input[type='submit'],
.wv-form--horizontal .has-meta input[type='button'],
.wv-form--horizontal .has-meta textarea,
.wv-form--inline .has-meta input[type='text'],
.wv-form--inline .has-meta input[type='password'],
.wv-form--inline .has-meta input[type='email'],
.wv-form--inline .has-meta input[type='search'],
.wv-form--inline .has-meta input[type='tel'],
.wv-form--inline .has-meta input[type='url'],
.wv-form--inline .has-meta input[type='submit'],
.wv-form--inline .has-meta input[type='button'],
.wv-form--inline .has-meta textarea {
  width: calc(100% - 30px);
}

.wv-form--horizontal .wv-form-fieldset--address {
  margin: 0;
}

@media only screen and (max-width: 549px) {
  .wv-form--horizontal .wv-form-fieldset--address {
    -webkit-flex-direction: column;
    flex-direction: column;
  }
}

.wv-form--horizontal
  .wv-form-fieldset--address
  .wv-form-field__element.wv-input--medium {
  margin-bottom: 4px;
}

@media only screen and (min-width: 768px) {
  .wv-form--horizontal .wv-form-fieldset--address .wv-form-field__element {
    display: -webkit-inline-flex;
    display: inline-flex;
  }
}

@media only screen and (min-width: 771px) {
  .wv-form--horizontal
    .wv-form-fieldset--address
    .wv-form-field__element.wv-input--large {
    width: auto;
    max-width: 480px;
  }
}

.wv-form--vertical .wv-form-fieldset--address {
  -webkit-flex-direction: column;
  flex-direction: column;
  margin: 10px 0 14px 0;
}

.wv-form--vertical .wv-form-fieldset--address .wv-form-field__label {
  display: block;
}

.wv-form--vertical .wv-form-fieldset--address .wv-form-field__element {
  width: 100%;
}

.wv-form--vertical
  .wv-form-fieldset--address
  .wv-form-field__element.wv-input--large {
  width: auto;
  max-width: 480px;
}

.wv-form--horizontal .wv-form-fieldset--address,
.wv-form--vertical .wv-form-fieldset--address {
  display: -webkit-flex;
  display: flex;
}

.wv-form--horizontal .wv-form-fieldset--address .wv-form-field__element,
.wv-form--vertical .wv-form-fieldset--address .wv-form-field__element {
  margin-top: 4px;
  margin-left: 0;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
}

@media only screen and (min-width: 421px) {
  .wv-form--horizontal
    .wv-form-fieldset--address
    .wv-form-field__element.wv-input--medium,
  .wv-form--vertical
    .wv-form-fieldset--address
    .wv-form-field__element.wv-input--medium {
    width: 320px;
  }
}

.wv-form-fieldset--address .wv-input,
.wv-form-fieldset--address .wv-select {
  min-width: 0;
  max-width: none;
  margin: -1px 0 0 -1px;
}

.wv-form-fieldset--address .wv-select .wv-select__input {
  margin: 0;
}

.wv-form-fieldset--address .wv-select__search-container .wv-input {
  z-index: auto;
}

.wv-form-fieldset--address .wv-input.has-error,
.wv-form-fieldset--address .wv-select.has-error .wv-select__input {
  border-color: #d74242;
  z-index: 1;
}

.wv-form-fieldset--address .wv-input.has-error:focus:not(.is-disabled),
.wv-form-fieldset--address
  .wv-select.has-error
  .wv-select__input:focus:not(.is-disabled) {
  border-color: #c22929;
}

.wv-form-fieldset--address .wv-input:focus,
.wv-form-fieldset--address .wv-input:active {
  z-index: 2;
}

.wv-form-fieldset--address .wv-select.is-open,
.wv-form-fieldset--address .wv-select:active,
.wv-form-fieldset--address .wv-select:focus {
  z-index: 2;
}

.wv-form-fieldset--address.wv-form-fieldset--address--usa .wv-input__message,
.wv-form-fieldset--address.wv-form-fieldset--address--canada .wv-input__message,
.wv-form-fieldset--address.wv-form-fieldset--address--universal
  .wv-input__message {
  margin-top: 4px;
  width: 100%;
}

.wv-form-fieldset--address.wv-form-fieldset--address--usa
  .wv-input.wv-input--street,
.wv-form-fieldset--address.wv-form-fieldset--address--canada
  .wv-input.wv-input--street {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  width: calc(100% - (90px - 1px));
}

.wv-form-fieldset--address.wv-form-fieldset--address--usa
  .wv-input.wv-input--street-no-unit,
.wv-form-fieldset--address.wv-form-fieldset--address--canada
  .wv-input.wv-input--street-no-unit {
  width: 100%;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.wv-form-fieldset--address.wv-form-fieldset--address--usa
  .wv-input.wv-input--unit,
.wv-form-fieldset--address.wv-form-fieldset--address--canada
  .wv-input.wv-input--unit {
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  width: 90px;
}

.wv-form-fieldset--address.wv-form-fieldset--address--usa
  .wv-input.wv-input--city,
.wv-form-fieldset--address.wv-form-fieldset--address--canada
  .wv-input.wv-input--city {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

@media only screen and (max-width: 420px) {
  .wv-form-fieldset--address.wv-form-fieldset--address--usa
    .wv-input.wv-input--city,
  .wv-form-fieldset--address.wv-form-fieldset--address--canada
    .wv-input.wv-input--city {
    border-bottom-left-radius: 0;
  }
}

.wv-form-fieldset--address.wv-form-fieldset--address--usa
  .wv-select.wv-select--state,
.wv-form-fieldset--address.wv-form-fieldset--address--usa
  .wv-input.wv-input--state,
.wv-form-fieldset--address.wv-form-fieldset--address--canada
  .wv-select.wv-select--state,
.wv-form-fieldset--address.wv-form-fieldset--address--canada
  .wv-input.wv-input--state {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: calc(50% + 1px);
}

@media only screen and (min-width: 421px) {
  .wv-form-fieldset--address.wv-form-fieldset--address--usa
    .wv-select.wv-select--state,
  .wv-form-fieldset--address.wv-form-fieldset--address--usa
    .wv-input.wv-input--state,
  .wv-form-fieldset--address.wv-form-fieldset--address--canada
    .wv-select.wv-select--state,
  .wv-form-fieldset--address.wv-form-fieldset--address--canada
    .wv-input.wv-input--state {
    border-bottom-left-radius: 0;
  }
}

.wv-form-fieldset--address.wv-form-fieldset--address--usa
  .wv-select.wv-select--state
  .wv-select__input,
.wv-form-fieldset--address.wv-form-fieldset--address--usa
  .wv-input.wv-input--state
  .wv-select__input,
.wv-form-fieldset--address.wv-form-fieldset--address--canada
  .wv-select.wv-select--state
  .wv-select__input,
.wv-form-fieldset--address.wv-form-fieldset--address--canada
  .wv-input.wv-input--state
  .wv-select__input {
  border-radius: inherit;
}

.wv-form-fieldset--address.wv-form-fieldset--address--usa
  .wv-input.wv-input--zip,
.wv-form-fieldset--address.wv-form-fieldset--address--canada
  .wv-input.wv-input--zip {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  width: 50%;
}

@media only screen and (min-width: 421px) {
  .wv-form-fieldset--address.wv-form-fieldset--address--usa
    .wv-input.wv-input--zip,
  .wv-form-fieldset--address.wv-form-fieldset--address--canada
    .wv-input.wv-input--zip {
    width: 90px;
  }
}

@media only screen and (min-width: 421px) {
  .wv-form-fieldset--address.wv-form-fieldset--address--usa
    .wv-input.wv-input--city {
    width: calc(100% - (120px - 1px) - (90px - 1px));
  }
}

@media only screen and (min-width: 421px) {
  .wv-form-fieldset--address.wv-form-fieldset--address--usa
    .wv-select.wv-select--state {
    width: 120px;
  }
}

@media only screen and (min-width: 421px) {
  .wv-form-fieldset--address.wv-form-fieldset--address--canada
    .wv-input.wv-input--city {
    width: calc(100% - (120px - 1px) - (90px - 1px));
  }
}

@media only screen and (min-width: 421px) {
  .wv-form-fieldset--address.wv-form-fieldset--address--canada
    .wv-select.wv-select--state,
  .wv-form-fieldset--address.wv-form-fieldset--address--canada
    .wv-input.wv-input--state {
    width: 120px;
  }
}

.wv-form-fieldset--address.wv-form-fieldset--address--universal
  .wv-input.wv-input--street,
.wv-form-fieldset--address.wv-form-fieldset--address--universal
  .wv-input.wv-input--unit,
.wv-form-fieldset--address.wv-form-fieldset--address--universal
  .wv-input.wv-input--city,
.wv-form-fieldset--address.wv-form-fieldset--address--universal
  .wv-select.wv-select--state,
.wv-form-fieldset--address.wv-form-fieldset--address--universal
  .wv-input.wv-input--state,
.wv-form-fieldset--address.wv-form-fieldset--address--universal
  .wv-input.wv-input--zip,
.wv-form-fieldset--address.wv-form-fieldset--address--universal
  .wv-select.wv-select--country {
  width: 100%;
}

@media only screen and (min-width: 1024px) {
  .wv-form-fieldset--address.wv-form-fieldset--address--universal
    .wv-input.wv-input--city,
  .wv-form-fieldset--address.wv-form-fieldset--address--universal
    .wv-select.wv-select--state,
  .wv-form-fieldset--address.wv-form-fieldset--address--universal
    .wv-input.wv-input--state,
  .wv-form-fieldset--address.wv-form-fieldset--address--universal
    .wv-select.wv-select--country,
  .wv-form-fieldset--address.wv-form-fieldset--address--universal
    .wv-input.wv-input--zip {
    width: calc(50% + 0.5px);
  }
}

.wv-form-fieldset--address.wv-form-fieldset--address--universal
  .wv-input.wv-input--unit,
.wv-form-fieldset--address.wv-form-fieldset--address--universal
  .wv-select.wv-select--country,
.wv-form-fieldset--address.wv-form-fieldset--address--universal
  .wv-select.wv-select--state,
.wv-form-fieldset--address.wv-form-fieldset--address--universal
  .wv-input.wv-input--state {
  border-radius: 0;
}

.wv-form-fieldset--address.wv-form-fieldset--address--universal
  .wv-select.wv-select--country
  .wv-select__input,
.wv-form-fieldset--address.wv-form-fieldset--address--universal
  .wv-select.wv-select--state
  .wv-select__input,
.wv-form-fieldset--address.wv-form-fieldset--address--universal
  .wv-input.wv-input--state
  .wv-select__input {
  border-radius: 0;
}

.wv-form-fieldset--address.wv-form-fieldset--address--universal
  .wv-input.wv-input--street {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.wv-form-fieldset--address.wv-form-fieldset--address--universal
  .wv-input.wv-input--city {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

@media only screen and (max-width: 1024px) {
  .wv-form-fieldset--address.wv-form-fieldset--address--universal
    .wv-input.wv-input--city {
    border-bottom-left-radius: 0;
  }
}

.wv-form-fieldset--address.wv-form-fieldset--address--universal
  .wv-input.wv-input--zip {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

@media only screen and (min-width: 1024px) {
  .wv-form-fieldset--address.wv-form-fieldset--address--universal
    .wv-input.wv-input--zip {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.wv-tile-button {
  text-align: center;
  -webkit-flex-shrink: 1;
  flex-shrink: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  box-sizing: border-box;
  text-decoration: none;
  border-radius: 12px;
  border: 1px solid #b2c2cd;
  background-color: #fff;
  transition: all 0.25s;
}

.wv-tile-button:hover:not(.is-disabled) {
  cursor: pointer;
  box-shadow: 0 8px 32px rgba(77, 101, 117, 0.35);
  border-color: transparent;
  -webkit-transform: translateY(-4px);
  transform: translateY(-4px);
}

.wv-tile-button:focus:not(.is-disabled),
.wv-tile-button:active:not(.is-disabled) {
  outline: 0;
  box-shadow: 0 0 0 4px rgba(19, 106, 205, 0.2);
  border-color: #136acd;
}

.wv-tile-button:focus:not(.is-disabled):hover,
.wv-tile-button:active:not(.is-disabled):hover {
  box-shadow: 0 0 0 4px rgba(19, 106, 205, 0.2),
    0 8px 32px rgba(19, 106, 205, 0.2);
}

.is-disabled.wv-tile-button {
  opacity: 0.5;
  cursor: not-allowed;
}

.wv-tile-button {
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  margin: 8px;
  max-width: 256px;
  min-width: 200px;
  -webkit-flex-basis: 200px;
  flex-basis: 200px;
  padding: 24px;
}

.wv-tile-button__illustration {
  text-align: center;
}

.wv-tile-button__illustration img {
  width: 80%;
}

.wv-tile-button__main {
  margin-top: 16px;
  min-width: 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.wv-tile-button__label {
  margin: 0;
}

.wv-tile-button__description {
  margin: 8px 0 0;
}

.wv-tile-button__badge {
  margin-top: 16px;
}

.wv-tile-button.wv-tile-button--horizontal {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-direction: row;
  flex-direction: row;
  text-align: left;
  max-width: 504px;
  min-width: 320px;
  -webkit-flex-basis: 320px;
  flex-basis: 320px;
}

.wv-tile-button.wv-tile-button--horizontal .wv-tile-button__illustration {
  max-width: 30%;
  min-width: 96px;
  margin-right: 16px;
}

.wv-tile-button.wv-tile-button--horizontal .wv-tile-button__illustration img {
  width: 100%;
}

.wv-tile-button.wv-tile-button--horizontal .wv-tile-button__main {
  margin-top: 0;
  -webkit-flex: 1;
  flex: 1;
}

.wv-tile-button.wv-tile-button--small {
  max-width: 176px;
  min-width: 160px;
  -webkit-flex-basis: 160px;
  flex-basis: 160px;
}

.wv-tile-button.wv-tile-button--small .wv-tile-button__main {
  margin-top: 8px;
}

.wv-tile-button.wv-tile-button--small.wv-tile-button--horizontal {
  max-width: 296px;
  min-width: 232px;
  -webkit-flex-basis: 232px;
  flex-basis: 232px;
}

.wv-tile-button.wv-tile-button--small.wv-tile-button--horizontal
  .wv-tile-button__illustration {
  max-width: none;
  min-width: auto;
  width: 64px;
  height: 64px;
}

.wv-tile-button.wv-tile-button--small.wv-tile-button--horizontal
  .wv-tile-button__main {
  margin-top: 0;
}

.wv-tile-button-group {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -8px;
}

.wv-tile-button-group.wv-tile-button-group--stack,
.wv-tile-button-group.wv-tile-button-group--small-stack {
  margin-left: auto;
  margin-right: auto;
  max-width: 504px;
}

.wv-tile-button-group.wv-tile-button-group--stack .wv-tile-button,
.wv-tile-button-group.wv-tile-button-group--small-stack .wv-tile-button {
  margin-left: 0;
  margin-right: 0;
}

.wv-tile-button-group.wv-tile-button-group--small-stack {
  max-width: 296px;
}

@media (max-width: 480px) {
  .wv-tile-button:not(.wv-tile-button--small) {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-direction: row;
    flex-direction: row;
    text-align: left;
    max-width: 504px;
    min-width: 320px;
    -webkit-flex-basis: 320px;
    flex-basis: 320px;
  }

  .wv-tile-button:not(.wv-tile-button--small) .wv-tile-button__illustration {
    max-width: 30%;
    min-width: 96px;
    margin-right: 16px;
  }

  .wv-tile-button:not(.wv-tile-button--small)
    .wv-tile-button__illustration
    img {
    width: 100%;
  }

  .wv-tile-button:not(.wv-tile-button--small) .wv-tile-button__main {
    margin-top: 0;
    -webkit-flex: 1;
    flex: 1;
  }

  .wv-tile-button.wv-tile-button--small {
    padding: 16px;
  }

  .wv-tile-button.wv-tile-button--small.wv-tile-button--horizontal {
    max-width: 100%;
  }

  .wv-tile-button-group.wv-tile-button-group--small-stack {
    max-width: none;
  }
}

@media (max-width: 400px) {
  .wv-tile-button.wv-tile-button--small {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-direction: row;
    flex-direction: row;
    text-align: left;
    max-width: 504px;
    min-width: 320px;
    -webkit-flex-basis: 320px;
    flex-basis: 320px;
    max-width: 296px;
    min-width: 232px;
    -webkit-flex-basis: 232px;
    flex-basis: 232px;
    max-width: 100%;
  }

  .wv-tile-button.wv-tile-button--small .wv-tile-button__illustration {
    max-width: 30%;
    min-width: 96px;
    margin-right: 16px;
  }

  .wv-tile-button.wv-tile-button--small .wv-tile-button__illustration img {
    width: 100%;
  }

  .wv-tile-button.wv-tile-button--small .wv-tile-button__main {
    margin-top: 0;
    -webkit-flex: 1;
    flex: 1;
  }

  .wv-tile-button.wv-tile-button--small .wv-tile-button__illustration {
    max-width: none;
    min-width: auto;
    width: 64px;
    height: 64px;
  }

  .wv-tile-button.wv-tile-button--small .wv-tile-button__main {
    margin-top: 0;
  }
}

.wv-progress {
  margin: 8px 0;
}

.wv-progress__bar {
  height: 8px;
  overflow: hidden;
  background-color: #d4dde3;
  border-radius: 8px;
}

.wv-progress__bar--small {
  height: 2px;
}

.wv-progress__amount {
  float: left;
  width: 0;
  height: 100%;
  background-color: #23c770;
  transition: width 0.6s ease;
}

.wv-progress__label {
  margin: 8px 0;
}

.wv-table__body .wv-table__row:last-child,
.wv-table__footer .wv-table__row:last-child,
.wv-table__rowgroup .wv-table__row:last-child {
  border-color: #d4dde3;
}

.wv-table__body:hover,
.wv-table__footer:hover,
.wv-table__rowgroup:hover {
  background-color: none;
}

.wv-table__header .wv-table__row:last-child {
  border-color: #b2c2cd;
}

.wv-table__row,
.wv-table__row--total {
  border-bottom: 1px solid #d4dde3;
}

.wv-table__body .wv-table__row:hover,
.wv-table__rowgroup .wv-table__row:hover {
  background-color: #e6f1ff;
}

.wv-table__body .wv-table__row.is-highlighted,
.wv-table__rowgroup .wv-table__row.is-highlighted {
  background-color: #e6f1ff !important;
}

.wv-table__body .wv-table__row.is-selected,
.wv-table__rowgroup .wv-table__row.is-selected {
  background-color: #f0f4fa !important;
}

.wv-table__row.is-muted {
  color: #4d6575 !important;
}

.wv-table,
.wv-table--fixed-width,
.wv-table--skeleton,
.wv-table--plain,
.wv-table--condensed {
  width: 100%;
  border-collapse: collapse;
}

.wv-table__header .wv-table__row {
  border-width: 2px;
}

.wv-table__cell,
.wv-table__cell--amount,
.wv-table__cell--grandtotal,
.wv-table__cell--subgroup,
.wv-table__cell--actions {
  padding: 5px 15px;
  text-align: left;
}

.wv-table__cell > .wv-svg-icon,
.wv-table__cell--amount > .wv-svg-icon,
.wv-table__cell--grandtotal > .wv-svg-icon,
.wv-table__cell--subgroup > .wv-svg-icon,
.wv-table__cell--actions > .wv-svg-icon {
  position: relative;
  bottom: -4px;
}

.wv-table__header .wv-table__cell,
.wv-table__header .wv-table__cell--amount,
.wv-table__header .wv-table__cell--grandtotal,
.wv-table__header .wv-table__cell--subgroup,
.wv-table__header .wv-table__cell--actions {
  padding-top: 20px;
  padding-bottom: 8px;
  font-weight: bold;
  vertical-align: bottom;
}

.wv-table__cell_top {
  padding-bottom: 0px !important;
}
.wv-table__cell_bottom {
  padding-top: 3px !important;
}

.wv-table__cell--amount {
  text-align: right;
}

.wv-table__body .wv-table__cell--amount {
  font-variant-numeric: tabular-nums;
}

.wv-table__cell--amount .wv-input__message {
  font-variant-numeric: initial;
}

.wv-table--fixed-width,
.wv-table--skeleton {
  table-layout: fixed;
}

.wv-table--fixed-width .wv-table__cell,
.wv-table--skeleton .wv-table__cell,
.wv-table--fixed-width .wv-table__cell--amount,
.wv-table--skeleton .wv-table__cell--amount,
.wv-table--fixed-width .wv-table__cell--grandtotal,
.wv-table--skeleton .wv-table__cell--grandtotal,
.wv-table--fixed-width .wv-table__cell--subgroup,
.wv-table--skeleton .wv-table__cell--subgroup,
.wv-table--fixed-width .wv-table__cell--actions,
.wv-table--skeleton .wv-table__cell--actions {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.wv-table__cell--select {
  text-align: center;
}

.wv-table__cell--select .wv-checkbox,
.wv-table__cell--select .wv-radio {
  vertical-align: text-bottom;
}

.wv-table__cell--grandtotal {
  text-align: right;
  border-top: 1px solid #373d3f;
  border-bottom: 2px solid #373d3f;
}

.wv-table__cell.is-highlighted,
.is-highlighted.wv-table__cell--amount,
.is-highlighted.wv-table__cell--grandtotal,
.is-highlighted.wv-table__cell--subgroup,
.is-highlighted.wv-table__cell--actions {
  background-color: #e6f1ff;
}

.wv-table__row--header .wv-table__cell.is-highlighted,
.wv-table__row--header .is-highlighted.wv-table__cell--amount,
.wv-table__row--header .is-highlighted.wv-table__cell--grandtotal,
.wv-table__row--header .is-highlighted.wv-table__cell--subgroup,
.wv-table__row--header .is-highlighted.wv-table__cell--actions {
  background-color: #c7e1ff;
}

.wv-table__row--subheader .wv-table__cell.is-highlighted,
.wv-table__row--subheader .is-highlighted.wv-table__cell--amount,
.wv-table__row--subheader .is-highlighted.wv-table__cell--grandtotal,
.wv-table__row--subheader .is-highlighted.wv-table__cell--subgroup,
.wv-table__row--subheader .is-highlighted.wv-table__cell--actions {
  background-color: #d6e9ff;
}

.wv-table--plain .wv-table__header {
  background-color: transparent;
}

.wv-table--plain .wv-table__row,
.wv-table--plain .wv-table__row--total {
  border-width: 0;
}

.wv-table--plain .wv-table__cell,
.wv-table--plain .wv-table__cell--amount,
.wv-table--plain .wv-table__cell--grandtotal,
.wv-table--plain .wv-table__cell--subgroup,
.wv-table--plain .wv-table__cell--actions {
  padding: 8px 15px;
}

.wv-table--plain .wv-table__body .wv-table__row:hover,
.wv-table--plain .wv-table__rowgroup .wv-table__row:hover {
  background-color: transparent;
}

.wv-table__cell--nested {
  display: block;
  margin: 0;
}

.wv-table__row--header .wv-table__cell,
.wv-table__row--header .wv-table__cell--amount,
.wv-table__row--header .wv-table__cell--grandtotal,
.wv-table__row--header .wv-table__cell--subgroup,
.wv-table__row--header .wv-table__cell--actions,
.wv-table__row--subheader .wv-table__cell,
.wv-table__row--subheader .wv-table__cell--amount,
.wv-table__row--subheader .wv-table__cell--grandtotal,
.wv-table__row--subheader .wv-table__cell--subgroup,
.wv-table__row--subheader .wv-table__cell--actions,
.wv-table__row--total .wv-table__cell,
.wv-table__row--total .wv-table__cell--amount,
.wv-table__row--total .wv-table__cell--grandtotal,
.wv-table__row--total .wv-table__cell--subgroup,
.wv-table__row--total .wv-table__cell--actions {
  padding-top: 8px;
  padding-bottom: 8px;
  vertical-align: top;
  font-weight: 600;
}

.wv-table__row--header .wv-table__cell,
.wv-table__row--header .wv-table__cell--amount,
.wv-table__row--header .wv-table__cell--grandtotal,
.wv-table__row--header .wv-table__cell--subgroup,
.wv-table__row--header .wv-table__cell--actions {
  background-color: #e0e7eb;
}

.wv-table__row--subheader .wv-table__cell,
.wv-table__row--subheader .wv-table__cell--amount,
.wv-table__row--subheader .wv-table__cell--grandtotal,
.wv-table__row--subheader .wv-table__cell--subgroup,
.wv-table__row--subheader .wv-table__cell--actions {
  background-color: #ecf0f3;
}

.wv-table__cell--subgroup {
  padding-left: 40px;
}

.wv-table__cell--actions {
  text-align: center;
  width: 130px;
}

.wv-table__action {
  margin: 0 8px;
}

.wv-table__action [class*='wv-icon'],
.wv-table__action .wv-svg-icon {
  color: #344551;
}

.wv-table__action > .wv-svg-icon {
  position: relative;
  bottom: -3px;
}

.wv-table__action:hover {
  text-decoration: none;
}

.wv-table__action:hover [class*='wv-icon'],
.wv-table__action:hover .wv-svg-icon {
  color: #1c252c;
}

.wv-table__action--danger:hover [class*='wv-icon'],
.wv-table__action--danger:hover .wv-svg-icon {
  color: #d74242;
}

.wv-table__action--blank {
  display: inline-block;
  width: 32px;
}

.wv-table__header .wv-table__row .wv-table__cell .wv-table__sort-unsortable,
.wv-table__header
  .wv-table__row
  .wv-table__cell--amount
  .wv-table__sort-unsortable,
.wv-table__header
  .wv-table__row
  .wv-table__cell--grandtotal
  .wv-table__sort-unsortable,
.wv-table__header
  .wv-table__row
  .wv-table__cell--subgroup
  .wv-table__sort-unsortable,
.wv-table__header
  .wv-table__row
  .wv-table__cell--actions
  .wv-table__sort-unsortable {
  cursor: not-allowed;
}

.wv-table__header .wv-table__row .wv-table__cell .wv-table__sort-trigger,
.wv-table__header
  .wv-table__row
  .wv-table__cell--amount
  .wv-table__sort-trigger,
.wv-table__header
  .wv-table__row
  .wv-table__cell--grandtotal
  .wv-table__sort-trigger,
.wv-table__header
  .wv-table__row
  .wv-table__cell--subgroup
  .wv-table__sort-trigger,
.wv-table__header
  .wv-table__row
  .wv-table__cell--actions
  .wv-table__sort-trigger {
  position: relative;
  cursor: pointer;
}

.wv-table__header
  .wv-table__row
  .wv-table__cell
  .wv-table__sort-trigger::before,
.wv-table__header
  .wv-table__row
  .wv-table__cell--amount
  .wv-table__sort-trigger::before,
.wv-table__header
  .wv-table__row
  .wv-table__cell--grandtotal
  .wv-table__sort-trigger::before,
.wv-table__header
  .wv-table__row
  .wv-table__cell--subgroup
  .wv-table__sort-trigger::before,
.wv-table__header
  .wv-table__row
  .wv-table__cell--actions
  .wv-table__sort-trigger::before {
  content: '';
  position: absolute;
  right: -16px;
  top: calc(50% - 1px);
  width: 0;
  border-style: solid;
  border-width: 5px 4px;
  border-color: transparent;
  border-top-color: inherit;
  border-bottom-width: 0;
  height: 0;
  display: none;
}

.wv-table__header .wv-table__row .wv-table__cell .wv-table__sort-trigger:hover,
.wv-table__header
  .wv-table__row
  .wv-table__cell--amount
  .wv-table__sort-trigger:hover,
.wv-table__header
  .wv-table__row
  .wv-table__cell--grandtotal
  .wv-table__sort-trigger:hover,
.wv-table__header
  .wv-table__row
  .wv-table__cell--subgroup
  .wv-table__sort-trigger:hover,
.wv-table__header
  .wv-table__row
  .wv-table__cell--actions
  .wv-table__sort-trigger:hover {
  color: #136acd;
}

.wv-table__header
  .wv-table__row
  .wv-table__cell
  .wv-table__sort-trigger:hover::before,
.wv-table__header
  .wv-table__row
  .wv-table__cell--amount
  .wv-table__sort-trigger:hover::before,
.wv-table__header
  .wv-table__row
  .wv-table__cell--grandtotal
  .wv-table__sort-trigger:hover::before,
.wv-table__header
  .wv-table__row
  .wv-table__cell--subgroup
  .wv-table__sort-trigger:hover::before,
.wv-table__header
  .wv-table__row
  .wv-table__cell--actions
  .wv-table__sort-trigger:hover::before {
  display: block;
}

.wv-table__header
  .wv-table__row
  .wv-table__cell
  .wv-table__sort-trigger.sort-asc::before,
.wv-table__header
  .wv-table__row
  .wv-table__cell--amount
  .wv-table__sort-trigger.sort-asc::before,
.wv-table__header
  .wv-table__row
  .wv-table__cell--grandtotal
  .wv-table__sort-trigger.sort-asc::before,
.wv-table__header
  .wv-table__row
  .wv-table__cell--subgroup
  .wv-table__sort-trigger.sort-asc::before,
.wv-table__header
  .wv-table__row
  .wv-table__cell--actions
  .wv-table__sort-trigger.sort-asc::before,
.wv-table__header
  .wv-table__row
  .wv-table__cell
  .wv-table__sort-trigger.sort-desc::before,
.wv-table__header
  .wv-table__row
  .wv-table__cell--amount
  .wv-table__sort-trigger.sort-desc::before,
.wv-table__header
  .wv-table__row
  .wv-table__cell--grandtotal
  .wv-table__sort-trigger.sort-desc::before,
.wv-table__header
  .wv-table__row
  .wv-table__cell--subgroup
  .wv-table__sort-trigger.sort-desc::before,
.wv-table__header
  .wv-table__row
  .wv-table__cell--actions
  .wv-table__sort-trigger.sort-desc::before {
  display: block;
}

.wv-table__header
  .wv-table__row
  .wv-table__cell
  .wv-table__sort-trigger.sort-asc::before,
.wv-table__header
  .wv-table__row
  .wv-table__cell--amount
  .wv-table__sort-trigger.sort-asc::before,
.wv-table__header
  .wv-table__row
  .wv-table__cell--grandtotal
  .wv-table__sort-trigger.sort-asc::before,
.wv-table__header
  .wv-table__row
  .wv-table__cell--subgroup
  .wv-table__sort-trigger.sort-asc::before,
.wv-table__header
  .wv-table__row
  .wv-table__cell--actions
  .wv-table__sort-trigger.sort-asc::before {
  border-bottom-color: inherit;
  border-top-width: 0;
  border-bottom-width: 5px;
}

.wv-table__header
  .wv-table__row
  .wv-table__cell
  .wv-table__sort-trigger.sort-desc::before,
.wv-table__header
  .wv-table__row
  .wv-table__cell--amount
  .wv-table__sort-trigger.sort-desc::before,
.wv-table__header
  .wv-table__row
  .wv-table__cell--grandtotal
  .wv-table__sort-trigger.sort-desc::before,
.wv-table__header
  .wv-table__row
  .wv-table__cell--subgroup
  .wv-table__sort-trigger.sort-desc::before,
.wv-table__header
  .wv-table__row
  .wv-table__cell--actions
  .wv-table__sort-trigger.sort-desc::before {
  border-top-color: inherit;
  border-bottom-width: 0;
}

.wv-table--condensed .wv-table__header [class*='wv-table__cell'] {
  padding-left: 8px;
  padding-right: 8px;
}

.wv-table--condensed .wv-table__body [class*='wv-table__cell'],
.wv-table--condensed .wv-table__rowgroup [class*='wv-table__cell'] {
  padding: 8px;
}

.wv-table--condensed .wv-table__body .wv-table__cell--nested,
.wv-table--condensed .wv-table__rowgroup .wv-table__cell--nested {
  padding: 0;
}

.wv-table--condensed .wv-table__footer [class*='wv-table__cell'] {
  padding-left: 8px;
  padding-right: 8px;
}

.wv-table__body--expandable > .wv-table__row {
  cursor: pointer;
}

.wv-table__body--expandable > .wv-table__row--detailed-view {
  display: none;
}

.wv-table__body--expandable.is-expanded {
  box-shadow: 0 8px 32px rgba(77, 101, 117, 0.35);
}

.wv-table__body--expandable.is-expanded > .wv-table__row {
  background: white;
  border-bottom: none;
}

.wv-table__body--expandable.is-expanded > .wv-table__row--detailed-view {
  cursor: default;
  display: table-row;
  background: white;
}

.wv-table__body--expandable.is-expanded
  > .wv-table__row--detailed-view
  > .wv-table__cell,
.wv-table__body--expandable.is-expanded
  > .wv-table__row--detailed-view
  > .wv-table__cell--amount,
.wv-table__body--expandable.is-expanded
  > .wv-table__row--detailed-view
  > .wv-table__cell--grandtotal,
.wv-table__body--expandable.is-expanded
  > .wv-table__row--detailed-view
  > .wv-table__cell--subgroup,
.wv-table__body--expandable.is-expanded
  > .wv-table__row--detailed-view
  > .wv-table__cell--actions {
  padding-top: 0;
}

@-webkit-keyframes skeletonShimmer {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

@keyframes skeletonShimmer {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

.wv-table--skeleton {
  overflow: hidden;
}

.wv-table--skeleton .wv-table--skeleton__stub {
  display: inline-block;
  position: relative;
  color: transparent;
  background-color: #e3e9ed;
  height: 16px;
  border-radius: 20px;
  width: 100%;
  min-width: 50px;
  max-width: 200px;
  -webkit-animation-name: skeletonShimmer;
  animation-name: skeletonShimmer;
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
  -webkit-animation-duration: 1.4s;
  animation-duration: 1.4s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: normal;
  animation-direction: normal;
}

.wv-table--skeleton .wv-table__header .wv-table--skeleton__stub {
  width: 55px;
}

.wv-table--skeleton .wv-table__row:hover {
  background-color: transparent;
}

.wv-object-list {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
}

.wv-object-list__item:last-child {
  border-bottom: 1px solid #d4dde3;
}

.wv-object-list__item__summary {
  border-top: 1px solid #d4dde3;
  padding: 16px 8px;
  display: -ms-grid;
  display: grid;
}

.wv-object-list__item--with-actions .wv-object-list__item__summary,
.wv-object-list__item--expandable .wv-object-list__item__summary {
  padding-top: 8px;
}

.wv-object-list__item--clickable .wv-object-list__item__summary:focus,
.wv-object-list__item--clickable .wv-object-list__item__summary:hover,
.wv-object-list__item--expandable .wv-object-list__item__summary:focus,
.wv-object-list__item--expandable .wv-object-list__item__summary:hover {
  outline: 0;
  cursor: pointer;
  background: #e6f1ff;
}

.wv-object-list__item__details {
  padding: 16px 8px;
}

.wv-object-list__item__actions {
  grid-row: 1;
  grid-row-start: 1;
  grid-column-end: -1;
  margin-right: -8px;
  justify-self: end;
}

.wv-object-list__item__actions .wv-button--icon {
  width: 40px;
  height: 40px;
}

.wv-object-list__item--expandable.is-expanded {
  box-shadow: 0 8px 32px rgba(77, 101, 117, 0.35);
  border-radius: 8px;
  position: relative;
}

.wv-object-list__item--expandable.is-expanded .wv-object-list__item__summary {
  border-top: 1px solid transparent;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.wv-object-list__item--expandable.is-expanded
  .wv-object-list__item__summary:focus,
.wv-object-list__item--expandable.is-expanded
  .wv-object-list__item__summary:hover {
  border-top: 1px solid #e6f1ff;
}

.wv-object-list__item--expandable.is-expanded:last-child {
  border-bottom: 1px solid transparent;
}

.wv-object-list__item--expandable.is-expanded
  + .wv-object-list__item
  .wv-object-list__item__summary {
  border-top: 1px solid transparent;
}

.wv-object-list__item--expandable:not(.is-expanded)
  .wv-object-list__item__details {
  display: none;
}

.wv-tooltip,
.wv-tooltip--top,
.wv-tooltip--left,
.wv-tooltip--bottom,
.wv-tooltip--right {
  display: block;
  position: absolute;
  min-width: 40px;
  min-height: 16px;
  padding: 8px 12px;
  margin: 0;
  z-index: 10;
  font-weight: 600;
  letter-spacing: normal;
  white-space: pre;
  word-wrap: break-word;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  text-shadow: none;
  border-radius: 3px;
  color: #fff;
  background-color: #373d3f;
  visibility: hidden;
  opacity: 0;
  transition: all 0.15s ease-out;
  pointer-events: none;
}

.wv-tooltip::after,
.wv-tooltip--top::after,
.wv-tooltip--left::after,
.wv-tooltip--bottom::after,
.wv-tooltip--right::after {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border: 8px solid transparent;
}

.wv-tooltip,
.wv-tooltip--top {
  bottom: 100%;
  left: 50%;
  margin-bottom: 18px;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.wv-tooltip::after,
.wv-tooltip--top::after {
  top: 99%;
  left: 50%;
  border-top-color: #373d3f;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.wv-tooltip--right {
  top: 50%;
  left: 100%;
  margin-left: 18px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.wv-tooltip--right::after {
  top: 50%;
  left: 0;
  border-right-color: #373d3f;
  -webkit-transform: translate(-100%, -50%);
  transform: translate(-100%, -50%);
}

.wv-tooltip--bottom {
  top: 99%;
  left: 50%;
  margin-top: 18px;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.wv-tooltip--bottom::after {
  bottom: 100%;
  left: 50%;
  border-bottom-color: #373d3f;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.wv-tooltip--left {
  top: 50%;
  right: 100%;
  margin-right: 18px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.wv-tooltip--left::after {
  top: 50%;
  left: 100%;
  border-left-color: #373d3f;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.wv-tooltip__target {
  position: relative;
  overflow: visible;
}

.wv-tooltip__target:hover .wv-tooltip,
.wv-tooltip__target:hover .wv-tooltip--top,
.wv-tooltip__target:hover .wv-tooltip--left,
.wv-tooltip__target:hover .wv-tooltip--bottom,
.wv-tooltip__target:hover .wv-tooltip--right {
  visibility: visible;
  opacity: 1;
}

.wv-tooltip__target:hover .wv-tooltip,
.wv-tooltip__target:hover .wv-tooltip--top {
  margin-bottom: 13px;
}

.wv-tooltip__target:hover .wv-tooltip--right {
  margin-left: 13px;
}

.wv-tooltip__target:hover .wv-tooltip--bottom {
  margin-top: 13px;
}

.wv-tooltip__target:hover .wv-tooltip--left {
  margin-right: 13px;
}

.wv-tooltip__target:hover .wv-input ~ [class*='wv-tooltip'] {
  visibility: hidden;
  opacity: 0;
}

.wv-tooltip__target:hover .wv-input ~ .wv-tooltip--top {
  margin-bottom: 18px;
}

.wv-tooltip__target:hover .wv-input ~ .wv-tooltip--right {
  margin-left: 18px;
}

.wv-tooltip__target:hover .wv-input ~ .wv-tooltip--bottom {
  margin-top: 18px;
}

.wv-tooltip__target:hover .wv-input ~ .wv-tooltip--left {
  margin-right: 18px;
}

.wv-tooltip__target input:focus.wv-input ~ [class*='wv-tooltip'] {
  visibility: visible;
  opacity: 1;
}

.wv-tooltip__target > .wv-svg-icon {
  position: relative;
  bottom: -5px;
}

.wv-tooltip__target--inline {
  border-bottom: 2px dotted;
  border-color: inherit;
}

.wv-tooltip__target--inline:hover {
  cursor: help;
}

.wv-input ~ .wv-tooltip--left,
.wv-input ~ .wv-tooltip--right {
  top: 60%;
}

.tippy-tooltip.wv-tooltip-theme {
  font-weight: 600;
}

.wv-dropdown__toggle .wv-icon--open-menu,
.wv-dropdown__toggle .wv-select__input__icon:empty {
  margin-right: -grid-rows(1);
}

.wv-dropdown__toggle.wv-button .wv-svg-icon {
  position: relative;
  bottom: -4px;
  margin-right: -5px;
}

.wv-dropdown__toggle.wv-button--small {
  min-width: 32px;
  padding: 3px 0;
  margin: 0;
}

.wv-table__action .wv-dropdown__toggle.wv-button--small {
  min-width: 24px;
  padding: 0;
  margin-top: -grid-rows(1);
}

.wv-dropdown__toggle.wv-button--small .wv-icon--open-menu,
.wv-dropdown__toggle.wv-button--small .wv-select__input__icon:empty {
  margin: 0;
}

.wv-dropdown__toggle.wv-button--small .wv-svg-icon {
  position: relative;
  bottom: -3px;
  margin-right: 0;
}

.wv-dropdown {
  position: relative;
  display: inline-block;
}

.wv-dropdown__menu,
.wv-dropdown__menu--right {
  list-style: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-align: left;
  display: block;
  visibility: hidden;
  position: absolute;
  top: 110%;
  z-index: 1000;
  min-width: 100%;
  padding: 8px 0;
  border-radius: 4px;
  color: #1c252c;
  background-color: white;
  box-shadow: 0 0 0 1px rgba(77, 101, 117, 0.1),
    0 3px 10px 0 rgba(77, 101, 117, 0.2);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  height: 0;
  -webkit-transform: translateY(4px);
  transform: translateY(4px);
  overflow: hidden;
}

.wv-dropdown__menu > li,
.wv-dropdown__menu--right > li {
  display: block;
}

.is-open > .wv-dropdown__menu,
.is-open > .wv-dropdown__menu--right {
  visibility: visible;
  height: auto;
  -webkit-animation: panel-slide-down 0.1s ease-in-out forwards;
  animation: panel-slide-down 0.1s ease-in-out forwards;
}

.wv-dropdown__menu--right {
  right: 0;
}

.wv-dropdown.open-up [class*='wv-dropdown__menu'] {
  top: auto;
  bottom: 110%;
  -webkit-transform: translateY(-4px);
  transform: translateY(-4px);
}

.wv-dropdown.wv-dropdown--fluid {
  width: 100%;
}

.wv-dropdown.wv-dropdown--fluid .wv-dropdown__toggle[class*='wv-button'] {
  width: 100%;
}

.wv-dropdown.wv-dropdown--fluid .wv-svg-icon {
  float: right;
}

.wv-dropdown__menu__item {
  position: relative;
  vertical-align: middle;
}

.wv-dropdown__menu__item.is-group-option .wv-dropdown__menu__link {
  padding-left: 24px;
}

.wv-dropdown__menu__link {
  display: block;
  padding: 4px 16px;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  outline: none;
  color: inherit;
  background-color: transparent;
  background-color: transparent;
  background-color: transparent;
}

.is-active > .wv-dropdown__menu__link {
  cursor: default;
}

.is-disabled > .wv-dropdown__menu__link {
  opacity: 0.3;
  cursor: not-allowed;
  background-color: transparent;
}

.wv-dropdown__menu__link [class*='wv-icon'] {
  opacity: 0.5;
  color: inherit;
}

:hover:not(.is-disabled) > .wv-dropdown__menu__link,
:focus:not(.is-disabled) > .wv-dropdown__menu__link {
  background-color: #f0f4fa;
}

:active:not(.is-disabled) > .wv-dropdown__menu__link,
.is-active:not(.is-disabled) > .wv-dropdown__menu__link,
.is-open:not(.is-disabled) > .wv-dropdown__menu__link {
  color: white;
  background-color: #136acd;
}

.wv-dropdown__menu__item.is-disabled .wv-dropdown__menu__link {
  pointer-events: none;
}

.wv-dropdown__menu__header {
  position: relative;
  display: block;
  padding: 4px 16px;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  cursor: default;
}

.wv-dropdown__menu__header.is-group-option .wv-dropdown__menu__link {
  padding-left: 24px;
}

.wv-dropdown__menu__item + .wv-dropdown__menu__header {
  margin-top: 24px;
}

.wv-dropdown__menu__divider {
  display: block;
  height: 0;
  padding: 0;
  border: none;
  border-bottom: 1px solid #d4dde3;
  overflow: hidden;
  margin: 8px 0;
}

.wv-dropdown__menu__head {
  display: block;
  padding: 4px 16px;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  margin-bottom: 8px;
  padding-right: 40px;
  border-radius: 4px 4px 0 0;
  cursor: default;
}

.wv-dropdown__menu__head .wv-icon--cancel {
  position: absolute;
  color: #dcdfe0;
  right: 16px;
  cursor: pointer;
  white-space: normal;
}

.wv-dropdown__menu__head .wv-icon--cancel:hover {
  color: #6f7d80;
}

.wv-dropdown__menu__body {
  list-style: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-align: left;
  max-height: 460px;
  overflow: auto;
}

[class~='wv-dropdown'] + [class~='wv-button'],
[class~='wv-dropdown'] + [class~='wv-dropdown'],
[class~='wv-button'] + [class~='wv-dropdown'] {
  margin-left: 10px;
}

@-webkit-keyframes modalShow {
  0% {
    visibility: hidden;
    opacity: 0;
    -webkit-transform: scale(0.8, 0.8);
    transform: scale(0.8, 0.8);
  }

  100% {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@keyframes modalShow {
  0% {
    visibility: hidden;
    opacity: 0;
    -webkit-transform: scale(0.8, 0.8);
    transform: scale(0.8, 0.8);
  }

  100% {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@-webkit-keyframes modalHide {
  0% {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  100% {
    visibility: hidden;
    opacity: 0;
    -webkit-transform: scale(0.8, 0.8);
    transform: scale(0.8, 0.8);
  }
}

@keyframes modalHide {
  0% {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  100% {
    visibility: hidden;
    opacity: 0;
    -webkit-transform: scale(0.8, 0.8);
    transform: scale(0.8, 0.8);
  }
}

@-webkit-keyframes backdropShow {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  100% {
    visibility: visible;
    opacity: 0.5;
  }
}

@keyframes backdropShow {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  100% {
    visibility: visible;
    opacity: 0.5;
  }
}

@-webkit-keyframes backdropHide {
  0% {
    visibility: visible;
    opacity: 0.5;
  }

  100% {
    visibility: hidden;
    opacity: 0;
  }
}

@keyframes backdropHide {
  0% {
    visibility: visible;
    opacity: 0.5;
  }

  100% {
    visibility: hidden;
    opacity: 0;
  }
}

.wv-modal__header__title {
  margin: 0;
  padding: 20px 48px 20px 24px;
}

.wv-modal__backdrop {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5000;
  background-color: #103265;
}

.wv-modal__footer,
.wv-modal__footer--centered {
  border-top: 1px solid #d4dde3;
  padding: 12px 24px;
  background-color: #fff;
  text-align: right;
}

.wv-modal,
.wv-modal--danger {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition: all 0.3s;
  z-index: 5000;
}

.wv-modal--open {
  opacity: 1;
  visibility: visible;
}

.wv-modal--open .wv-modal__window {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-name: modalShow;
  animation-name: modalShow;
  -webkit-animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wv-modal--open .wv-modal__backdrop {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-name: backdropShow;
  animation-name: backdropShow;
}

.wv-modal--close .wv-modal__window {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-name: modalHide;
  animation-name: modalHide;
}

.wv-modal--close .wv-modal__backdrop {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-name: backdropHide;
  animation-name: backdropHide;
}

.wv-modal__window {
  position: relative;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  z-index: 5001;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  padding: 0 12px;
}

.wv-modal__content {
  margin: 36px auto;
  box-shadow: 0 10px 20px -5px #4d6575;
  min-width: 320px;
  max-width: 640px;
  position: relative;
}

@media (min-width: 481px) {
  .wv-modal__content {
    border-radius: 8px;
  }
}

.wv-modal__header,
.wv-modal__footer {
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}

.wv-modal__header {
  position: relative;
  border-bottom: 1px solid #d4dde3;
  background: #fff;
}

@media (min-width: 481px) {
  .wv-modal__header {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
}

.wv-modal__header .wv-nav--secondary {
  margin-bottom: -1px;
  margin-left: 16px;
}

.wv-modal--danger .wv-modal__header {
  border-bottom: 0;
  color: white;
  background-color: #d74242;
}

.wv-modal--danger .wv-modal__header__title {
  padding: 12px 48px 12px 24px;
}

.wv-modal__body {
  background: #fff;
  padding: 12px 24px;
}

.wv-modal__body--centered-content {
  text-align: center;
}

@media (min-width: 481px) {
  .wv-modal .wv-modal__body {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  .wv-modal__header + .wv-modal__body,
  .wv-modal__header + .wv-modal__content__scroll-area > .wv-modal__body {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  .wv-modal .wv-modal__body:last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}

@media (min-width: 481px) {
  .wv-modal__footer,
  .wv-modal__footer--centered {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}

.wv-modal__footer--centered {
  text-align: center;
}

.wv-modal .wv-close--large {
  right: 16px;
  top: 24px;
}

.wv-modal--danger .wv-close--medium {
  right: 16px;
  top: 12px;
}

.wv-modal--danger .wv-close--medium::before,
.wv-modal--danger .wv-close--medium::after {
  background-color: white;
  opacity: 0.7;
}

.wv-modal--danger .wv-close--medium:hover::before,
.wv-modal--danger .wv-close--medium:hover::after {
  opacity: 1;
}

.wv-modal--plain > .wv-modal__window > .wv-modal__content > .wv-close--large {
  top: 16px;
  right: 16px;
}

.wv-modal--plain > .wv-modal__window > .wv-modal__content > .wv-modal__body,
.wv-modal--plain
  > .wv-modal__window
  > .wv-modal__content
  > .wv-modal__content__scroll-area
  > .wv-modal__body {
  background-color: #fff;
  padding: 32px 40px;
}

.wv-modal--plain
  > .wv-modal__window
  > .wv-modal__content
  > .wv-modal__body
  .wv-close--large,
.wv-modal--plain
  > .wv-modal__window
  > .wv-modal__content
  > .wv-modal__content__scroll-area
  > .wv-modal__body
  .wv-close--large {
  top: 16px;
  right: 16px;
}

@media (max-width: 480px) {
  .wv-modal__window {
    padding: 0;
  }

  .wv-modal__content {
    margin: 0;
    height: 100%;
    width: 100%;
    position: fixed;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  .wv-modal__content__scroll-area {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-flex: 1;
    flex: 1;
    overflow: auto;
  }

  .wv-modal__body {
    -webkit-flex: 1;
    flex: 1;
  }
}

.wv-fullscreen-modal {
  z-index: 5000;
  background: #fff;
  box-shadow: 0 10px 20px 5px rgba(77, 101, 117, 0.5);
  opacity: 0;
  visibility: hidden;
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  bottom: -100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  transition: all 0.4s;
}

.wv-fullscreen-modal.is-open {
  opacity: 1;
  visibility: visible;
  bottom: 0;
  transition: bottom 0.5s;
}

.wv-fullscreen-modal.is-open:focus {
  outline: none;
  box-shadow: 0 10px 20px 5px rgba(77, 101, 117, 0.5), 0 0 0 3px #ceeef8;
}

.wv-fullscreen-modal__header {
  padding: 16px;
  border-bottom: 1px solid #d4dde3;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}

@media (min-width: 769px) {
  .wv-fullscreen-modal__header {
    padding: 24px;
  }
}

.wv-fullscreen-modal__header__content-wrapper {
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
}

@media (min-width: 769px) {
  .wv-fullscreen-modal__header__title {
    font-size: 33px;
    line-height: 1.4;
  }
}

.wv-fullscreen-modal__header__title .wv-svg-icon {
  position: relative;
  bottom: -2px;
}

.wv-fullscreen-modal__close {
  border: none;
  background: transparent;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  border-radius: 100px;
  padding: 8px;
  color: #8aa2b2;
  margin: -8px 0 -8px 16px;
}

.wv-fullscreen-modal__close:hover,
.wv-fullscreen-modal__close:focus {
  background: #e0f4fb;
  color: #136acd;
}

.wv-fullscreen-modal__close:focus,
.wv-fullscreen-modal__close:active {
  box-shadow: 0 0 0 3px #ceeef8;
  outline: 0;
}

.wv-fullscreen-modal__scroll-area {
  -webkit-flex: 1;
  flex: 1;
  overflow: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.wv-fullscreen-modal__body {
  padding: 16px;
  -webkit-flex: 1;
  flex: 1;
}

@media (min-width: 769px) {
  .wv-fullscreen-modal__body {
    padding: 24px;
  }
}

.wv-fullscreen-modal__body__content-wrapper {
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.wv-fullscreen-modal__body .wv-nav--secondary .wv-nav__section {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: white;
  padding-top: 16px;
}

@media (min-width: 769px) {
  .wv-fullscreen-modal__body .wv-nav--secondary .wv-nav__section {
    padding-top: 24px;
  }
}

.wv-fullscreen-modal__body__content-wrapper
  > .wv-nav--secondary:first-child
  .wv-nav__section {
  margin-top: -16px;
}

@media (min-width: 769px) {
  .wv-fullscreen-modal__body__content-wrapper
    > .wv-nav--secondary:first-child
    .wv-nav__section {
    margin-top: -24px;
  }
}

@media (min-width: 481px) and (min-height: 1000px) {
  .wv-fullscreen-modal__body {
    overflow: auto;
  }

  .wv-fullscreen-modal__body .wv-nav--secondary .wv-nav__section {
    top: -16px;
  }
}

@media (min-width: 481px) and (min-height: 1000px) and (min-width: 769px) {
  .wv-fullscreen-modal__body .wv-nav--secondary .wv-nav__section {
    top: -24px;
  }
}

.wv-fullscreen-modal__footer {
  padding: 16px;
  border-top: 1px solid #d4dde3;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}

@media (min-width: 769px) {
  .wv-fullscreen-modal__footer {
    padding: 24px;
  }
}

.wv-fullscreen-modal__footer__content-wrapper {
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  text-align: right;
}

.wv-takeover {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  text-align: center;
  background: #fff;
  z-index: 20000;
  overflow-y: auto;
  transition: all 0.4s;
}

.wv-takeover.is-open {
  opacity: 1;
  visibility: visible;
}

.wv-takeover__content {
  margin: auto;
  max-width: 700px;
  padding: 16px;
}

.wv-takeover__actions {
  padding-top: 24px;
  padding-bottom: 24px;
}

.wv-notify--warning,
.wv-notify--danger,
.wv-notify--info,
.wv-notify--success {
  display: -webkit-flex;
  display: flex;
  width: calc(100% - (2 * 2px));
  border-radius: 4px;
  border: 2px solid transparent;
  margin-bottom: 16px;
}

.wv-notify--warning .wv-button--secondary,
.wv-notify--danger .wv-button--secondary,
.wv-notify--info .wv-button--secondary,
.wv-notify--success .wv-button--secondary,
.wv-notify--warning .wv-button--link,
.wv-notify--danger .wv-button--link,
.wv-notify--info .wv-button--link,
.wv-notify--success .wv-button--link {
  color: inherit;
  border-color: inherit;
}

.wv-notify--warning .wv-button--secondary:hover,
.wv-notify--danger .wv-button--secondary:hover,
.wv-notify--info .wv-button--secondary:hover,
.wv-notify--success .wv-button--secondary:hover,
.wv-notify--warning .wv-button--link:hover,
.wv-notify--danger .wv-button--link:hover,
.wv-notify--info .wv-button--link:hover,
.wv-notify--success .wv-button--link:hover {
  color: inherit;
  border-color: inherit;
}

.wv-notify--warning .wv-button--secondary,
.wv-notify--danger .wv-button--secondary,
.wv-notify--info .wv-button--secondary,
.wv-notify--success .wv-button--secondary {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0)
  );
  opacity: 0.8;
  margin: 0 16px 16px 0;
}

.wv-notify--warning .wv-button--secondary:hover,
.wv-notify--danger .wv-button--secondary:hover,
.wv-notify--info .wv-button--secondary:hover,
.wv-notify--success .wv-button--secondary:hover {
  opacity: 1;
}

.wv-notify--warning .wv-button--link,
.wv-notify--danger .wv-button--link,
.wv-notify--info .wv-button--link,
.wv-notify--success .wv-button--link {
  margin: 0 0 16px 0;
}

.wv-notify--warning .wv-close,
.wv-notify--danger .wv-close,
.wv-notify--info .wv-close,
.wv-notify--success .wv-close {
  top: 8px;
  right: 8px;
}

.wv-notify__icon-holder {
  -webkit-flex: 0 0 42px;
  flex: 0 0 42px;
  padding-top: 16px;
  text-align: right;
}

.wv-notify__icon-holder .wv-icon {
  font-size: 24px;
}

.wv-notify__content-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
  padding: 16px 0 0 16px;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  min-width: 0;
}

.wv-notify__content {
  -webkit-flex: 1 1 250px;
  flex: 1 1 250px;
  margin: 0 16px 16px 0;
  min-width: 0;
  text-align: left;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.wv-notify__title {
  font-weight: bold;
  margin-bottom: 16px;
}

.wv-notify__actions {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-self: flex-start;
  align-self: flex-start;
}

.wv-notify__actions .wv-button--link {
  margin-right: 16px;
  -webkit-align-self: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.wv-notify__close {
  -webkit-flex: 0 0 32px;
  flex: 0 0 32px;
  position: relative;
}

.wv-notify__close .wv-close.wv-tooltip__target {
  position: absolute;
}

.wv-notify__message > :first-child {
  margin-top: 0;
}

.wv-notify__message > :last-child {
  margin-bottom: 0;
}

.wv-notify__message [class*='wv-text'],
.wv-notify__message [class*='wv-list'] {
  color: inherit;
}

.wv-notify__message .wv-text--link,
.wv-notify__message .wv-text--link-external {
  text-decoration: underline;
}

.wv-notify__message .wv-text--link:hover,
.wv-notify__message .wv-text--link-external:hover {
  text-decoration: none;
}

.wv-notify--warning .wv-notify__icon-holder .wv-svg-icon,
.wv-notify--danger .wv-notify__icon-holder .wv-svg-icon,
.wv-notify--info .wv-notify__icon-holder .wv-svg-icon,
.wv-notify--success .wv-notify__icon-holder .wv-svg-icon {
  width: 25px;
  height: 25px;
}

.wv-notify--warning {
  background: #fcfbe3;
  border-color: #f6f2ac;
  color: #7c760e;
}

.wv-notify--warning .wv-notify__icon-holder .wv-icon {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.wv-notify--warning .wv-divider {
  border-color: rgba(124, 118, 14, 0.3);
}

.wv-notify--success {
  background: #e1faec;
  border-color: #bef4d7;
  color: #157944;
}

.wv-notify--success .wv-notify__icon-holder .wv-icon {
  font-size: 24px;
}

.wv-notify--success .wv-divider {
  border-color: rgba(21, 121, 68, 0.3);
}

.wv-notify--info {
  background: #e0f4fb;
  border-color: #bce7f6;
  color: #147494;
}

.wv-notify--info .wv-divider {
  border-color: rgba(20, 116, 148, 0.3);
}

.wv-notify--danger {
  background: #fbeaea;
  border-color: #f5d1d1;
  color: #c22929;
}

.wv-notify--danger .wv-notify__icon-holder .wv-icon {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.wv-notify--danger .wv-divider {
  border-color: rgba(194, 41, 41, 0.3);
}

.wv-notify--small .wv-notify__icon-holder {
  -webkit-flex: 0 0 40px;
  flex: 0 0 40px;
  padding-top: 9px;
  margin-right: 8px;
}

.wv-notify--small .wv-notify__icon-holder .wv-icon {
  font-size: 18px;
  line-height: 1.4;
}

@media (min-width: 480px) {
  .wv-notify--small .wv-notify__icon-holder .wv-icon {
    font-size: 19px;
  }
}

.wv-notify--small .wv-notify__icon-holder .wv-svg-icon {
  width: 20px;
  height: 20px;
}

.wv-notify--small .wv-notify__content-wrapper {
  padding: 8px 0 8px 8px;
}

.wv-notify--small .wv-notify__content-wrapper .wv-notify__content {
  margin: 0 16px 0 0;
}

.wv-notify--small [class*='wv-button'] {
  margin: 0 16px 0 0;
}

.wv-workflow__task__number,
.wv-wizard__task__number {
  box-sizing: border-box;
  border: 2px solid #dcdfe0;
  color: #dcdfe0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  float: left;
  font-size: 25px;
  font-weight: bold;
  line-height: 44px;
  margin-right: 16px;
  text-align: center;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

.wv-workflow__task__number:empty::before,
.wv-wizard__task__number:empty::before {
  content: counter(workflow-task-number);
}

.is-current .wv-workflow__task__number,
.is-current .wv-wizard__task__number {
  background: #fff;
  border-color: #78c3fc;
  color: #78c3fc;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  margin-right: 16px;
  margin-top: -1px;
}

.is-complete .wv-workflow__task__number,
.is-complete .wv-wizard__task__number {
  border-color: #23c770;
  background: #23c770;
  color: #fff;
}

.wv-workflow {
  counter-reset: workflow-task-number;
}

.wv-workflow__task {
  display: block;
  padding: 12px;
  margin-bottom: 12px;
  border-radius: 8px;
  border: 1px solid #b2c2cd;
  background-color: #fff;
  position: relative;
  counter-increment: workflow-task-number;
}

.wv-workflow .wv-workflow__task {
  margin-bottom: 24px;
}

.wv-workflow__task.is-complete {
  background: #ecf0f3;
  box-shadow: none;
  border: none;
  opacity: 1;
}

.wv-workflow__task.is-complete::after {
  top: 100%;
}

.wv-workflow__task.is-current {
  box-shadow: 0 8px 32px rgba(77, 101, 117, 0.35);
  border-radius: 12px;
  border-color: transparent;
  margin-left: -8px;
  margin-right: -8px;
  padding-left: 20px;
  padding-right: 20px;
  opacity: 1;
}

.wv-workflow__task.is-current::after {
  left: 43px;
}

.wv-workflow__task::after {
  content: '';
  position: absolute;
  left: 35px;
  border: 2px solid #b2c2cd;
  height: 20px;
  top: calc(100% + 1px);
  z-index: auto;
}

.wv-workflow__task:last-child::after {
  border: none;
}

.wv-workflow__task__content {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  min-height: 48px;
}

.wv-workflow__task__title {
  font-weight: normal;
}

.wv-workflow__task.is-current .wv-workflow__task__title {
  font-weight: 600;
}

.wv-workflow__task__actions {
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  -webkit-align-self: flex-start;
  align-self: flex-start;
  padding-left: 40px;
  margin-top: 4px;
}

.wv-workflow__task__action {
  display: none;
}

.wv-workflow__task__number .wv-svg-icon {
  width: 26px;
  height: 26px;
  position: relative;
  bottom: -4px;
}

.wv-popover--top,
.wv-popover,
.wv-popover--left,
.wv-popover--bottom,
.wv-popover--right {
  position: relative;
  display: inline-block;
}

.wv-popover__panel {
  display: block;
  visibility: hidden;
  position: absolute;
  width: 250px;
  padding: 24px;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid rgba(77, 101, 117, 0.07);
  box-shadow: 0 4px 36px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.is-open > .wv-popover__panel {
  visibility: visible;
}

.wv-popover--top .wv-popover__panel,
.wv-popover .wv-popover__panel {
  top: -20px;
  left: 50%;
  -webkit-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
}

.wv-popover--left .wv-popover__panel {
  top: 50%;
  left: -20px;
  -webkit-transform: translate(-100%, -50%);
  transform: translate(-100%, -50%);
}

.wv-popover--bottom .wv-popover__panel {
  top: 100%;
  left: 50%;
  -webkit-transform: translate(-50%, 20px);
  transform: translate(-50%, 20px);
}

.wv-popover--right .wv-popover__panel {
  top: 50%;
  left: 100%;
  -webkit-transform: translate(20px, -50%);
  transform: translate(20px, -50%);
}

.wv-popover--top.is-open > .wv-popover__panel,
.is-open.wv-popover > .wv-popover__panel {
  -webkit-animation: panel-slide-down 0.1s ease-in-out forwards;
  animation: panel-slide-down 0.1s ease-in-out forwards;
}

.wv-popover--left.is-open > .wv-popover__panel {
  -webkit-animation: panel-slide-right 0.1s ease-in-out forwards;
  animation: panel-slide-right 0.1s ease-in-out forwards;
}

.wv-popover--bottom.is-open > .wv-popover__panel {
  -webkit-animation: panel-slide-up 0.1s ease-in-out forwards;
  animation: panel-slide-up 0.1s ease-in-out forwards;
}

.wv-popover--right.is-open > .wv-popover__panel {
  -webkit-animation: panel-slide-left 0.1s ease-in-out forwards;
  animation: panel-slide-left 0.1s ease-in-out forwards;
}

.wv-popover__panel .wv-close {
  top: 8px;
  right: 8px;
}

.wv-popover__caret {
  position: absolute;
  width: 40px;
  height: 20px;
  overflow: hidden;
}

.wv-popover__caret::before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 20px;
  height: 20px;
  -webkit-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
  border: 1px solid rgba(77, 101, 117, 0.07);
  background: #fff;
}

.wv-popover--top .wv-popover__caret,
.wv-popover .wv-popover__caret {
  top: 100%;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.wv-popover--left .wv-popover__caret {
  top: 50%;
  left: 100%;
  -webkit-transform: translate(-25%, -50%) rotate(270deg);
  transform: translate(-25%, -50%) rotate(270deg);
}

.wv-popover--bottom .wv-popover__caret {
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -100%) rotate(180deg);
  transform: translate(-50%, -100%) rotate(180deg);
}

.wv-popover--right .wv-popover__caret {
  top: 50%;
  left: 0;
  -webkit-transform: translate(-75%, -50%) rotate(90deg);
  transform: translate(-75%, -50%) rotate(90deg);
}

.wv-popover__content > :first-child {
  margin-top: 0;
}

.wv-popover__content > :last-child {
  margin-bottom: 0;
}

.wv-popover__actions {
  text-align: center;
  margin-top: 16px;
}

.wv-popover__actions.wv-button-wrapper {
  margin-top: 16px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
}

.tippy-tooltip.wv-popover-theme {
  font-size: inherit;
  color: #1c252c;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid rgba(77, 101, 117, 0.07);
  box-shadow: 0 4px 36px 4px rgba(0, 0, 0, 0.1);
  padding: 0;
  text-align: left;
}

.tippy-tooltip.wv-popover-theme .tippy-arrow {
  -webkit-transform: scale(1.75);
  transform: scale(1.75);
}

.tippy-tooltip.wv-popover-theme[x-placement^='top'] .tippy-arrow {
  border-top-color: #fff;
  margin: 0 8px;
}

.tippy-tooltip.wv-popover-theme[x-placement^='bottom'] .tippy-arrow {
  border-bottom-color: #fff;
  margin: 0 8px;
}

.tippy-tooltip.wv-popover-theme[x-placement^='left'] .tippy-arrow {
  border-left-color: #fff;
  margin: 8px 0;
}

.tippy-tooltip.wv-popover-theme[x-placement^='right'] .tippy-arrow {
  border-right-color: #fff;
  margin: 8px 0;
}

.tippy-tooltip.wv-popover-theme[x-out-of-boundaries] {
  display: none;
}

.tippy-tooltip.wv-popover-theme .wv-popover__content-wrapper {
  padding: 24px;
}

.tippy-tooltip.wv-popover-theme .wv-close {
  top: 8px;
  right: 8px;
}

.wv-popover__trigger {
  position: relative;
  display: inline-block;
}

.wv-pagination {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

@media (max-width: 480px) {
  .wv-pagination {
    -webkit-flex-direction: column;
    flex-direction: column;
  }
}

.wv-pagination .wv-select {
  min-width: 0;
  width: 70px;
  margin: 0 4px;
}

.wv-pagination .wv-select__input {
  padding: 3px 10px;
  min-height: 0;
}

.wv-pagination .wv-select__input__icon {
  top: 4px;
}

.wv-pagination__results,
.wv-pagination__pages {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

.wv-pagination__pages:first-child {
  margin-left: auto;
}

@media (max-width: 480px) {
  .wv-pagination__pages:first-child {
    margin-left: 0;
  }
}

@media (max-width: 480px) {
  .wv-pagination__pages {
    -webkit-order: -1;
    order: -1;
  }
}

.wv-pagination__control {
  min-width: 0;
  padding: 0;
  width: 32px;
  height: 32px;
  line-height: 30px;
}

.wv-pagination__control:hover {
  z-index: 1;
}

.wv-pagination__control.prev {
  margin-left: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.wv-pagination__control.prev [class*='wv-icon'],
.wv-pagination__control.prev .wv-svg-icon {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.wv-pagination__control.next {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.wv-pagination__control.next [class*='wv-icon'],
.wv-pagination__control.next .wv-svg-icon {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.wv-workflow__task__number,
.wv-wizard__task__number {
  box-sizing: border-box;
  border: 2px solid #dcdfe0;
  color: #dcdfe0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  float: left;
  font-size: 25px;
  font-weight: bold;
  line-height: 44px;
  margin-right: 16px;
  text-align: center;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

.wv-workflow__task__number:empty::before,
.wv-wizard__task__number:empty::before {
  content: counter(workflow-task-number);
}

.is-current .wv-workflow__task__number,
.is-current .wv-wizard__task__number {
  background: #fff;
  border-color: #78c3fc;
  color: #78c3fc;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  margin-right: 16px;
  margin-top: -1px;
}

.is-complete .wv-workflow__task__number,
.is-complete .wv-wizard__task__number {
  border-color: #23c770;
  background: #23c770;
  color: #fff;
}

.wv-wizard {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  margin-top: 24px;
  position: relative;
}

.wv-wizard__tasklist {
  counter-reset: wizard-task-number;
  width: 264px;
  margin: 0 24px 0 0;
  padding: 0;
}

@media (max-width: 1024px) {
  .wv-wizard__tasklist {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    padding: 16px 0 16px 8px;
    margin: 0;
    overflow-x: scroll;
    border-bottom: 1px solid #d4dde3;
  }

  .wv-wizard__tasklist::before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    pointer-events: none;
    z-index: 100;
    background: linear-gradient(to left, #fff, rgba(255, 255, 255, 0));
    height: 64px;
    width: 24px;
  }
}

.wv-wizard__task__number {
  width: 40px;
  height: 40px;
  margin-right: 8px;
  background-color: white;
  font-size: 20px;
  line-height: 36px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}

@media (max-width: 1024px) {
  .wv-wizard__task__number {
    width: 32px;
    height: 32px;
  }
}

.wv-wizard__task__number:empty::before {
  content: counter(wizard-task-number);
}

.is-current .wv-wizard__task__number {
  transition: all 200ms ease;
  margin-right: 12px;
  margin-top: 2px;
}

@media (max-width: 1024px) {
  .is-current .wv-wizard__task__number {
    margin: 0 12px 0 2px;
  }

  .is-current .wv-wizard__task__number::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -15px;
    background: #78c3fc;
    width: 100%;
    height: 4px;
    z-index: -1;
  }
}

.is-complete .wv-wizard__task__number,
.is-error .wv-wizard__task__number,
.is-warning .wv-wizard__task__number {
  transition: all 200ms ease;
}

.is-complete .wv-wizard__task__number::before,
.is-error .wv-wizard__task__number::before,
.is-warning .wv-wizard__task__number::before {
  color: #fff;
}

.is-complete .wv-wizard__task__number:empty::before {
  font-size: 18px;
  line-height: 38px;
}

.is-error .wv-wizard__task__number::before,
.is-warning .wv-wizard__task__number::before {
  content: '!';
  font-size: 26px;
  font-weight: 600;
  line-height: 36px;
}

.is-error .wv-wizard__task__number {
  border-color: #d74242;
  background: #d74242;
}

.is-warning .wv-wizard__task__number {
  border-color: #dcd318;
  background: #dcd318;
}

.wv-wizard__task__title {
  color: #4d6575;
  margin: 0;
}

@media (max-width: 1024px) {
  .wv-wizard__task__title {
    white-space: nowrap;
  }
}

.is-current .wv-wizard__task__title {
  color: #1c252c;
}

@media (max-width: 1024px) {
  .is-current .wv-wizard__task__title {
    font-size: 16px;
    line-height: 1.4;
    padding-top: 0;
  }
}

@media (max-width: 1024px) and (min-width: 480px) {
  .is-current .wv-wizard__task__title {
    font-size: 16px;
  }
}

.is-error .wv-wizard__task__title {
  color: #c22929;
}

.is-warning .wv-wizard__task__title {
  color: #7c760e;
}

.wv-wizard__task:nth-child(1) {
  z-index: 20;
}

.wv-wizard__task:nth-child(2) {
  z-index: 19;
}

.wv-wizard__task:nth-child(3) {
  z-index: 18;
}

.wv-wizard__task:nth-child(4) {
  z-index: 17;
}

.wv-wizard__task:nth-child(5) {
  z-index: 16;
}

.wv-wizard__task:nth-child(6) {
  z-index: 15;
}

.wv-wizard__task:nth-child(7) {
  z-index: 14;
}

.wv-wizard__task:nth-child(8) {
  z-index: 13;
}

.wv-wizard__task:nth-child(9) {
  z-index: 12;
}

.wv-wizard__task {
  position: relative;
  counter-increment: wizard-task-number;
  list-style: none;
  margin-bottom: 24px;
  cursor: default;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

@media (max-width: 1024px) {
  .wv-wizard__task {
    margin: 0 32px 0 0;
  }

  .wv-wizard__task:last-child {
    padding: 0 32px 0 0;
  }
}

.wv-wizard__task::before {
  content: '';
  position: absolute;
  left: 18px;
  border: 2px solid #d4dde3;
  height: 18px;
  bottom: 100%;
  z-index: -1;
  border-radius: 3px;
}

@media (max-width: 1024px) {
  .wv-wizard__task::before {
    display: none;
  }
}

.wv-wizard__task:first-child::before {
  border: none;
}

.wv-wizard__task.is-link {
  cursor: pointer;
}

.wv-wizard__task.is-link:hover.is-complete .wv-wizard__task__number,
.wv-wizard__task.is-link:hover.is-error .wv-wizard__task__number,
.wv-wizard__task.is-link:hover.is-warning .wv-wizard__task__number {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  transition: all 200ms ease;
}

.wv-wizard__task.is-link:hover.is-error .wv-wizard__task__number {
  background-color: #d74242;
  border-color: #d74242;
}

.wv-wizard__task.is-link:hover.is-error .wv-wizard__task__title {
  color: #c22929;
}

.wv-wizard__task.is-link:hover.is-warning .wv-wizard__task__title {
  color: #7c760e;
}

.wv-wizard__content__sub-task-list {
  margin: 0;
  padding: 0;
}

@media (max-width: 1024px) {
  .wv-wizard__content__sub-task-list {
    margin-top: 24px;
  }
}

.wv-wizard__content__sub-task {
  display: block;
  padding: 12px;
  margin-bottom: 12px;
  border-radius: 8px;
  border: 1px solid #b2c2cd;
  background-color: #fff;
  padding: 8px 24px;
  position: relative;
}

.wv-wizard .wv-wizard__content__sub-task {
  margin-bottom: 24px;
}

.wv-wizard__content__sub-task.is-complete {
  background: #ecf0f3;
  box-shadow: none;
  border: none;
  border: none;
  padding: 8px 24px;
}

.wv-wizard__content__sub-task.is-current {
  box-shadow: 0 8px 32px rgba(77, 101, 117, 0.35);
  border-radius: 12px;
  border-color: transparent;
  padding: 24px;
}

.wv-wizard__content__sub-task.is-current .wv-text--large {
  font-weight: 600;
}

.wv-wizard__content__sub-task::after {
  content: '';
  position: absolute;
  left: 35px;
  border: 2px solid #d4dde3;
  height: 22px;
  top: 100%;
  z-index: -1;
}

.wv-wizard__content__sub-task:last-child::after {
  border: none;
}

.wv-wizard__content__sub-task .wv-wizard__task__actions {
  position: absolute;
  top: 16px;
  right: 24px;
}

.wv-wizard__content {
  -webkit-flex: 1;
  flex: 1;
}

.wv-wizard__content__task {
  display: block;
  padding: 12px;
  margin-bottom: 12px;
  border-radius: 8px;
  border: 1px solid #b2c2cd;
  background-color: #fff;
  padding: 24px;
}

.wv-wizard__content__task:not(.is-current) {
  display: none;
}

.wv-wizard__content__task.is-current {
  box-shadow: 0 8px 32px rgba(77, 101, 117, 0.35);
  border-radius: 12px;
  border-color: transparent;
  display: block;
}

@media (max-width: 1024px) {
  .wv-wizard__content__task.is-current {
    box-shadow: none;
    border-radius: 0;
    padding: 24px 8px;
  }
}

.wv-wizard__content__task.is-current .wv-text--large {
  font-weight: 600;
}

.wv-wizard__task__content__header .wv-text {
  margin-top: 0;
}

.wv-wizard__task__content__footer {
  margin-top: 24px;
  text-align: right;
}

.wv-wizard__task__content__footer .wv-divider:first-child {
  margin-bottom: 16px;
}

.wv-topbar--app,
.wv-topbar--minimal {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  box-sizing: border-box;
  margin: 0 auto;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
}

.wv-topbar--minimal {
  -webkit-justify-content: center;
  justify-content: center;
  height: 72px;
  padding-top: 48px;
}

@media only screen and (max-width: 768px) {
  .wv-topbar--minimal {
    height: 56px;
  }
}

.wv-topbar--app {
  background-color: white;
  color: #1c252c;
  border-bottom: 1px solid #d4dde3;
}

@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 0.05;
  }

  50% {
    opacity: 0.25;
  }

  100% {
    -webkit-transform: scale(1.5, 1.5);
    transform: scale(1.5, 1.5);
    opacity: 0;
  }
}

@keyframes pulsate {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 0.05;
  }

  50% {
    opacity: 0.25;
  }

  100% {
    -webkit-transform: scale(1.5, 1.5);
    transform: scale(1.5, 1.5);
    opacity: 0;
  }
}

@-webkit-keyframes pulsate__transparent {
  0% {
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
  }

  50% {
    -webkit-transform: scale(1.14, 1.14);
    transform: scale(1.14, 1.14);
  }

  100% {
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
  }
}

@keyframes pulsate__transparent {
  0% {
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
  }

  50% {
    -webkit-transform: scale(1.14, 1.14);
    transform: scale(1.14, 1.14);
  }

  100% {
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
  }
}

@-webkit-keyframes targetHide {
  0% {
    z-index: 1011;
  }

  99% {
    z-index: 1011;
  }

  100% {
    z-index: auto;
  }
}

@keyframes targetHide {
  0% {
    z-index: 1011;
  }

  99% {
    z-index: 1011;
  }

  100% {
    z-index: auto;
  }
}

.wv-hotspot__target {
  position: relative;
}

.wv-hotspot__target.is-open {
  z-index: 1011;
}

.wv-hotspot__target.is-open > .wv-hotspot__clear-overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1012;
}

.wv-hotspot__target.is-closed > :last-child {
  -webkit-animation-duration: 150ms;
  animation-duration: 150ms;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-name: targetHide;
  animation-name: targetHide;
}

.wv-hotspot__target-wrapper {
  position: relative;
}

.wv-hotspot__wrapper {
  position: absolute;
  cursor: pointer;
  margin: -14px -14px 0;
}

.wv-hotspot__trigger {
  height: 28px;
  width: 28px;
  position: relative;
  opacity: 1;
  z-index: 2;
}

.wv-hotspot__trigger .wv-hotspot__pulse {
  border: 1px solid #6e8ca0;
  border-radius: 50%;
  height: 28px;
  width: 28px;
  position: absolute;
  top: -1px;
  left: -1px;
  pointer-events: none;
  opacity: 0;
  -webkit-animation: pulsate 1500ms ease infinite;
  animation: pulsate 1500ms ease infinite;
}

.wv-hotspot__trigger .wv-hotspot__transparent {
  height: 26px;
  width: 26px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(35, 199, 112, 0.16);
  border: 1px solid rgba(110, 140, 160, 0.3);
  border-radius: 50%;
  -webkit-animation: pulsate__transparent 1500ms ease infinite;
  animation: pulsate__transparent 1500ms ease infinite;
}

.wv-hotspot__trigger .wv-hotspot__center {
  height: 14px;
  width: 14px;
  background-color: #23c770;
  position: absolute;
  top: 7px;
  left: 7px;
  border-radius: 50%;
}

.wv-hotspot__trigger:hover .wv-hotspot__pulse {
  display: none;
}

.wv-hotspot__trigger:hover .wv-hotspot__transparent {
  border: 1px solid #23c770;
  -webkit-animation: none;
  animation: none;
}

.wv-hotspot__trigger.is-active .wv-hotspot__pulse {
  display: none;
}

.wv-hotspot__trigger.is-active .wv-hotspot__transparent {
  border: 1px solid #23c770;
  -webkit-animation: none;
  animation: none;
}

.wv-hotspot__trigger.is-active .wv-hotspot__center {
  background-color: #23c770;
}

.wv-hotspot__trigger.is-viewed {
  opacity: 0.5;
  transition: opacity 150ms 150ms;
}

.wv-hotspot__trigger.is-viewed .wv-hotspot__pulse {
  display: none;
}

.wv-hotspot__trigger.is-viewed .wv-hotspot__transparent {
  -webkit-animation: none;
  animation: none;
}

.wv-hotspot__connector {
  position: absolute;
  height: 0;
  width: 0;
  overflow: visible;
  pointer-events: none;
}

.wv-hotspot__connector.is-open {
  height: auto;
  width: auto;
  transition: all 25ms;
  z-index: 1014;
}

.wv-hotspot__connector.is-closed {
  height: 0;
  width: 0;
  opacity: 0;
  transition: all 25ms 75ms;
}

.wv-hotspot__connector line {
  stroke-dasharray: 0.4, 8;
  stroke-linecap: round;
  stroke: #23c770;
  stroke-width: 4;
}

.wv-hotspot__content {
  position: absolute;
  width: 340px;
  padding: 20px 40px 20px 20px;
  border-radius: 4px;
  background-color: #e1faec;
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.3);
}

.wv-hotspot__content > :nth-child(2) {
  margin-top: 0;
}

.wv-hotspot__content > :last-child {
  margin-bottom: 0;
}

.wv-hotspot__content.is-open {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  transition: 150ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  z-index: 1014;
}

.wv-hotspot__content.is-closed {
  visibility: hidden;
  opacity: 0;
  -webkit-transform: scale(0.8, 0.8);
  transform: scale(0.8, 0.8);
  transition: 150ms;
}

.wv-hotspot__content--left {
  -webkit-transform-origin: left;
  transform-origin: left;
  border-left: 6px solid #23c770;
}

.wv-hotspot__content--right {
  -webkit-transform-origin: right;
  transform-origin: right;
  border-right: 6px solid #23c770;
}

.wv-hotspot__content--top {
  -webkit-transform-origin: top;
  transform-origin: top;
  border-left: 6px solid #23c770;
}

.wv-hotspot__content--bottom {
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  border-left: 6px solid #23c770;
}

.wv-hotspot__content--left-top {
  -webkit-transform-origin: left top;
  transform-origin: left top;
  border-left: 6px solid #23c770;
}

.wv-hotspot__content--right-top {
  -webkit-transform-origin: right top;
  transform-origin: right top;
  border-right: 6px solid #23c770;
}

.wv-hotspot__content--right-bottom {
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom;
  border-right: 6px solid #23c770;
}

.wv-hotspot__content--left-bottom {
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom;
  border-left: 6px solid #23c770;
}

.wv-hotspot__content .wv-close {
  top: 8px;
  right: 8px;
}

.wv-nav--primary {
  height: 100%;
  overflow-y: auto;
  -webkit-flex-grow: 2;
  flex-grow: 2;
  color: #1c252c;
  background: transparent;
}

.wv-nav--primary::-webkit-scrollbar {
  width: 8px;
  margin: 5px;
}

.wv-nav--primary::-webkit-scrollbar-thumb {
  width: 6px;
  margin: 2px;
  border-radius: 10px;
  background-color: #d7e0ea;
}

.wv-nav__menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.wv-nav__menu--nested {
  padding-bottom: 4px;
  margin-top: -125%;
  margin-bottom: 0;
  opacity: 0;
  transition: margin-top 0.5s ease, opacity 0.5s ease;
}

.wv-nav__menu__item--expandable.is-open .wv-nav__menu--nested {
  margin-top: 0;
  opacity: 1;
}

.wv-nav__menu--extras {
  margin: 8px 0;
}

.wv-nav__menu__item {
  color: #4d6575;
  font-weight: normal;
}

.wv-nav__menu__item.is-open,
.is-open > .wv-nav__menu__item,
.wv-nav__menu__item:hover {
  color: inherit;
}

.wv-nav__menu__item.is-open .wv-nav__menu__item__caret.wv-svg-icon,
.is-open > .wv-nav__menu__item .wv-nav__menu__item__caret.wv-svg-icon,
.wv-nav__menu__item:hover .wv-nav__menu__item__caret.wv-svg-icon {
  color: #4d6575;
}

.wv-nav__menu__item.is-open {
  background-color: rgba(0, 0, 0, 0);
}

.wv-nav__menu--nested .wv-nav__menu__item {
  color: #4d6575;
  margin-top: -8px;
  opacity: 0;
  transition: margin-top 0.25s ease, opacity 0.1s ease;
}

.wv-nav__menu--nested .wv-nav__menu__item:hover {
  color: #1c252c;
}

.wv-nav__menu__item--expandable.is-open
  .wv-nav__menu--nested
  .wv-nav__menu__item {
  margin-top: 0;
  opacity: 1;
  transition-delay: 0.3s;
}

.wv-nav__menu__item__link {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  text-decoration: none;
  color: inherit;
  position: relative;
  cursor: pointer;
}

.wv-nav__menu__item__link:hover {
  background: rgba(0, 0, 0, 0);
}

.wv-nav__menu--apps .wv-nav__menu__item__link {
  padding: 12px 32px 12px 64px;
}

.wv-nav__menu--nested .wv-nav__menu__item__link {
  padding: 6px 0 6px 64px;
}

.wv-nav__menu--nested .wv-nav__menu__item.is-current .wv-nav__menu__item__link {
  font-weight: bold;
  color: #136acd;
}

.wv-nav__menu--extras .wv-nav__menu__item__link {
  padding: 4px 16px;
}

.is-current.is-open > .wv-nav__menu__item__link:hover {
  background: transparent;
  cursor: default;
}

.wv-nav__menu__item__link .wv-nav__menu__item__link__new-badge,
.wv-nav__menu__item__link .wv-nav__menu__item__link__beta-badge {
  font-weight: bold;
  font-size: 9px;
  line-height: 13px;
  color: white;
  text-transform: uppercase;
  text-align: center;
  border-radius: 2px;
  width: 32px;
  position: absolute;
  left: 21px;
  top: calc(50% - 13px / 2);
}

.wv-nav__menu__item__link__new-badge {
  background-color: #ef9b25;
}

.wv-nav__menu__item__link__beta-badge {
  background-color: #c1b815;
}

.wv-nav__menu__item__icon {
  position: absolute;
  left: 24px;
  top: calc(50% - 14px);
  display: inline-block;
  color: #b2c2cd;
}

.wv-nav__menu__item.is-current .wv-nav__menu__item__icon {
  color: #136acd;
}

.wv-nav__menu__item--expandable.is-open .wv-nav__menu__item__icon,
.wv-nav__menu__item:not(.is-current):hover .wv-nav__menu__item__icon {
  color: #4d6575;
}

.wv-nav__menu__item__icon--dashboard:empty::before {
  content: '';
}

.wv-nav__menu__item__icon--sales:empty::before {
  content: '';
}

.wv-nav__menu__item__icon--purchases:empty::before {
  content: '';
}

.wv-nav__menu__item__icon--accounting:empty::before {
  content: '';
}

.wv-nav__menu__item__icon--banking:empty::before {
  content: '';
}

.wv-nav__menu__item__icon--payroll:empty::before {
  content: '';
}

.wv-nav__menu__item__icon--reports:empty::before {
  content: '';
}

.wv-nav__menu--apps .wv-nav__menu__item__label {
  font-weight: normal;
  color: inherit;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

.wv-nav__menu--extras .wv-nav__menu__item__label {
  color: inherit;
}

.wv-nav__menu--extras .is-current .wv-nav__menu__item__label {
  color: #136acd;
  font-weight: bold;
}

.wv-nav__menu--apps .wv-nav__menu__item.is-current .wv-nav__menu__item__label {
  font-weight: bold;
  color: #136acd;
}

.wv-nav__menu--apps
  .wv-nav__menu__item--expandable.is-open
  .wv-nav__menu__item__label {
  font-weight: bold;
  color: #1c252c;
}

.wv-nav__menu__item__caret {
  position: absolute;
  top: calc(50% - (10px / 2));
  right: 16px;
  font-size: 10px;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  color: #b2c2cd;
}

.is-open > .wv-nav__menu__item__link > .wv-nav__menu__item__caret {
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}

.wv-nav__menu__item__caret.wv-svg-icon {
  width: 12px;
  height: 12px;
  top: calc(50% - (12px / 2));
}

.wv-nav__menu__item__expandable-content {
  overflow: hidden;
}

.wv-nav__divider {
  display: block;
  height: 0;
  padding: 0;
  margin: 4px 16px 0;
  border: none;
  border-bottom: 1px solid #d4dde3;
}

.wv-nav--secondary--horizontal,
.wv-nav--secondary {
  position: relative;
}

@media (max-width: 768px) {
  .wv-nav--secondary--horizontal::before,
  .wv-nav--secondary::before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    pointer-events: none;
    z-index: 100;
    background: linear-gradient(to left, #fff, rgba(255, 255, 255, 0));
    height: 36px;
    width: 24px;
  }
}

.wv-nav--secondary--horizontal .wv-nav__section,
.wv-nav--secondary .wv-nav__section {
  border-bottom: 1px solid #d4dde3;
}

@media (max-width: 768px) {
  .wv-nav--secondary--horizontal .wv-nav__section,
  .wv-nav--secondary .wv-nav__section {
    white-space: nowrap;
    overflow-x: auto;
  }
}

.wv-nav--secondary--horizontal .wv-nav__item,
.wv-nav--secondary .wv-nav__item {
  display: inline-block;
}

.wv-nav--secondary--horizontal .wv-nav__item .wv-svg-icon,
.wv-nav--secondary .wv-nav__item .wv-svg-icon {
  position: relative;
  bottom: -4px;
}

.wv-nav--secondary--horizontal .wv-nav__link,
.wv-nav--secondary .wv-nav__link {
  display: block;
  padding: 8px;
  margin-right: 16px;
  cursor: pointer;
  text-decoration: none;
  font-weight: normal;
  color: #4d6575;
  border-bottom: 4px solid transparent;
}

.wv-nav--secondary--horizontal .wv-nav__link:hover,
.wv-nav--secondary .wv-nav__link:hover {
  color: #1c252c;
  border-bottom: 4px solid #d4dde3;
}

.wv-nav--secondary--horizontal .wv-nav__link.is-active,
.wv-nav--secondary--horizontal .wv-nav__link.is-active:hover,
.wv-nav--secondary .wv-nav__link.is-active,
.wv-nav--secondary .wv-nav__link.is-active:hover {
  color: #1c252c;
  font-weight: bold;
  border-bottom: 4px solid #136acd;
}

.wv-nav--secondary--horizontal .wv-nav__link .wv-icon--open-menu,
.wv-nav--secondary--horizontal .wv-nav__link .wv-select__input__icon:empty,
.wv-nav--secondary--horizontal .wv-nav__link .wv-icon--close-menu,
.wv-nav--secondary .wv-nav__link .wv-icon--open-menu,
.wv-nav--secondary .wv-nav__link .wv-select__input__icon:empty,
.wv-nav--secondary .wv-nav__link .wv-icon--close-menu {
  margin: 0 -8px 0 0;
}

.wv-nav--secondary--horizontal .wv-nav__link__count,
.wv-nav--secondary--horizontal .wv-nav__link__count--warning,
.wv-nav--secondary--horizontal .wv-nav__link__count--danger,
.wv-nav--secondary .wv-nav__link__count,
.wv-nav--secondary .wv-nav__link__count--warning,
.wv-nav--secondary .wv-nav__link__count--danger {
  background: #dcdfe0;
  color: #5c686a;
  font-weight: 600;
  padding: 0 6px;
  border-radius: 500px;
  font-size: 12px;
  line-height: 1.4;
  margin-left: 4px;
  display: inline-block;
}

.wv-nav--secondary--horizontal .wv-nav__link__count--warning,
.wv-nav--secondary .wv-nav__link__count--warning {
  color: white;
  background: #dcd318;
}

.wv-nav--secondary--horizontal .wv-nav__link__count--danger,
.wv-nav--secondary .wv-nav__link__count--danger {
  color: white;
  background: #d74242;
}

.wv-nav--tertiary {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column wrap;
  flex-flow: column wrap;
}

@media (max-width: 768px) {
  .wv-nav--tertiary {
    border: 1px solid #b2c2cd;
    border-radius: 4px;
    position: relative;
  }
}

.wv-nav--tertiary .wv-nav__menu {
  width: 100%;
}

@media (max-width: 768px) {
  .wv-nav--tertiary .wv-nav__menu {
    display: none;
  }
}

@media (max-width: 768px) {
  .wv-nav--tertiary.is-open {
    box-shadow: 0 0 0 3px rgba(19, 106, 205, 0.2);
    border-color: #136acd;
    border-radius: 4px 4px 0 0;
  }

  .wv-nav--tertiary.is-open > .wv-nav__menu {
    display: block;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 0 0 1px rgba(77, 101, 117, 0.1),
      0 3px 10px 0 rgba(77, 101, 117, 0.2);
    width: 100%;
    position: absolute;
    max-height: 224px;
    left: 1px;
    top: 62px;
    overflow-y: auto;
    background-color: white;
    z-index: 1000;
  }
}

.wv-nav--tertiary .wv-nav__heading {
  width: 100%;
  box-sizing: border-box;
  color: #1c252c;
  font-weight: bold;
  padding-left: 32px;
  margin-top: 6px;
}

@media (max-width: 768px) {
  .wv-nav--tertiary .wv-nav__heading {
    margin: auto 0;
    padding: 8px 0 0 12px;
    box-sizing: border-box;
    font-size: 12px;
    color: #4d6575;
    font-weight: normal;
    cursor: pointer;
  }
}

.wv-nav--tertiary__toggle {
  display: none;
}

.wv-nav--tertiary__toggle__selected {
  -webkit-flex: 1;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wv-nav--tertiary__toggle__icon {
  margin: 0 12px;
  display: -webkit-flex;
  display: flex;
}

@media (max-width: 768px) {
  .wv-nav--tertiary__toggle {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    width: 100%;
    padding: 8px 0 8px 12px;
    box-sizing: border-box;
    cursor: pointer;
  }
}

.wv-nav--tertiary .wv-nav__title {
  padding: 8px 32px;
}

@media (max-width: 768px) {
  .wv-nav--tertiary .wv-nav__title {
    padding: 8px 12px;
  }
}

.wv-nav--tertiary .wv-nav__section {
  padding-bottom: 16px;
}

.wv-nav--tertiary .wv-nav__link {
  display: block;
  padding: 8px 16px;
  margin: 0 16px;
  cursor: pointer;
  text-decoration: none;
  color: #136acd;
  font-weight: 600;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .wv-nav--tertiary .wv-nav__link {
    padding: 8px 0 8px 8px;
    margin: 0 12px;
  }
}

.wv-nav--tertiary .wv-nav__link:hover {
  color: #1c252c;
  background-color: #f0f4fa;
}

@media (max-width: 768px) {
  .wv-nav--tertiary .wv-nav__link:hover {
    padding: 8px 12px 8px 20px;
    margin: 0;
    border-radius: 0;
  }
}

.wv-nav--tertiary .wv-nav__link.is-active {
  color: #1c252c;
  background-color: #f0f4fa;
  text-decoration: none;
  cursor: default;
}

@media (max-width: 768px) {
  .wv-nav--tertiary .wv-nav__link.is-active {
    padding: 8px 12px 8px 20px;
    margin: 0;
    border-radius: 0;
  }
}

.wv-nav--tertiary .wv-nav__link.is-active:hover {
  cursor: pointer;
}

.wv-nav__promo,
.wv-nav__promo--topbar {
  display: block;
  text-decoration: none;
  color: #1c252c;
  text-align: center;
  background: white;
  border: 2px solid #718fa2;
  border-radius: 8px;
  box-sizing: border-box;
  margin: 0 16px;
  padding: 24px 16px;
  transition: all 150ms ease;
}

.wv-nav__promo [class*='wv-nav__promo__icon'],
.wv-nav__promo--topbar [class*='wv-nav__promo__icon'] {
  display: none;
}

.wv-nav__promo [class*='wv-nav__promo__icon'] svg,
.wv-nav__promo--topbar [class*='wv-nav__promo__icon'] svg {
  fill: #1c252c;
}

.wv-nav__promo:hover,
.wv-nav__promo:active,
.wv-nav__promo:focus:not(.is-disabled),
.wv-nav__promo--topbar:hover,
.wv-nav__promo--topbar:active,
.wv-nav__promo--topbar:focus:not(.is-disabled) {
  box-shadow: 0 8px 32px rgba(77, 101, 117, 0.35);
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
}

.wv-nav__promo:hover [class*='wv-nav__promo__icon'] svg,
.wv-nav__promo:active [class*='wv-nav__promo__icon'] svg,
.wv-nav__promo:focus:not(.is-disabled) [class*='wv-nav__promo__icon'] svg,
.wv-nav__promo--topbar:hover [class*='wv-nav__promo__icon'] svg,
.wv-nav__promo--topbar:active [class*='wv-nav__promo__icon'] svg,
.wv-nav__promo--topbar:focus:not(.is-disabled)
  [class*='wv-nav__promo__icon']
  svg {
  fill: #136acd;
}

.wv-nav__promo:focus:not(.is-disabled),
.wv-nav__promo--topbar:focus:not(.is-disabled) {
  box-shadow: 0 0 0 4px rgba(19, 106, 205, 0.2);
}

.wv-nav__promo .wv-nav__promo__icon--large,
.wv-nav__promo--topbar .wv-nav__promo__icon--large {
  display: inline-block;
  height: 32px;
}

.wv-nav__promo .wv-nav__promo__title,
.wv-nav__promo .wv-nav__promo__title--long,
.wv-nav__promo .wv-nav__promo__title--short,
.wv-nav__promo--topbar .wv-nav__promo__title,
.wv-nav__promo--topbar .wv-nav__promo__title--long,
.wv-nav__promo--topbar .wv-nav__promo__title--short {
  font-weight: 600;
  font-size: 14px;
  margin: 16px 0 0;
}

.wv-nav__promo .wv-nav__promo__title--short,
.wv-nav__promo--topbar .wv-nav__promo__title--short {
  display: none;
}

.wv-nav__promo .wv-nav__promo__body,
.wv-nav__promo--topbar .wv-nav__promo__body {
  font-size: 13px;
  line-height: 19px;
  margin: 8px 0 0;
}

.wv-nav__promo .wv-nav__promo__button,
.wv-nav__promo--topbar .wv-nav__promo__button {
  color: #1c252c;
  width: 100%;
  margin: 16px 0 0;
  padding-left: 12px;
  padding-right: 12px;
  display: inline-block;
  background: #30dfe8;
  border: 0;
}

@media only screen and (max-height: 1090px) {
  .wv-nav__promo .wv-nav__promo__button {
    display: none;
  }

  .wv-nav__promo .wv-nav__promo__body {
    margin-bottom: 0;
  }
}

@media only screen and (max-height: 1045px) {
  .wv-nav__promo .wv-nav__promo__body {
    display: none;
  }

  .wv-nav__promo .wv-nav__promo__title--long {
    display: none;
  }

  .wv-nav__promo .wv-nav__promo__title,
  .wv-nav__promo .wv-nav__promo__title--short {
    display: block;
  }
}

@media only screen and (max-height: 1000px) {
  .wv-nav__promo {
    position: fixed;
    bottom: 70px;
    width: 218px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    min-height: 48px;
    padding: 8px;
    margin: 0;
    margin-left: 16px;
  }

  .wv-nav__promo .wv-nav__promo__icon--large {
    display: none;
  }

  .wv-nav__promo .wv-nav__promo__icon--small {
    height: 16px;
    margin-right: 10px;
    display: inline-block;
  }

  .wv-nav__promo .wv-nav__promo__title,
  .wv-nav__promo .wv-nav__promo__title--short {
    margin: 10px 0;
  }

  .wv-nav__promo .wv-nav__promo__body {
    display: none;
  }

  .wv-nav--primary.has-payments-promo {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 1234px) {
  .wv-nav__promo {
    bottom: 10px;
  }
}

.wv-nav__promo--topbar {
  display: none;
  -webkit-align-items: center;
  align-items: center;
  border-radius: 500px;
  min-height: 30px;
  text-align: left;
  margin: 0 100px 0 auto;
  padding: 3px 12px;
  margin-top: 1px;
}

@media (min-width: 800px) {
  .wv-nav__promo--topbar {
    display: -webkit-flex;
    display: flex;
  }
}

.wv-nav__promo--topbar .wv-nav__promo__icon--small {
  height: 16px;
  display: inline-block;
  margin-right: 10px;
}

.wv-nav__promo--topbar:hover,
.wv-nav__promo--topbar:active {
  box-shadow: none;
  -webkit-transform: none;
  transform: none;
}

.wv-nav__promo--topbar .wv-nav__promo__title {
  margin: 0;
  white-space: nowrap;
}

@-webkit-keyframes toastPopup {
  0% {
    visibility: visible;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }

  50% {
    -webkit-transform: translate(-50%, -448px);
    transform: translate(-50%, -448px);
  }

  75% {
    -webkit-transform: translate(-50%, -430px);
    transform: translate(-50%, -430px);
  }

  100% {
    -webkit-transform: translate(-50%, -432px);
    transform: translate(-50%, -432px);
    visibility: visible;
  }
}

@keyframes toastPopup {
  0% {
    visibility: visible;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }

  50% {
    -webkit-transform: translate(-50%, -448px);
    transform: translate(-50%, -448px);
  }

  75% {
    -webkit-transform: translate(-50%, -430px);
    transform: translate(-50%, -430px);
  }

  100% {
    -webkit-transform: translate(-50%, -432px);
    transform: translate(-50%, -432px);
    visibility: visible;
  }
}

@-webkit-keyframes toastDown {
  0% {
    -webkit-transform: translate(-50%, -432px);
    transform: translate(-50%, -432px);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    visibility: hidden;
  }
}

@keyframes toastDown {
  0% {
    -webkit-transform: translate(-50%, -432px);
    transform: translate(-50%, -432px);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    visibility: hidden;
  }
}

.wv-toast {
  position: fixed;
  width: 720px;
  border-radius: 12px;
  color: #fff;
  border-color: #f5ebf9;
  overflow: hidden;
  z-index: 5000;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.4);
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -400px;
  background: #103265;
  visibility: hidden;
}

@media (max-width: 1024px) {
  .wv-toast {
    width: 296px;
  }
}

.wv-toast--open {
  -webkit-animation: toastPopup 0.8s ease-in-out forwards;
  animation: toastPopup 0.8s ease-in-out forwards;
}

.wv-toast--close {
  -webkit-animation: toastDown 0.4s ease-out forwards;
  animation: toastDown 0.4s ease-out forwards;
}

.wv-toast .wv-button--secondary {
  color: inherit;
  border-color: inherit;
}

.wv-toast .wv-button--secondary:hover {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );
  color: inherit;
  border-color: inherit;
}

@media (max-width: 1024px) {
  .wv-toast .wv-button--secondary {
    width: 100%;
    margin: 0;
  }
}

.wv-toast__content {
  max-width: 664px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 24px;
}

@media (max-width: 1024px) {
  .wv-toast__content {
    margin: 0;
    -webkit-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }
}

.wv-toast__content__title {
  color: #fff;
  margin: 0;
}

.wv-toast__content__text {
  color: #fff;
  margin: 8px 0 0;
}

.wv-toast__content__image {
  margin-right: 16px;
}

@media (max-width: 1024px) {
  .wv-toast__content__image {
    margin: 0 auto 16px;
  }
}

.wv-toast__content__message {
  -webkit-flex: 1;
  flex: 1;
  -webkit-align-self: center;
  -ms-grid-row-align: center;
  align-self: center;
  padding-right: 16px;
}

.wv-toast__content__video {
  min-width: 168px;
  padding: 0 8px;
  margin-right: 8px;
}

@media (max-width: 1024px) {
  .wv-toast__content__video {
    margin: 16px auto 0;
  }
}

.wv-toast__content__timer {
  background: #7b99c6;
  width: 100%;
  height: 4px;
  position: absolute;
  left: 0;
  top: 0;
}

.wv-toast__content__progress {
  position: relative;
  width: 100%;
  height: 100%;
  background: #103265;
}

.wv-toast__content__actions {
  width: 100%;
  margin-top: 16px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-align-items: center;
  align-items: center;
}

.wv-toast__content__simple-action {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-align-items: center;
  align-items: center;
  margin: 0 24px;
}

@media (max-width: 1024px) {
  .wv-toast__content__simple-action {
    margin: 16px 0 0;
  }
}

.wv-toast__close--medium {
  right: 16px;
  top: 16px;
}

.wv-toast__close--medium::before,
.wv-toast__close--medium::after {
  background-color: white;
}

.wv-toast__close--medium:hover::before,
.wv-toast__close--medium:hover::after {
  background-color: #b1c9ec;
}

.wv-toast-progress {
  position: absolute;
  top: calc(50% - 15px);
  right: calc(50% - 16px);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.wv-toast-progress--value {
  fill: none;
  stroke: #7b99c6;
  stroke-linecap: round;
}

.wv-toast__close--simple {
  right: 16px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

@media (max-width: 1024px) {
  .wv-toast__close--simple {
    right: 8px;
    top: 20px;
  }
}

.wv-toast__close--simple::before,
.wv-toast__close--simple::after {
  background-color: white;
}

.wv-toast__close--simple:hover::before,
.wv-toast__close--simple:hover::after {
  background-color: #b1c9ec;
}

.wv-timeline {
  width: 100%;
}

.wv-timeline__year {
  padding: 16px;
  color: #4d6575;
  font-weight: 600;
}

.wv-timeline__year:not(:first-child) {
  border-top: 1px solid #d4dde3;
  padding-top: 24px;
}

@media (min-width: 769px) {
  .wv-timeline__year:not(:first-child) {
    padding-top: 32px;
  }
}

.wv-timeline__event {
  border-top: 1px solid #d4dde3;
}

.wv-timeline__event:last-child {
  border-bottom: 1px solid #d4dde3;
}

.wv-timeline__event--danger .wv-timeline__event__summary {
  background: #fbeaea;
}

.wv-timeline__event__details {
  padding: 16px;
}

.wv-timeline__event--expandable .wv-timeline__event__summary {
  cursor: pointer;
}

.wv-timeline__event--expandable.is-expanded {
  box-shadow: 0 8px 32px rgba(77, 101, 117, 0.35);
  border-top: 1px solid transparent;
  border-radius: 8px;
  position: relative;
}

.wv-timeline__event--expandable.is-expanded:last-child {
  border-bottom: 1px solid transparent;
}

.wv-timeline__event--expandable.is-expanded .wv-timeline__event__summary {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.wv-timeline__event--expandable.is-expanded.wv-timeline__event--danger {
  border-top-color: #fbeaea;
}

.wv-timeline__event--expandable.is-expanded + .wv-timeline__event,
.wv-timeline__event--expandable.is-expanded + .wv-timeline__year {
  border-top: 1px solid transparent;
}

.wv-timeline__event--expandable:not(.is-expanded) .wv-timeline__event__details {
  display: none;
}

.wv-timeline__event--expandable:not(.is-expanded):hover
  .wv-timeline__event__summary {
  background: #e6f1ff;
}

.wv-timeline__event--expandable:not(
    .is-expanded
  ):hover.wv-timeline__event--danger
  .wv-timeline__event__summary {
  background: #f8dddd;
}

.wv-timeline__event__actions-footer {
  width: 100%;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.wv-timeline__event__actions-footer--bookended {
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.wv-timeline__event__actions-footer.wv-button-wrapper {
  margin-top: 24px;
}

.wv-timeline__event__actions-footer__supporting-info {
  text-align: center;
  margin: 4px;
}

@media (max-width: 480px) {
  .wv-timeline__event__actions-footer__supporting-info {
    width: 100%;
  }
}

.wv-timeline__event__summary {
  display: -ms-grid;
  display: grid;
  grid-gap: 16px;
  -webkit-align-items: center;
  align-items: center;
  padding: 16px;
}

@media (max-width: 768px) {
  .wv-timeline__event__summary {
    padding-bottom: 24px;
  }
}

@media (max-width: 768px) {
  .wv-timeline__event__summary {
    -ms-grid-columns: [icon-start] auto [date-start description-start
      badge-start] 1fr;
    grid-template-columns: [icon-start] auto [date-start description-start badge-start] 1fr;
  }

  .wv-timeline__event.wv-timeline__event--expandable
    .wv-timeline__event__summary {
    -ms-grid-columns: [icon-start] auto [date-start description-start
      badge-start] 1fr [toggle-start] 24px;
    grid-template-columns: [icon-start] auto [date-start description-start badge-start] 1fr [toggle-start] 24px;
  }
}

@media (min-width: 769px) {
  .wv-timeline__event__summary {
    -ms-grid-columns: [date-start] 48px [icon-start] auto [description-start]
      1fr [badge-start] auto;
    grid-template-columns: [date-start] 48px [icon-start] auto [description-start] 1fr [badge-start] auto;
  }

  .wv-timeline.has-datetimes .wv-timeline__event__summary {
    -ms-grid-columns: [date-start] 120px [icon-start] auto [description-start]
      1fr [badge-start] auto;
    grid-template-columns: [date-start] 120px [icon-start] auto [description-start] 1fr [badge-start] auto;
  }

  .wv-timeline.has-expandable-rows .wv-timeline__event__summary {
    -ms-grid-columns: [date-start] 48px [icon-start] auto [description-start]
      1fr [badge-start] auto [toggle-start] 24px;
    grid-template-columns: [date-start] 48px [icon-start] auto [description-start] 1fr [badge-start] auto [toggle-start] 24px;
  }

  .wv-timeline.has-datetimes.has-expandable-rows .wv-timeline__event__summary {
    -ms-grid-columns: [date-start] 120px [icon-start] auto [description-start]
      1fr [badge-start] auto [toggle-start] 24px;
    grid-template-columns: [date-start] 120px [icon-start] auto [description-start] 1fr [badge-start] auto [toggle-start] 24px;
  }
}

.wv-timeline__cell--icon {
  grid-column: icon-start;
  grid-column-start: icon-start;
  grid-row: 1;
  grid-row-start: 1;
}

.wv-timeline__cell--date {
  grid-column: date-start;
  grid-column-start: date-start;
}

.wv-timeline__cell--description {
  grid-column: description-start;
  grid-column-start: description-start;
}

@media (max-width: 768px) {
  .wv-timeline__cell--description {
    grid-column-end: -1;
  }
}

.wv-timeline__cell--badge {
  text-align: left;
  grid-column: badge-start;
  grid-column-start: badge-start;
}

.wv-timeline__cell--expansion-toggle {
  grid-column: toggle-start;
  grid-column-start: toggle-start;
  grid-row: 1;
  grid-row-start: 1;
}

.wv-timeline__cell--expansion-toggle .wv-button--icon {
  vertical-align: text-bottom;
}

.wv-timeline__event__icon {
  width: 32px;
  height: 32px;
  border-radius: 40px;
  background: #ecf0f3;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  color: #4d6575;
}

@media (min-width: 769px) {
  .wv-timeline__event__icon {
    width: 40px;
    height: 40px;
  }
}

.wv-timeline__event--expandable:not(.is-expanded):hover
  .wv-timeline__event__icon:not(.wv-timeline__event__icon--accent):not(
    .wv-timeline__event__icon--alert
  ):not(.wv-timeline__event__icon--danger):not(
    .wv-timeline__event__icon--success
  ) {
  background: white;
}

.wv-timeline__event__icon--accent {
  background: #7f309c;
  color: white;
}

.wv-timeline__event__icon--alert {
  background: #c1b815;
  color: white;
}

.wv-timeline__event__icon--danger {
  background: #c22929;
  color: white;
}

.wv-timeline__event__icon--success {
  background: #23c770;
  color: white;
}

.wv-timeline__event__byline {
  font-style: italic;
  color: #4d6575;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .wv-timeline__event__byline {
    display: block;
  }
}

.wv-financial-institution__list-item,
.wv-financial-institution__list-item--danger {
  border: 1px solid #b2c2cd;
  border-top-width: 0;
  padding: 16px;
}

.wv-financial-institution__list-item:last-child,
.wv-financial-institution__list-item--danger:last-child {
  border-radius: 0 0 8px 8px;
}

.wv-financial-institution + .wv-financial-institution {
  margin-top: 16px;
}

.wv-financial-institution__header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  padding: 16px;
  background: #f1f3f3;
  border: 1px solid #b2c2cd;
  border-radius: 8px 8px 0 0;
}

.wv-financial-institution__header__bank-logo-name-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-align-items: center;
  align-items: center;
}

.wv-financial-institution__header__bank-icon {
  margin-right: 8px;
}

.wv-financial-institution__header__bank-name {
  margin: 0;
}

.wv-financial-institution__list-item--danger {
  margin-top: -1px;
  border-color: #d74242;
  border-top-width: 1px;
  background-color: #fbeaea;
}

.wv-financial-institution__account-details {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

.wv-financial-institution__account-details__account-name {
  margin: 0;
  display: block;
}

.wv-financial-institution__account-details__account-type:not(:last-child) {
  margin-right: 8px;
}

.wv-financial-institution__account-details__balance {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  white-space: nowrap;
}

.wv-financial-institution__account-details__balance-currency-code {
  margin-top: 0;
  margin-bottom: 0;
}

.wv-financial-institution__account-details__collapse-button {
  margin-left: 8px;
}

.wv-financial-institution__account-details__collapse-spacer {
  height: 24px;
  width: 32px;
  display: inline-block;
}

.wv-financial-institution__account-details__extended-content,
.wv-financial-institution__account-details__expandable-content {
  margin-top: 8px;
}

.wv-insights-box {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  border-radius: 8px;
  background: #f0f4fa;
}

@media (min-width: 568px) {
  .wv-insights-box {
    padding: 8px;
  }
}

@media (min-width: 1024px) {
  .wv-insights-box {
    padding: 0;
  }
}

.wv-insight,
.wv-insight--large {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  -webkit-flex-basis: 288px;
  flex-basis: 288px;
  padding: 16px;
  box-sizing: border-box;
}

@media (max-width: 568px) {
  .wv-insight:not(:first-child),
  .wv-insight--large:not(:first-child) {
    padding-top: 0;
  }
}

.wv-insight > .wv-insight__label,
.wv-insight--large > .wv-insight__label {
  margin: 0;
  display: -webkit-flex;
  display: flex;
}

.wv-insight > .wv-insight__label .wv-svg-icon,
.wv-insight--large > .wv-insight__label .wv-svg-icon {
  display: inherit;
  margin-left: 4px;
  cursor: pointer;
}

.wv-insight > .wv-insight__label > .wv-svg-icon,
.wv-insight--large > .wv-insight__label > .wv-svg-icon {
  bottom: 0;
}

.wv-insight > .wv-insight__label .wv-text,
.wv-insight--large > .wv-insight__label .wv-text {
  margin: 0;
}

.wv-insight .wv-insight__content,
.wv-insight--large .wv-insight__content {
  margin-top: 0;
}

@media (min-width: 1024px) {
  .wv-insight .wv-insight__content,
  .wv-insight--large .wv-insight__content {
    margin-top: 8px;
  }
}

.wv-insight .wv-insight__content__value,
.wv-insight--large .wv-insight__content__value {
  color: inherit;
}

.wv-insight .wv-insight__content__value--suffix,
.wv-insight--large .wv-insight__content__value--suffix {
  font-size: 14px;
  line-height: 1.4;
  color: inherit;
}

.wv-insight .wv-insight__content__secondary-value,
.wv-insight--large .wv-insight__content__secondary-value {
  font-size: 14px;
  line-height: 1.4;
}

@media (min-width: 568px) {
  .wv-insight,
  .wv-insight--large {
    padding: 8px;
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
    -webkit-flex-grow: 0;
    flex-grow: 0;
  }
}

@media (min-width: 648px) {
  .wv-insight,
  .wv-insight--large {
    -webkit-flex-basis: 33.3333%;
    flex-basis: 33.3333%;
  }
}

@media (min-width: 1024px) {
  .wv-insight,
  .wv-insight--large {
    padding: 24px;
    -webkit-flex-basis: auto;
    flex-basis: auto;
    -webkit-flex-grow: 1;
    flex-grow: 1;
  }
}

.wv-insight--large .wv-insight__content {
  margin-top: 0;
}

.wv-insight--large .wv-insight__content__value {
  font-size: 26px;
  line-height: 1.4;
  margin: 0;
}

@media (min-width: 480px) {
  .wv-insight--large .wv-insight__content__value {
    font-size: 33px;
  }
}

.wv-insight--highlight {
  background-color: #3c5b8b;
  color: white;
}

.wv-insight--highlight .wv-insight__label {
  color: inherit;
}

.wv-insight--highlight:first-child {
  border-radius: 8px 8px 0 0;
}

.wv-insight--highlight:last-child {
  border-radius: 0 0 8px 8px;
}

@media (max-width: 568px) {
  .wv-insight--highlight + .wv-insight {
    padding: 16px;
  }
}

@media (min-width: 568px) {
  .wv-insight--highlight {
    border-radius: 8px;
  }

  .wv-insight--highlight:first-child,
  .wv-insight--highlight:last-child {
    border-radius: 8px;
  }
}

@media (min-width: 1024px) {
  .wv-insight--highlight {
    border-radius: 0;
  }

  .wv-insight--highlight:first-child {
    border-radius: 8px 0 0 8px;
  }

  .wv-insight--highlight:last-child {
    border-radius: 0 8px 8px 0;
  }
}

.wv-insight--highlight .wv-button--secondary {
  color: white;
  border-color: white;
}

.wv-insight--highlight .wv-insight__content .wv-text--link {
  color: inherit;
  text-decoration: underline;
}

.wv-callout-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
}

@media (min-width: 568px) {
  .wv-callout-wrapper {
    -webkit-flex-flow: nowrap;
    flex-flow: nowrap;
  }
}

.wv-callout {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  border-radius: 8px;
  background: #f0f4fa;
  margin-top: 8px;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  padding: 12px 16px;
  display: flex;
  flex-flow: row wrap;
}

@media (min-width: 568px) {
  .wv-callout {
    margin-right: 8px;
  }

  .wv-callout:last-child {
    margin-right: 0;
  }
}

@media (min-width: 1024px) {
  .wv-callout {
    padding: 8px 24px;
  }
}

.wv-callout__title {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  width: 100%;
}

@media (min-width: 1024px) {
  .wv-callout__title {
    width: auto;
  }
}

.wv-callout__title--centered {
  -webkit-justify-content: center;
  justify-content: center;
}

@media (min-width: 480px) {
  .wv-callout__title--centered {
    -webkit-justify-content: left;
    justify-content: left;
  }
}

@media (min-width: 1024px) {
  .wv-callout__title--centered {
    width: auto;
  }
}

.wv-callout__value {
  margin: 8px 0;
  -webkit-flex: 1;
  flex: 1;
  color: inherit;
}

@media (min-width: 1024px) {
  .wv-callout__value {
    margin: 0 16px 0 0;
    text-align: right;
  }
}

.wv-callout__paragraph {
  margin: 16px 0;
  width: 100%;
  text-align: center;
  display: -webkit-flex;
  display: flex;
}

@media (min-width: 480px) {
  .wv-callout__paragraph {
    -webkit-flex: 1;
    flex: 1;
    margin: 8px 0 8px;
    width: auto;
    text-align: left;
  }

  .wv-callout__paragraph .wv-text {
    margin: 0 16px 0 0;
  }
}

@media (min-width: 1024px) {
  .wv-callout__paragraph {
    margin: 0;
  }

  .wv-callout__paragraph .wv-text {
    margin: 0 8px;
  }
}

.wv-callout .wv-text {
  -webkit-align-self: center;
  -ms-grid-row-align: center;
  align-self: center;
  margin: 0 8px;
}

.wv-callout .wv-button--secondary {
  color: #136acd;
  background: white;
  border-color: #136acd;
}

.wv-no-results--full-page,
.wv-no-results {
  text-align: center;
  width: 100%;
  max-width: 560px;
  margin: 0 auto;
}

.wv-no-results--full-page .wv-divider,
.wv-no-results .wv-divider {
  max-width: 448px;
}

.wv-no-results__illustration {
  max-height: 192px;
  max-width: 192px;
}

.wv-no-results--full-page .wv-no-results__pre-title {
  margin-bottom: 64px;
}

.wv-no-results--full-page .wv-no-results__body {
  margin-bottom: 24px;
}

@media (max-width: 480px) {
  .wv-no-results .wv-heading--section-title {
    margin-top: 24px;
  }
}

.wv-nav--secondary.wv-nav--secondary--horizontal + .wv-no-results,
.wv-nav--secondary.wv-nav--secondary--horizontal .wv-no-results {
  margin-top: 64px;
}

@media (max-width: 480px) {
  .wv-nav--secondary.wv-nav--secondary--horizontal + .wv-no-results,
  .wv-nav--secondary.wv-nav--secondary--horizontal .wv-no-results {
    margin-top: 32px;
  }
}

.wv-topbar--app,
.wv-topbar--minimal {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  box-sizing: border-box;
  margin: 0 auto;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
}

.wv-topbar--minimal {
  -webkit-justify-content: center;
  justify-content: center;
  height: 72px;
  padding-top: 48px;
}

@media only screen and (max-width: 768px) {
  .wv-topbar--minimal {
    height: 56px;
  }
}

.wv-topbar--app {
  background-color: white;
  color: #1c252c;
  border-bottom: 1px solid #d4dde3;
}

@media only screen and (max-width: 768px) {
  .wv-topbar__logo {
    text-align: center;
  }
}

@media only screen and (max-width: 480px) {
  .wv-topbar__logo .wv-logo {
    display: none;
  }
}

.wv-topbar__logo .wv-logo--mark {
  display: none;
}

@media only screen and (max-width: 480px) {
  .wv-topbar__logo .wv-logo--mark {
    display: inline-block;
  }
}

.wv-topbar__menu-toggle {
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  height: 100%;
}

.wv-topbar__menu-toggle__label {
  margin-left: 8px;
  color: #1c252c;
}

.wv-topbar__menu-toggle__icon {
  display: inline-block;
  width: 18px;
  height: 14px;
  position: relative;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.wv-topbar__menu-toggle__icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  border-radius: 2px;
  left: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
  background: #1c252c;
}

.wv-topbar__menu-toggle__icon span:nth-child(1) {
  top: 0;
}

.wv-topbar__menu-toggle__icon span:nth-child(2),
.wv-topbar__menu-toggle__icon span:nth-child(3) {
  top: 6px;
}

.wv-topbar__menu-toggle__icon span:nth-child(4) {
  top: 12px;
}

.wv-frame.is-open .wv-topbar__menu-toggle__icon span:nth-child(1) {
  top: 6px;
  width: 0;
  left: 50%;
}

.wv-frame.is-open .wv-topbar__menu-toggle__icon span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.wv-frame.is-open .wv-topbar__menu-toggle__icon span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.wv-frame.is-open .wv-topbar__menu-toggle__icon span:nth-child(4) {
  top: 6px;
  width: 0;
  left: 50%;
}

.wv-topbar--app {
  height: 73px;
}

.wv-topbar--app .wv-topbar__menu-toggle,
.wv-topbar--app .wv-topbar__business-name {
  padding-right: 12px;
  margin-right: 12px;
}

.wv-topbar--app .wv-topbar__menu-toggle {
  border-right: 1px solid #d4dde3;
}

.wv-topbar--app .wv-topbar__business-name {
  color: #1c252c;
  transition: opacity 0.2s;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media (max-width: 800px) {
  .wv-topbar--app .wv-topbar__business-name {
    padding-right: 220px;
  }
}

@media (max-width: 600px) {
  .wv-topbar--app .wv-topbar__business-name {
    padding-right: 110px;
  }
}

.wv-frame--app.is-open .wv-topbar__business-name {
  opacity: 0;
}

.wv-topbar--minimal.wv-topbar--public {
  padding-top: 0;
  border-bottom: 0;
}

.wv-logo,
.wv-logo--light,
.wv-logo--mark {
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  text-indent: -500%;
  background: url('assets/icons/tradext-logo-72.png') left top no-repeat;
  background-size: cover;
}
/*
.wv-logo--mono,
.wv-logo--mono-light {
    display: inline-block;
    overflow: hidden;
    vertical-align: middle;
    text-indent: -500%;
    background: url("images/wave-logo-mono.svg") left top no-repeat;
    background-size: auto 200%
}

.wv-logo--powered-by {
    display: inline-block;
    overflow: hidden;
    vertical-align: middle;
    text-indent: -500%;
    background: url("images/wave-logo--powered-by.svg") left top no-repeat;
    background-size: auto 100%
}

.wv-logo--powered-by--mono {
    display: inline-block;
    overflow: hidden;
    vertical-align: middle;
    text-indent: -500%;
    background: url("images/wave-logo--powered-by--mono.svg") left top no-repeat;
    background-size: auto 100%
}
 */
.wv-logo--light,
.wv-logo--mono-light {
  background-position: left bottom;
}

.wv-logo.wv-logo--small,
.wv-logo--light.wv-logo--small,
.wv-logo--mono.wv-logo--small,
.wv-logo--mono-light.wv-logo--small {
  width: 60.16px;
  height: 16px;
}

.wv-logo--mark.wv-logo--small {
  width: 18.56px;
  height: 16px;
}

.wv-logo--powered-by.wv-logo--small {
  width: 132.8px;
  height: 16px;
}

.wv-logo--powered-by--mono.wv-logo--small {
  width: 123.84px;
  height: 16px;
}

.wv-logo,
.wv-logo--light,
.wv-logo--mono,
.wv-logo--mono-light {
  width: 120.32px;
  height: 32px;
}

.wv-logo--mark {
  width: 37.12px;
  height: 32px;
}

.wv-logo--powered-by {
  width: 265.6px;
  height: 32px;
}

.wv-logo--powered-by--mono {
  width: 247.68px;
  height: 32px;
}

.wv-logo.wv-logo--large,
.wv-logo--light.wv-logo--large,
.wv-logo--mono.wv-logo--large,
.wv-logo--mono-light.wv-logo--large {
  width: 180.48px;
  height: 48px;
}

.wv-logo--mark.wv-logo--large {
  width: 55.68px;
  height: 48px;
}

.wv-logo--powered-by.wv-logo--large {
  width: 398.4px;
  height: 48px;
}

@media (max-width: 550px) {
  .wv-logo--powered-by.wv-logo--large {
    width: 265.6px;
    height: 32px;
  }
}

.wv-logo--powered-by--mono.wv-logo--large {
  width: 371.52px;
  height: 48px;
}

@media (max-width: 550px) {
  .wv-logo--powered-by--mono.wv-logo--large {
    width: 247.68px;
    height: 32px;
  }
}

.wv-business-switcher {
  width: 100%;
}

.wv-business-switcher__toggle {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  box-sizing: border-box;
  height: 56px;
  padding: 0 16px;
  margin: 8px;
  color: #1c252c;
  background: #d7e0ea;
  cursor: pointer;
  border-radius: 500px;
}

.wv-business-switcher__toggle:hover {
  background: #d0dbe6;
}

.wv-business-switcher__toggle__logo {
  width: 36px;
}

.wv-business-switcher__toggle__logo .wv-logo--mark {
  width: 36px;
  height: 36px;
}

.wv-business-switcher__toggle__info {
  width: 80%;
  box-sizing: border-box;
  padding: 0 4px 0 12px;
}

.wv-business-switcher__toggle__info__business {
  font-size: 16px;
  line-height: 1.4;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media (min-width: 480px) {
  .wv-business-switcher__toggle__info__business {
    font-size: 16px;
  }
}

.wv-business-switcher__toggle__info__user {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  opacity: 0.6;
  padding: 4px 0;
}

.wv-business-switcher__toggle__caret {
  font-size: 14px;
  opacity: 0.4;
  -webkit-transform: rotateZ(-90deg);
  transform: rotateZ(-90deg);
  transition: all 0.2s;
  color: #4d6575;
}

.wv-business-switcher__toggle__caret.wv-svg-icon {
  width: 16px;
  height: 16px;
}

:hover > .wv-business-switcher__toggle__caret {
  -webkit-transform: translateX(3px) rotateZ(-90deg);
  transform: translateX(3px) rotateZ(-90deg);
}

.wv-business-switcher.is-open .wv-business-switcher__toggle__caret {
  -webkit-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
}

.wv-business-switcher__panel {
  /*   opacity: 0;
  visibility: hidden;
 */
  position: absolute;
  top: 8px;
  left: 0;
  z-index: 1015;
  min-width: 400px !important;
  max-width: 600px !important;
  border-radius: 24px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.02), 0 4px 36px 4px rgba(0, 0, 0, 0.1) !important;
  background: #fff;
  transition: left 0.3s ease, opacity 0.3s ease;
}

.wv-business-switcher.is-open .wv-business-switcher__panel {
  opacity: 1;
  visibility: visible;
  left: 8px;
}

@media only screen and (max-width: 416px) {
  .wv-business-switcher.is-open .wv-business-switcher__panel {
    min-width: unset;
    max-width: unset;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}

.wv-business-switcher__panel__header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  height: 56px;
  padding: 0;
  margin: 0 16px;
  background: #fff;
  border-radius: 24px 24px 0 0;
}

@media only screen and (max-width: 416px) {
  .wv-business-switcher__panel__header {
    border-radius: 0;
    margin: 8px;
  }
}

.wv-business-switcher__panel__header__title {
  color: #4d6575;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  margin-left: 12px;
}

.wv-business-switcher__panel__header__close {
  position: relative;
}

.wv-business-switcher__panel__header__close::before,
.wv-business-switcher__panel__header__close::after {
  opacity: 0.5;
}

.wv-business-switcher__panel__header__close:hover::before,
.wv-business-switcher__panel__header__close:hover::after {
  opacity: 1;
}

.wv-business-switcher__panel__body {
  padding: 0 8px 8px;
  box-sizing: border-box;
}

@media only screen and (max-width: 416px) {
  .wv-business-switcher__panel__body {
    width: 100%;
  }
}

.wv-business-switcher__panel__body__section {
  margin: 0 8px;
  padding: 0 8px;
  border-bottom: 1px solid #d4dde3;
  box-sizing: border-box;
}

@media only screen and (max-width: 416px) {
  .wv-business-switcher__panel__body__section {
    width: 100%;
  }
}

.wv-business-switcher__panel__body__section:last-child {
  border-bottom: none;
}

.wv-business-switcher__business-menu {
  list-style: none;
  width: 100%;
  margin: 0 0 -8px;
  padding: 0;
  max-height: 315px;
  overflow-y: auto;
}

@media only screen and (min-height: 780px) {
  .wv-business-switcher__business-menu {
    max-height: 460px;
  }
}

.wv-business-switcher__business-menu::-webkit-scrollbar {
  width: 8px;
  margin: 5px;
}

.wv-business-switcher__business-menu::-webkit-scrollbar-thumb {
  width: 6px;
  margin: 2px;
  border-radius: 10px;
  background-color: #8aa2b2;
}

.wv-business-switcher__business-menu__item {
  position: relative;
  vertical-align: middle;
  margin: 0;
  border-radius: 4px;
}

.wv-business-switcher__business-menu__item.is-group-option
  .wv-dropdown__menu__link {
  padding-left: 24px;
}

.wv-business-switcher__business-menu__item.is-current {
  background-color: #f0f4fa;
}

.wv-business-switcher__business-menu__item__link {
  display: block;
  padding: 4px 16px;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  outline: none;
  color: inherit;
  background-color: transparent;
  padding: 8px 12px;
  border-radius: 4px;
  text-overflow: initial;
}

.is-active > .wv-business-switcher__business-menu__item__link {
  cursor: default;
}

.is-disabled > .wv-business-switcher__business-menu__item__link {
  opacity: 0.3;
  cursor: not-allowed;
  background-color: transparent;
}

.wv-business-switcher__business-menu__item__link [class*='wv-icon'] {
  opacity: 0.5;
  color: inherit;
}

:hover > .wv-business-switcher__business-menu__item__link {
  background-color: #f0f4fa;
}

.wv-business-switcher__business-menu__item__label {
  font-weight: normal;
  max-width: 500px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}

.wv-business-switcher__business-menu__item.is-collaborator
  .wv-business-switcher__business-menu__item__label {
  max-width: 432px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.wv-business-switcher__business-menu__item.is-collaborator.is-current
  .wv-business-switcher__business-menu__item__label {
  max-width: 400px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.wv-business-switcher__business-menu__item__badge {
  display: inline-block;
  vertical-align: middle;
  margin-right: 24px;
}

.wv-business-switcher__business-menu__item .wv-icon--check,
.wv-business-switcher__business-menu__item
  .is-complete
  .wv-workflow__task__number:empty::before,
.is-complete
  .wv-business-switcher__business-menu__item
  .wv-workflow__task__number:empty::before,
.wv-business-switcher__business-menu__item
  .is-complete
  .wv-wizard__task__number:empty::before,
.is-complete
  .wv-business-switcher__business-menu__item
  .wv-wizard__task__number:empty::before,
.wv-business-switcher__business-menu__item .wv-svg-icon {
  vertical-align: middle;
  opacity: 1;
  color: #23c770;
  position: absolute;
  top: 15px;
  right: 18px;
}

.wv-business-switcher__business-menu__item .wv-svg-icon {
  top: 13px;
}

.wv-business-switcher__business-menu__add {
  margin: 16px 0;
}

.wv-business-switcher__user-email {
  overflow-wrap: break-word;
  font-weight: bold;
}

.wv-business-switcher__context-menu {
  list-style: none;
  padding: 0;
}

.wv-business-switcher__context-menu__item {
  margin-bottom: 8px;
}

.wv-business-switcher__context-menu__item :empty {
  margin-bottom: 16px;
}

.wv-business-switcher__context-menu__item__icon {
  display: inline-block;
  color: #1c252c;
  margin-right: 4px;
  margin-left: -3px;
  vertical-align: middle;
  position: relative;
  bottom: 1px;
}

.wv-business-switcher__legal__link {
  text-decoration: none;
}

.wv-business-switcher__legal__link:hover {
  text-decoration: underline;
}

.wv-business-switcher__legal__separator {
  opacity: 0.3;
  margin: 0 4px;
}

.wv-status-page,
.wv-status-page--condensed {
  text-align: center;
}

.wv-status-page > .wv-box,
.wv-status-page--condensed > .wv-box {
  padding: 20px 50px;
  border: none;
}

.wv-status-page > .wv-box .wv-box,
.wv-status-page--condensed > .wv-box .wv-box {
  box-shadow: none;
  border: none;
  margin-top: 40px;
}

.wv-status-page > .wv-box .wv-box .wv-text--body,
.wv-status-page--condensed > .wv-box .wv-box .wv-text--body {
  text-align: left;
}

.wv-status-page .wv-status-page__cta,
.wv-status-page--condensed .wv-status-page__cta {
  margin-top: 32px;
  margin-bottom: 40px;
}

.wv-status-page--condensed > .wv-box {
  padding: 0 30px;
  margin: 0;
  box-shadow: none;
}

.wv-status-page--condensed > .wv-box .wv-box {
  margin-right: 30px;
  margin-left: 30px;
}

.wv-status-page__icon {
  padding-top: 32px;
}

.wv-wizard__content__task .wv-status-page > .wv-box {
  margin: 0;
}

.wv-wizard__content__task .wv-status-page .wv-status-page__icon {
  padding-top: 0;
}

.wv-cta-page .wv-button--primary,
.wv-landing-page .wv-button--primary,
.wv-cta-page .wv-button--submit,
.wv-landing-page .wv-button--submit {
  background-color: #30dfe8;
  color: #1c252c;
}

.wv-cta-page .wv-button--primary:hover:not(.is-disabled),
.wv-landing-page .wv-button--primary:hover:not(.is-disabled),
.wv-cta-page .wv-button--primary:focus:not(.is-disabled),
.wv-landing-page .wv-button--primary:focus:not(.is-disabled),
.wv-cta-page .wv-button--submit:hover:not(.is-disabled),
.wv-landing-page .wv-button--submit:hover:not(.is-disabled),
.wv-cta-page .wv-button--submit:focus:not(.is-disabled),
.wv-landing-page .wv-button--submit:focus:not(.is-disabled) {
  background: #17c5cf;
}

.wv-footer--app .wv-footer__help-toggle {
  cursor: pointer;
  color: #4d6575;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 6px 0;
  box-sizing: border-box;
  border-radius: 20px;
  border: none;
  background: #d7e0ea;
  font-weight: 600;
  font-family: inherit;
  font-size: inherit;
}

.wv-footer--app .wv-footer__help-toggle:hover {
  background: #b3e4f5;
  color: #1c252c;
}

.wv-footer--app .wv-footer__help-toggle:active {
  background: #a5dff3;
}

.wv-footer--app .wv-footer__help-toggle:focus {
  background: #b3e4f5;
  box-shadow: 0 0 0 3px #ceeef8;
  outline: 0;
}

@media (max-width: 1234px) {
  .wv-footer--app .wv-footer__help-toggle {
    background: none;
    border: 2px solid #718fa2;
    font-size: 14px;
    color: #1c252c;
    height: 30px;
    padding: 4px 12px;
  }

  .wv-footer--app .wv-footer__help-toggle:hover {
    background: none;
    border-color: #136acd;
    color: #136acd;
  }
}

@media (max-width: 600px) {
  .wv-footer--app .wv-footer__help-toggle {
    width: 40px !important;
    height: 40px !important;
    padding: 0;
  }

  .wv-footer--app .wv-footer__help-toggle .wv-footer__help__text,
  .wv-footer--app .wv-footer__help-toggle .wv-footer__chat__toggle__text {
    display: none;
  }
}

.wv-footer--app {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 16px 0;
  margin: 0 16px;
  color: #4d6575;
  background: #ebeff4;
  border-top: 1px solid #d4dde3;
  transition: right 0.2s ease-in-out;
}

.wv-footer--app .wv-footer__help-toggle {
  width: 88px;
}

.wv-footer--app .wv-footer__help-toggle .wv-footer__help__icon {
  margin-right: 6px;
}

@media (max-width: 1234px) {
  .wv-footer--app {
    position: fixed;
    top: 0;
    right: 0;
    background: none;
    border: 0;
    z-index: 20;
    height: 73px;
    padding: 0;
  }

  .wv-frame--app.is-open .wv-footer--app {
    right: -250px;
  }
}

@media (max-width: 600px) {
  .wv-footer--app .wv-footer__help-toggle .wv-footer__help__icon {
    margin-right: 0;
  }

  .wv-footer--app .wv-footer__help__toggle__icon {
    display: block;
  }

  .wv-footer--app .wv-footer__help__toggle__status {
    position: absolute;
    top: -3px;
    right: -2px;
    margin: 0;
  }
}

.wv-card--bank--affinity,
.wv-card--bank--ally,
.wv-card--bank--assiniboine,
.wv-card--bank--bank-of-america,
.wv-card--bank--bb-and-t,
.wv-card--bank--bmo,
.wv-card--bank--capital-one,
.wv-card--bank--chase,
.wv-card--bank--cibc,
.wv-card--bank--citi,
.wv-card--bank--citizens,
.wv-card--bank--coast-capital,
.wv-card--bank--conexus,
.wv-card--bank--connect-first,
.wv-card--bank--desjardins,
.wv-card--bank--equitable,
.wv-card--bank--fifth-third,
.wv-card--bank--first-republic,
.wv-card--bank--first-west,
.wv-card--bank--laurentien,
.wv-card--bank--meridian,
.wv-card--bank--national-bank,
.wv-card--bank--no-bank,
.wv-card--bank--pc-financial,
.wv-card--bank--pnc,
.wv-card--bank--rbc,
.wv-card--bank--regions-financial,
.wv-card--bank--scotiabank,
.wv-card--bank--servus,
.wv-card--bank--silicon-valley,
.wv-card--bank--simplii,
.wv-card--bank--steinbach,
.wv-card--bank--suntrust,
.wv-card--bank--tangerine,
.wv-card--bank--td,
.wv-card--bank--us-bank,
.wv-card--bank--usaa,
.wv-card--bank--vancity,
.wv-card--bank--wells-fargo {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: wrap;
  flex-flow: wrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-top: 40px;
  padding: 16px 16px 8px 16px;
  border-radius: 8px;
  border: 1px solid #ecf0f3;
  background: #fff;
  box-shadow: 0 3px 6px -1px rgba(77, 101, 117, 0.25);
}

.wv-card--bank--affinity,
.wv-card--bank--ally,
.wv-card--bank--assiniboine,
.wv-card--bank--bank-of-america,
.wv-card--bank--bb-and-t,
.wv-card--bank--bmo,
.wv-card--bank--capital-one,
.wv-card--bank--chase,
.wv-card--bank--cibc,
.wv-card--bank--citi,
.wv-card--bank--citizens,
.wv-card--bank--coast-capital,
.wv-card--bank--conexus,
.wv-card--bank--connect-first,
.wv-card--bank--desjardins,
.wv-card--bank--equitable,
.wv-card--bank--fifth-third,
.wv-card--bank--first-republic,
.wv-card--bank--first-west,
.wv-card--bank--laurentien,
.wv-card--bank--meridian,
.wv-card--bank--national-bank,
.wv-card--bank--no-bank,
.wv-card--bank--pc-financial,
.wv-card--bank--pnc,
.wv-card--bank--rbc,
.wv-card--bank--regions-financial,
.wv-card--bank--scotiabank,
.wv-card--bank--servus,
.wv-card--bank--silicon-valley,
.wv-card--bank--simplii,
.wv-card--bank--steinbach,
.wv-card--bank--suntrust,
.wv-card--bank--tangerine,
.wv-card--bank--td,
.wv-card--bank--us-bank,
.wv-card--bank--usaa,
.wv-card--bank--vancity,
.wv-card--bank--wells-fargo {
  margin: 8px 0;
  padding: 8px;
}

.wv-card--bank--affinity:hover,
.wv-card--bank--ally:hover,
.wv-card--bank--assiniboine:hover,
.wv-card--bank--bank-of-america:hover,
.wv-card--bank--bb-and-t:hover,
.wv-card--bank--bmo:hover,
.wv-card--bank--capital-one:hover,
.wv-card--bank--chase:hover,
.wv-card--bank--cibc:hover,
.wv-card--bank--citi:hover,
.wv-card--bank--citizens:hover,
.wv-card--bank--coast-capital:hover,
.wv-card--bank--conexus:hover,
.wv-card--bank--connect-first:hover,
.wv-card--bank--desjardins:hover,
.wv-card--bank--equitable:hover,
.wv-card--bank--fifth-third:hover,
.wv-card--bank--first-republic:hover,
.wv-card--bank--first-west:hover,
.wv-card--bank--laurentien:hover,
.wv-card--bank--meridian:hover,
.wv-card--bank--national-bank:hover,
.wv-card--bank--no-bank:hover,
.wv-card--bank--pc-financial:hover,
.wv-card--bank--pnc:hover,
.wv-card--bank--rbc:hover,
.wv-card--bank--regions-financial:hover,
.wv-card--bank--scotiabank:hover,
.wv-card--bank--servus:hover,
.wv-card--bank--silicon-valley:hover,
.wv-card--bank--simplii:hover,
.wv-card--bank--steinbach:hover,
.wv-card--bank--suntrust:hover,
.wv-card--bank--tangerine:hover,
.wv-card--bank--td:hover,
.wv-card--bank--us-bank:hover,
.wv-card--bank--usaa:hover,
.wv-card--bank--vancity:hover,
.wv-card--bank--wells-fargo:hover {
  border-color: #b2c2cd;
  cursor: pointer;
}

.wv-payment-card {
  position: relative;
  width: 240px;
  height: 152px;
  padding: 16px;
  margin: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgba(77, 101, 117, 0.3);
}

/* .wv-payment-card.wave-card {
    background: url("images/wave-card-details.svg") no-repeat 0 0/contain, linear-gradient(235deg, #136acd -1.56%, #7f309c 104.49%);
    border-bottom: solid 2px #136acd
}
 */
.wv-payment-card .wv-payment-card__logo {
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  right: 16px;
  bottom: 16px;
}

/* .wv-payment-card.mastercard {
    background: url("images/cc--background-lines.png") center center no-repeat, linear-gradient(to bottom left, #155396, #8d5fbd)
}

.wv-payment-card.mastercard .wv-payment-card__logo {
    background-image: url("images/icons-payment-card-logos/payment-card-logo--mastercard.svg");
    width: 40px;
    height: 26px
}

.wv-payment-card.visa {
    background: url("images/cc--background-lines.png") center center no-repeat, linear-gradient(to bottom left, #008ecd, #aa884b)
}

.wv-payment-card.visa .wv-payment-card__logo {
    background-image: url("images/cc-card-icon--visa.png");
    width: 50px;
    height: 16px
}

.wv-payment-card.plus-debit {
    background: url("images/cc--background-lines.png") center center no-repeat, linear-gradient(to bottom left, #008ecd, #aa884b)
}

.wv-payment-card.plus-debit .wv-payment-card__logo {
    background-image: url("images/icons-payment-card-logos/payment-card-logo--plus-debit.svg");
    width: 46px;
    height: 23px
}

.wv-payment-card.amex {
    background: url("images/cc--background-lines.png") center center no-repeat, linear-gradient(to bottom left, #d5a124, #c8462e)
}

.wv-payment-card.amex .wv-payment-card__logo {
    background-image: url("images/cc-card-icon--amex.png");
    width: 40px;
    height: 40px
}

.wv-payment-card.other {
    background: url("images/cc--background-lines.png") center center no-repeat, linear-gradient(to bottom left, #acb3bd, #5582d4)
}

.wv-payment-card.other .wv-payment-card__logo {
    width: 50px;
    height: 30px
}

.wv-payment-card.other.discover .wv-payment-card__logo {
    background-image: url("images/cc-icon--discover.png")
}

.wv-payment-card.other.jcb .wv-payment-card__logo {
    background-image: url("images/cc-icon--jcb.png")
}

.wv-payment-card.other.diners .wv-payment-card__logo {
    background-image: url("images/cc-icon--diners.png")
}
 */
.wv-payment-card .wv-payment-card__details {
  color: white;
  position: absolute;
  left: 16px;
  right: 16px;
  bottom: 16px;
  font-size: 20px;
  line-height: 34px;
}

.wv-payment-card .wv-payment-card__details .wv-payment-card__number {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.wv-payment-card
  .wv-payment-card__details
  .wv-payment-card__number
  .obfuscated {
  font-size: 25px;
  margin-top: 5px;
  vertical-align: text-top;
}

.wv-payment-card .wv-payment-card__details .wv-payment-card__name-date {
  font-size: 16px;
  line-height: 1.25;
}

.wv-payment-card.bank {
  background: linear-gradient(to bottom right, #328bcd, #0166b1);
}

.wv-payment-card.bank .wv-payment-card__bank-details {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

/* .wv-payment-card.bank .wv-payment-card__bank-details .wv-payment-card__logo {
    position: inherit;
    width: 56px;
    height: 56px;
    background: white url("images/icon--default-bank.png") center center no-repeat;
    background-size: 40px 40px;
    border-radius: 3px;
    -webkit-flex-shrink: 0;
    flex-shrink: 0
}
 */
.wv-payment-card.bank .wv-payment-card__bank-details .wv-payment-card__details {
  position: relative;
  right: inherit;
  left: inherit;
  bottom: inherit;
  margin-left: 10px;
  font-size: 16px;
  line-height: 1.25;
}

.wv-payment-card.bank
  .wv-payment-card__bank-details
  .wv-payment-card__details
  .wv-payment-card__details__name {
  display: block;
  display: -webkit-box;
  height: 40px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wv-payment-card.bank .wv-payment-card__transfer-link {
  position: absolute;
  bottom: 16px;
  right: 16px;
  font-size: 13px;
  color: white;
  opacity: 0.7;
  text-decoration: underline;
  font-weight: bold;
}

.wv-payment-card.bank .wv-payment-card__transfer-link:hover {
  opacity: 1;
}

.wv-payment-card.bank.bank--small {
  height: 100px;
}

.wv-card--bank--affinity {
  background: white;
}

.wv-card--bank--affinity.wv-card--bank--branded {
  background-color: #113467;
  border: 0;
}

.wv-card--bank--affinity.wv-card--bank--branded:hover {
  background-color: #12376e;
}

.wv-card--bank--ally {
  background: white;
}

.wv-card--bank--ally.wv-card--bank--branded {
  background-color: #65125f;
  border: 0;
}

.wv-card--bank--ally.wv-card--bank--branded:hover {
  background-color: #6b1365;
}

.wv-card--bank--assiniboine {
  background: white;
}

.wv-card--bank--assiniboine.wv-card--bank--branded {
  background-color: #113467;
  border: 0;
}

.wv-card--bank--assiniboine.wv-card--bank--branded:hover {
  background-color: #12376e;
}

.wv-card--bank--bank-of-america {
  background: white;
}

.wv-card--bank--bank-of-america.wv-card--bank--branded {
  background-color: #c0242b;
  border: 0;
}

.wv-card--bank--bank-of-america.wv-card--bank--branded:hover {
  background-color: #c6252c;
}

.wv-card--bank--bb-and-t {
  background: white;
}

.wv-card--bank--bb-and-t.wv-card--bank--branded {
  background-color: #970534;
  border: 0;
}

.wv-card--bank--bb-and-t.wv-card--bank--branded:hover {
  background-color: #9e0537;
}

.wv-card--bank--bmo {
  background: white;
}

.wv-card--bank--bmo.wv-card--bank--branded {
  background-color: #1279be;
  border: 0;
}

.wv-card--bank--bmo.wv-card--bank--branded:hover {
  background-color: #137dc5;
}

.wv-card--bank--capital-one {
  background: white;
}

.wv-card--bank--capital-one.wv-card--bank--branded {
  background-color: #075386;
  border: 0;
}

.wv-card--bank--capital-one.wv-card--bank--branded:hover {
  background-color: #07588d;
}

.wv-card--bank--chase {
  background: white;
}

.wv-card--bank--chase.wv-card--bank--branded {
  background-color: #1970a9;
  border: 0;
}

.wv-card--bank--chase.wv-card--bank--branded:hover {
  background-color: #1a74b0;
}

.wv-card--bank--cibc {
  background: white;
}

.wv-card--bank--cibc.wv-card--bank--branded {
  background-color: #9b262c;
  border: 0;
}

.wv-card--bank--cibc.wv-card--bank--branded:hover {
  background-color: #a1282e;
}

.wv-card--bank--citi {
  background: white;
}

.wv-card--bank--citi.wv-card--bank--branded {
  background-color: #135b8e;
  border: 0;
}

.wv-card--bank--citi.wv-card--bank--branded:hover {
  background-color: #145f95;
}

.wv-card--bank--citizens {
  background: white;
}

.wv-card--bank--citizens.wv-card--bank--branded {
  background-color: #168968;
  border: 0;
}

.wv-card--bank--citizens.wv-card--bank--branded:hover {
  background-color: #17906d;
}

.wv-card--bank--coast-capital {
  background: white;
}

.wv-card--bank--coast-capital.wv-card--bank--branded {
  background-color: #1e78c3;
  border: 0;
}

.wv-card--bank--coast-capital.wv-card--bank--branded:hover {
  background-color: #1f7cca;
}

.wv-card--bank--conexus {
  background: white;
}

.wv-card--bank--conexus.wv-card--bank--branded {
  background-color: #138ca7;
  border: 0;
}

.wv-card--bank--conexus.wv-card--bank--branded:hover {
  background-color: #1492ae;
}

.wv-card--bank--connect-first {
  background: white;
}

.wv-card--bank--connect-first.wv-card--bank--branded {
  background-color: #f08b31;
  border: 0;
}

.wv-card--bank--connect-first.wv-card--bank--branded:hover {
  background-color: #f18f38;
}

.wv-card--bank--desjardins {
  background: white;
}

.wv-card--bank--desjardins.wv-card--bank--branded {
  background-color: #00874e;
  border: 0;
}

.wv-card--bank--desjardins.wv-card--bank--branded:hover {
  background-color: #008f52;
}

.wv-card--bank--equitable {
  background: white;
}

.wv-card--bank--equitable.wv-card--bank--branded {
  background-color: #bf923b;
  border: 0;
}

.wv-card--bank--equitable.wv-card--bank--branded:hover {
  background-color: #c3963e;
}

.wv-card--bank--fifth-third {
  background: white;
}

.wv-card--bank--fifth-third.wv-card--bank--branded {
  background-color: #213e7e;
  border: 0;
}

.wv-card--bank--fifth-third.wv-card--bank--branded:hover {
  background-color: #234184;
}

.wv-card--bank--first-republic {
  background: white;
}

.wv-card--bank--first-republic.wv-card--bank--branded {
  background-color: #b7903c;
  border: 0;
}

.wv-card--bank--first-republic.wv-card--bank--branded:hover {
  background-color: #bd953e;
}

.wv-card--bank--first-west {
  background: white;
}

.wv-card--bank--first-west.wv-card--bank--branded {
  background-color: #189ad2;
  border: 0;
}

.wv-card--bank--first-west.wv-card--bank--branded:hover {
  background-color: #199fd9;
}

.wv-card--bank--laurentien {
  background: white;
}

.wv-card--bank--laurentien.wv-card--bank--branded {
  background-color: #0c5f9b;
  border: 0;
}

.wv-card--bank--laurentien.wv-card--bank--branded:hover {
  background-color: #0d63a2;
}

.wv-card--bank--meridian {
  background: white;
}

.wv-card--bank--meridian.wv-card--bank--branded {
  background-color: #517489;
  border: 0;
}

.wv-card--bank--meridian.wv-card--bank--branded:hover {
  background-color: #54788e;
}

.wv-card--bank--national-bank {
  background: white;
}

.wv-card--bank--national-bank.wv-card--bank--branded {
  background-color: #e2222c;
  border: 0;
}

.wv-card--bank--national-bank.wv-card--bank--branded:hover {
  background-color: #e32932;
}

.wv-card--bank--no-bank {
  background: white;
}

.wv-card--bank--no-bank.wv-card--bank--branded {
  background-color: #5c6d75;
  border: 0;
}

.wv-card--bank--no-bank.wv-card--bank--branded:hover {
  background-color: #5f7179;
}

.wv-card--bank--pc-financial {
  background: white;
}

.wv-card--bank--pc-financial.wv-card--bank--branded {
  background-color: #ee2e24;
  border: 0;
}

.wv-card--bank--pc-financial.wv-card--bank--branded:hover {
  background-color: #ef352b;
}

.wv-card--bank--pnc {
  background: white;
}

.wv-card--bank--pnc.wv-card--bank--branded {
  background-color: #f58025;
  border: 0;
}

.wv-card--bank--pnc.wv-card--bank--branded:hover {
  background-color: #f5842c;
}

.wv-card--bank--rbc {
  background: white;
}

.wv-card--bank--rbc.wv-card--bank--branded {
  background-color: #095892;
  border: 0;
}

.wv-card--bank--rbc.wv-card--bank--branded:hover {
  background-color: #095c99;
}

.wv-card--bank--regions-financial {
  background: white;
}

.wv-card--bank--regions-financial.wv-card--bank--branded {
  background-color: #8dc548;
  border: 0;
}

.wv-card--bank--regions-financial.wv-card--bank--branded:hover {
  background-color: #91c74e;
}

.wv-card--bank--scotiabank {
  background: white;
}

.wv-card--bank--scotiabank.wv-card--bank--branded {
  background-color: #eb2035;
  border: 0;
}

.wv-card--bank--scotiabank.wv-card--bank--branded:hover {
  background-color: #ec273b;
}

.wv-card--bank--servus {
  background: white;
}

.wv-card--bank--servus.wv-card--bank--branded {
  background-color: #6eb246;
  border: 0;
}

.wv-card--bank--servus.wv-card--bank--branded:hover {
  background-color: #71b748;
}

.wv-card--bank--silicon-valley {
  background: white;
}

.wv-card--bank--silicon-valley.wv-card--bank--branded {
  background-color: #1796d8;
  border: 0;
}

.wv-card--bank--silicon-valley.wv-card--bank--branded:hover {
  background-color: #189bdf;
}

.wv-card--bank--simplii {
  background: white;
}

.wv-card--bank--simplii.wv-card--bank--branded {
  background-color: #222944;
  border: 0;
}

.wv-card--bank--simplii.wv-card--bank--branded:hover {
  background-color: #252c49;
}

.wv-card--bank--steinbach {
  background: white;
}

.wv-card--bank--steinbach.wv-card--bank--branded {
  background-color: #b82c21;
  border: 0;
}

.wv-card--bank--steinbach.wv-card--bank--branded:hover {
  background-color: #be2e22;
}

.wv-card--bank--suntrust {
  background: white;
}

.wv-card--bank--suntrust.wv-card--bank--branded {
  background-color: #05477d;
  border: 0;
}

.wv-card--bank--suntrust.wv-card--bank--branded:hover {
  background-color: #054b84;
}

.wv-card--bank--tangerine {
  background: white;
}

.wv-card--bank--tangerine.wv-card--bank--branded {
  background-color: #f38435;
  border: 0;
}

.wv-card--bank--tangerine.wv-card--bank--branded:hover {
  background-color: #f3883c;
}

.wv-card--bank--td {
  background: white;
}

.wv-card--bank--td.wv-card--bank--branded {
  background-color: #27a427;
  border: 0;
}

.wv-card--bank--td.wv-card--bank--branded:hover {
  background-color: #28aa28;
}

.wv-card--bank--us-bank {
  background: white;
}

.wv-card--bank--us-bank.wv-card--bank--branded {
  background-color: #c94552;
  border: 0;
}

.wv-card--bank--us-bank.wv-card--bank--branded:hover {
  background-color: #cb4b58;
}

.wv-card--bank--usaa {
  background: white;
}

.wv-card--bank--usaa.wv-card--bank--branded {
  background-color: #02324e;
  border: 0;
}

.wv-card--bank--usaa.wv-card--bank--branded:hover {
  background-color: #023755;
}

.wv-card--bank--vancity {
  background: white;
}

.wv-card--bank--vancity.wv-card--bank--branded {
  background-color: #ed3138;
  border: 0;
}

.wv-card--bank--vancity.wv-card--bank--branded:hover {
  background-color: #ee383f;
}

.wv-card--bank--wells-fargo {
  background: white;
}

.wv-card--bank--wells-fargo.wv-card--bank--branded {
  background-color: #cb1718;
  border: 0;
}

.wv-card--bank--wells-fargo.wv-card--bank--branded:hover {
  background-color: #d21819;
}

.wv-card--bank__logo {
  -webkit-align-self: flex-start;
  align-self: flex-start;
  margin-right: 16px;
}

.wv-card--bank__title {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  -webkit-align-self: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.wv-card--bank--branded .wv-card--bank__title {
  color: white;
  font-weight: normal;
}

.wv-box {
  display: block;
  padding: 12px;
  margin-bottom: 12px;
  border-radius: 8px;
  border: 1px solid #b2c2cd;
  background-color: #fff;
}

.wv-box.is-highlighted {
  box-shadow: 0 8px 32px rgba(77, 101, 117, 0.35);
  border-radius: 12px;
  border-color: transparent;
}

.wv-box.is-active {
  background-color: #e6f1ff;
  border-width: 0;
  box-shadow: none;
}

.wv-box__content > :first-child {
  margin-top: 0;
}

.wv-box__content > :last-child {
  margin-bottom: 0;
}

.wv-box--gray {
  display: block;
  padding: 12px;
  margin-bottom: 12px;
  border-radius: 8px;
  border: 1px solid #b2c2cd;
  background-color: #fff;
  background: #ecf0f3;
  box-shadow: none;
  border: none;
}

.wv-box.wv-box--small,
.wv-box--gray.wv-box--small {
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 4px;
}

.wv-box.wv-box--large,
.wv-box--gray.wv-box--large {
  padding: 16px;
  margin-bottom: 16px;
}

.wv-box.wv-box--xlarge,
.wv-box--gray.wv-box--xlarge {
  padding: 24px;
  margin-bottom: 24px;
}

.wv-button-group,
.wv-button-wrapper {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
  align-items: center;
  margin: -4px;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.wv-button-group > .wv-button--link,
.wv-button-wrapper > .wv-button--link {
  border-radius: 500px;
  padding: 9px 20px;
}

.wv-button-group > .wv-button--link.wv-button--small,
.wv-button-wrapper > .wv-button--link.wv-button--small {
  padding: 5px 16px;
}

.wv-button-group > .wv-button--link:focus:not(.is-disabled),
.wv-button-group > .wv-button--link:active:not(.is-disabled),
.wv-button-wrapper > .wv-button--link:focus:not(.is-disabled),
.wv-button-wrapper > .wv-button--link:active:not(.is-disabled) {
  box-shadow: 0 0 0 3px #ceeef8;
  outline: 0;
}

.wv-button-group > [class*='wv-button'],
.wv-button-group > .wv-dropdown,
.wv-button-wrapper > [class*='wv-button'],
.wv-button-wrapper > .wv-dropdown {
  margin: 4px;
}

@media (max-width: 480px) {
  .wv-button-group > [class*='wv-button'],
  .wv-button-group > .wv-dropdown,
  .wv-button-wrapper > [class*='wv-button'],
  .wv-button-wrapper > .wv-dropdown {
    margin-left: 0;
    margin-right: 0;
  }
}

.wv-button-group > .wv-tooltip__target > .wv-button--link,
.wv-button-group > .wv-popover > .wv-button--link,
.wv-button-group > .wv-popover__trigger > .wv-button--link,
.wv-button-wrapper > .wv-tooltip__target > .wv-button--link,
.wv-button-wrapper > .wv-popover > .wv-button--link,
.wv-button-wrapper > .wv-popover__trigger > .wv-button--link {
  border-radius: 500px;
  padding: 9px 20px;
}

.wv-button-group > .wv-tooltip__target > .wv-button--link.wv-button--small,
.wv-button-group > .wv-popover > .wv-button--link.wv-button--small,
.wv-button-group > .wv-popover__trigger > .wv-button--link.wv-button--small,
.wv-button-wrapper > .wv-tooltip__target > .wv-button--link.wv-button--small,
.wv-button-wrapper > .wv-popover > .wv-button--link.wv-button--small,
.wv-button-wrapper > .wv-popover__trigger > .wv-button--link.wv-button--small {
  padding: 5px 16px;
}

.wv-button-group
  > .wv-tooltip__target
  > .wv-button--link:focus:not(.is-disabled),
.wv-button-group
  > .wv-tooltip__target
  > .wv-button--link:active:not(.is-disabled),
.wv-button-group > .wv-popover > .wv-button--link:focus:not(.is-disabled),
.wv-button-group > .wv-popover > .wv-button--link:active:not(.is-disabled),
.wv-button-group
  > .wv-popover__trigger
  > .wv-button--link:focus:not(.is-disabled),
.wv-button-group
  > .wv-popover__trigger
  > .wv-button--link:active:not(.is-disabled),
.wv-button-wrapper
  > .wv-tooltip__target
  > .wv-button--link:focus:not(.is-disabled),
.wv-button-wrapper
  > .wv-tooltip__target
  > .wv-button--link:active:not(.is-disabled),
.wv-button-wrapper > .wv-popover > .wv-button--link:focus:not(.is-disabled),
.wv-button-wrapper > .wv-popover > .wv-button--link:active:not(.is-disabled),
.wv-button-wrapper
  > .wv-popover__trigger
  > .wv-button--link:focus:not(.is-disabled),
.wv-button-wrapper
  > .wv-popover__trigger
  > .wv-button--link:active:not(.is-disabled) {
  box-shadow: 0 0 0 3px #ceeef8;
  outline: 0;
}

.wv-button-group > .wv-tooltip__target > [class*='wv-button'],
.wv-button-group > .wv-tooltip__target > .wv-dropdown,
.wv-button-group > .wv-popover > [class*='wv-button'],
.wv-button-group > .wv-popover > .wv-dropdown,
.wv-button-group > .wv-popover__trigger > [class*='wv-button'],
.wv-button-group > .wv-popover__trigger > .wv-dropdown,
.wv-button-wrapper > .wv-tooltip__target > [class*='wv-button'],
.wv-button-wrapper > .wv-tooltip__target > .wv-dropdown,
.wv-button-wrapper > .wv-popover > [class*='wv-button'],
.wv-button-wrapper > .wv-popover > .wv-dropdown,
.wv-button-wrapper > .wv-popover__trigger > [class*='wv-button'],
.wv-button-wrapper > .wv-popover__trigger > .wv-dropdown {
  margin: 4px;
}

@media (max-width: 480px) {
  .wv-button-group > .wv-tooltip__target > [class*='wv-button'],
  .wv-button-group > .wv-tooltip__target > .wv-dropdown,
  .wv-button-group > .wv-popover > [class*='wv-button'],
  .wv-button-group > .wv-popover > .wv-dropdown,
  .wv-button-group > .wv-popover__trigger > [class*='wv-button'],
  .wv-button-group > .wv-popover__trigger > .wv-dropdown,
  .wv-button-wrapper > .wv-tooltip__target > [class*='wv-button'],
  .wv-button-wrapper > .wv-tooltip__target > .wv-dropdown,
  .wv-button-wrapper > .wv-popover > [class*='wv-button'],
  .wv-button-wrapper > .wv-popover > .wv-dropdown,
  .wv-button-wrapper > .wv-popover__trigger > [class*='wv-button'],
  .wv-button-wrapper > .wv-popover__trigger > .wv-dropdown {
    margin-left: 0;
    margin-right: 0;
  }
}

.wv-button-group > .wv-button-wrapper,
.wv-button-wrapper > .wv-button-wrapper {
  margin: 0;
}

@media (max-width: 480px) {
  .wv-button-group,
  .wv-button-wrapper {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .wv-button-group > [class*='wv-button'],
  .wv-button-wrapper > [class*='wv-button'] {
    width: 100%;
  }

  .wv-button-group > [class*='wv-button--with-actions'],
  .wv-button-wrapper > [class*='wv-button--with-actions'] {
    width: 100%;
  }

  .wv-button-group > [class*='wv-button--with-actions'] > .wv-button,
  .wv-button-wrapper > [class*='wv-button--with-actions'] > .wv-button {
    width: 100%;
  }

  .wv-button-group > .wv-dropdown,
  .wv-button-wrapper > .wv-dropdown {
    width: 100%;
  }

  .wv-button-group > .wv-dropdown .wv-dropdown__toggle[class*='wv-button'],
  .wv-button-wrapper > .wv-dropdown .wv-dropdown__toggle[class*='wv-button'] {
    width: 100%;
  }

  .wv-button-group > .wv-dropdown .wv-svg-icon,
  .wv-button-wrapper > .wv-dropdown .wv-svg-icon {
    float: right;
  }

  .wv-button-group > .wv-tooltip__target,
  .wv-button-group > .wv-popover,
  .wv-button-group > .wv-popover__trigger,
  .wv-button-wrapper > .wv-tooltip__target,
  .wv-button-wrapper > .wv-popover,
  .wv-button-wrapper > .wv-popover__trigger {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .wv-button-group > .wv-tooltip__target > [class*='wv-button'],
  .wv-button-group > .wv-popover > [class*='wv-button'],
  .wv-button-group > .wv-popover__trigger > [class*='wv-button'],
  .wv-button-wrapper > .wv-tooltip__target > [class*='wv-button'],
  .wv-button-wrapper > .wv-popover > [class*='wv-button'],
  .wv-button-wrapper > .wv-popover__trigger > [class*='wv-button'] {
    width: 100%;
  }

  .wv-button-group > .wv-tooltip__target > [class*='wv-button--with-actions'],
  .wv-button-group > .wv-popover > [class*='wv-button--with-actions'],
  .wv-button-group > .wv-popover__trigger > [class*='wv-button--with-actions'],
  .wv-button-wrapper > .wv-tooltip__target > [class*='wv-button--with-actions'],
  .wv-button-wrapper > .wv-popover > [class*='wv-button--with-actions'],
  .wv-button-wrapper
    > .wv-popover__trigger
    > [class*='wv-button--with-actions'] {
    width: 100%;
  }

  .wv-button-group
    > .wv-tooltip__target
    > [class*='wv-button--with-actions']
    > .wv-button,
  .wv-button-group
    > .wv-popover
    > [class*='wv-button--with-actions']
    > .wv-button,
  .wv-button-group
    > .wv-popover__trigger
    > [class*='wv-button--with-actions']
    > .wv-button,
  .wv-button-wrapper
    > .wv-tooltip__target
    > [class*='wv-button--with-actions']
    > .wv-button,
  .wv-button-wrapper
    > .wv-popover
    > [class*='wv-button--with-actions']
    > .wv-button,
  .wv-button-wrapper
    > .wv-popover__trigger
    > [class*='wv-button--with-actions']
    > .wv-button {
    width: 100%;
  }

  .wv-button-group > .wv-tooltip__target > .wv-dropdown,
  .wv-button-group > .wv-popover > .wv-dropdown,
  .wv-button-group > .wv-popover__trigger > .wv-dropdown,
  .wv-button-wrapper > .wv-tooltip__target > .wv-dropdown,
  .wv-button-wrapper > .wv-popover > .wv-dropdown,
  .wv-button-wrapper > .wv-popover__trigger > .wv-dropdown {
    width: 100%;
  }

  .wv-button-group
    > .wv-tooltip__target
    > .wv-dropdown
    .wv-dropdown__toggle[class*='wv-button'],
  .wv-button-group
    > .wv-popover
    > .wv-dropdown
    .wv-dropdown__toggle[class*='wv-button'],
  .wv-button-group
    > .wv-popover__trigger
    > .wv-dropdown
    .wv-dropdown__toggle[class*='wv-button'],
  .wv-button-wrapper
    > .wv-tooltip__target
    > .wv-dropdown
    .wv-dropdown__toggle[class*='wv-button'],
  .wv-button-wrapper
    > .wv-popover
    > .wv-dropdown
    .wv-dropdown__toggle[class*='wv-button'],
  .wv-button-wrapper
    > .wv-popover__trigger
    > .wv-dropdown
    .wv-dropdown__toggle[class*='wv-button'] {
    width: 100%;
  }

  .wv-button-group > .wv-tooltip__target > .wv-dropdown .wv-svg-icon,
  .wv-button-group > .wv-popover > .wv-dropdown .wv-svg-icon,
  .wv-button-group > .wv-popover__trigger > .wv-dropdown .wv-svg-icon,
  .wv-button-wrapper > .wv-tooltip__target > .wv-dropdown .wv-svg-icon,
  .wv-button-wrapper > .wv-popover > .wv-dropdown .wv-svg-icon,
  .wv-button-wrapper > .wv-popover__trigger > .wv-dropdown .wv-svg-icon {
    float: right;
  }
}

@media (min-width: 481px) {
  .wv-button-wrapper.wv-button-wrapper--bookended {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
}

.wv-header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
}

.wv-header__title {
  -webkit-flex-grow: 1;
  flex-grow: 1;
}

.wv-header__title [class*='wv-heading'] {
  margin: 0;
}

.wv-header__title .wv-select {
  width: auto;
  margin: 0;
}

@media (max-width: 768px) {
  .wv-header__title .wv-select {
    width: 100%;
    max-width: none;
  }
}

.wv-header__title .wv-select .wv-select__input {
  font-size: 20px;
  line-height: 1.4;
  background: none;
  padding: 8px 32px 6px 16px;
  padding-right: 32px;
}

@media (min-width: 480px) {
  .wv-header__title .wv-select .wv-select__input {
    font-size: 23px;
  }
}

.wv-header__title .wv-select .wv-select__label {
  line-height: 38px;
}

.wv-header__title .wv-select .wv-select__input__icon {
  top: 13px;
}

.wv-header__actions {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
  align-items: center;
  margin: -4px;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.wv-header__actions > .wv-button--link {
  border-radius: 500px;
  padding: 9px 20px;
}

.wv-header__actions > .wv-button--link.wv-button--small {
  padding: 5px 16px;
}

.wv-header__actions > .wv-button--link:focus:not(.is-disabled),
.wv-header__actions > .wv-button--link:active:not(.is-disabled) {
  box-shadow: 0 0 0 3px #ceeef8;
  outline: 0;
}

.wv-header__actions > [class*='wv-button'],
.wv-header__actions > .wv-dropdown {
  margin: 4px;
}

@media (max-width: 768px) {
  .wv-header__actions > [class*='wv-button'],
  .wv-header__actions > .wv-dropdown {
    margin-left: 0;
    margin-right: 0;
  }
}

.wv-header__actions > .wv-tooltip__target > .wv-button--link,
.wv-header__actions > .wv-popover > .wv-button--link,
.wv-header__actions > .wv-popover__trigger > .wv-button--link {
  border-radius: 500px;
  padding: 9px 20px;
}

.wv-header__actions > .wv-tooltip__target > .wv-button--link.wv-button--small,
.wv-header__actions > .wv-popover > .wv-button--link.wv-button--small,
.wv-header__actions > .wv-popover__trigger > .wv-button--link.wv-button--small {
  padding: 5px 16px;
}

.wv-header__actions
  > .wv-tooltip__target
  > .wv-button--link:focus:not(.is-disabled),
.wv-header__actions
  > .wv-tooltip__target
  > .wv-button--link:active:not(.is-disabled),
.wv-header__actions > .wv-popover > .wv-button--link:focus:not(.is-disabled),
.wv-header__actions > .wv-popover > .wv-button--link:active:not(.is-disabled),
.wv-header__actions
  > .wv-popover__trigger
  > .wv-button--link:focus:not(.is-disabled),
.wv-header__actions
  > .wv-popover__trigger
  > .wv-button--link:active:not(.is-disabled) {
  box-shadow: 0 0 0 3px #ceeef8;
  outline: 0;
}

.wv-header__actions > .wv-tooltip__target > [class*='wv-button'],
.wv-header__actions > .wv-tooltip__target > .wv-dropdown,
.wv-header__actions > .wv-popover > [class*='wv-button'],
.wv-header__actions > .wv-popover > .wv-dropdown,
.wv-header__actions > .wv-popover__trigger > [class*='wv-button'],
.wv-header__actions > .wv-popover__trigger > .wv-dropdown {
  margin: 4px;
}

@media (max-width: 768px) {
  .wv-header__actions > .wv-tooltip__target > [class*='wv-button'],
  .wv-header__actions > .wv-tooltip__target > .wv-dropdown,
  .wv-header__actions > .wv-popover > [class*='wv-button'],
  .wv-header__actions > .wv-popover > .wv-dropdown,
  .wv-header__actions > .wv-popover__trigger > [class*='wv-button'],
  .wv-header__actions > .wv-popover__trigger > .wv-dropdown {
    margin-left: 0;
    margin-right: 0;
  }
}

.wv-header__actions > .wv-button-wrapper {
  margin: 0;
}

@media (max-width: 768px) {
  .wv-header__actions {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .wv-header__actions > [class*='wv-button'] {
    width: 100%;
  }

  .wv-header__actions > [class*='wv-button--with-actions'] {
    width: 100%;
  }

  .wv-header__actions > [class*='wv-button--with-actions'] > .wv-button {
    width: 100%;
  }

  .wv-header__actions > .wv-dropdown {
    width: 100%;
  }

  .wv-header__actions > .wv-dropdown .wv-dropdown__toggle[class*='wv-button'] {
    width: 100%;
  }

  .wv-header__actions > .wv-dropdown .wv-svg-icon {
    float: right;
  }

  .wv-header__actions > .wv-tooltip__target,
  .wv-header__actions > .wv-popover,
  .wv-header__actions > .wv-popover__trigger {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .wv-header__actions > .wv-tooltip__target > [class*='wv-button'],
  .wv-header__actions > .wv-popover > [class*='wv-button'],
  .wv-header__actions > .wv-popover__trigger > [class*='wv-button'] {
    width: 100%;
  }

  .wv-header__actions
    > .wv-tooltip__target
    > [class*='wv-button--with-actions'],
  .wv-header__actions > .wv-popover > [class*='wv-button--with-actions'],
  .wv-header__actions
    > .wv-popover__trigger
    > [class*='wv-button--with-actions'] {
    width: 100%;
  }

  .wv-header__actions
    > .wv-tooltip__target
    > [class*='wv-button--with-actions']
    > .wv-button,
  .wv-header__actions
    > .wv-popover
    > [class*='wv-button--with-actions']
    > .wv-button,
  .wv-header__actions
    > .wv-popover__trigger
    > [class*='wv-button--with-actions']
    > .wv-button {
    width: 100%;
  }

  .wv-header__actions > .wv-tooltip__target > .wv-dropdown,
  .wv-header__actions > .wv-popover > .wv-dropdown,
  .wv-header__actions > .wv-popover__trigger > .wv-dropdown {
    width: 100%;
  }

  .wv-header__actions
    > .wv-tooltip__target
    > .wv-dropdown
    .wv-dropdown__toggle[class*='wv-button'],
  .wv-header__actions
    > .wv-popover
    > .wv-dropdown
    .wv-dropdown__toggle[class*='wv-button'],
  .wv-header__actions
    > .wv-popover__trigger
    > .wv-dropdown
    .wv-dropdown__toggle[class*='wv-button'] {
    width: 100%;
  }

  .wv-header__actions > .wv-tooltip__target > .wv-dropdown .wv-svg-icon,
  .wv-header__actions > .wv-popover > .wv-dropdown .wv-svg-icon,
  .wv-header__actions > .wv-popover__trigger > .wv-dropdown .wv-svg-icon {
    float: right;
  }
}

.wv-header--page,
.wv-header--page--centered,
.wv-header--section {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
}

@media (max-width: 768px) {
  .wv-header,
  .wv-header--page,
  .wv-header--section {
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }

  .wv-header .wv-header__title,
  .wv-header--page .wv-header__title,
  .wv-header--section .wv-header__title {
    margin-bottom: 16px;
    width: 100%;
  }
}

.wv-header--page,
.wv-header--page--centered {
  margin-top: 4px;
  margin-bottom: 24px;
}

@media only screen and (max-width: 768px) {
  .wv-header--page,
  .wv-header--page--centered {
    margin-bottom: 16px;
  }
}

.wv-header--section,
.wv-header--section--centered {
  margin-bottom: 8px;
}

.wv-header--page--centered,
.wv-header--section--centered {
  text-align: center;
}

.wv-frame--minimal .wv-header__title .wv-heading--title {
  margin: 16px 0;
}

.wv-content {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
}

.wv-content__secondary,
.wv-content__secondary--left,
.wv-content__secondary--right {
  -webkit-flex: 0 0 220px;
  flex: 0 0 220px;
}

.wv-content .wv-content__primary {
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}

.wv-content--centered .wv-content__primary {
  text-align: center;
}

.wv-content--fixed .wv-content__primary {
  -webkit-flex: 0 1 940px;
  flex: 0 1 940px;
}

.wv-content--narrow .wv-content__primary {
  -webkit-flex: 0 1 400px;
  flex: 0 1 400px;
}

.wv-content__secondary--left {
  -webkit-order: -1;
  order: -1;
  margin-right: 40px;
}

.wv-content__secondary--right {
  -webkit-order: 2;
  order: 2;
  margin-left: 40px;
}

html,
body {
  height: 100%;
  margin: 0;
}

html {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

@media screen {
  .wv-frame {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 370px) {
  .wv-frame {
    overflow: hidden;
  }
}

@media screen {
  .wv-frame .wv-frame__global {
    display: none;
  }

  .wv-frame .wv-frame__wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
    min-height: 0;
    transition: left 0.2s ease-in-out;
    position: relative;
  }

  .wv-frame .wv-frame__navigation {
    display: none;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    width: 250px;
    background: #ebeff4;
  }

  .wv-frame .wv-frame__navigation.is-active {
    display: -webkit-flex;
    display: flex;
  }

  .wv-frame .wv-frame__content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-flex: 2 0 0%;
    flex: 2 0 0%;
    background: #fff;
    overflow: auto;
  }

  .wv-frame .wv-frame__content__header {
    display: none;
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .wv-frame .wv-frame__content__header.is-active {
    display: -webkit-flex;
    display: flex;
  }

  .wv-frame .wv-frame__content__body {
    -webkit-flex: 1 1 0%;
    flex: 1 1 0%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    min-height: 0;
  }
}

@media only screen and (min-width: 769px) {
  .wv-frame .wv-frame__content__body {
    -webkit-flex-direction: row;
    flex-direction: row;
  }
}

@media screen {
  .wv-frame .wv-frame__content__body__main {
    width: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    -webkit-flex: 1;
    flex: 1;
  }

  .wv-frame .wv-frame__content__body__main__banner {
    background-color: #e0f4fb;
    border-radius: 0 0 8px 8px;
    padding: 0 16px;
    margin-top: -24px;
    margin-bottom: 24px;
    color: #11637e;
  }

  .wv-frame .wv-frame__content__body__main__banner .wv-content {
    -webkit-align-items: center;
    align-items: center;
  }

  .wv-frame .wv-frame__content__body__main__banner__message-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
  }

  .wv-frame .wv-frame__content__body__main__banner__text {
    color: inherit;
  }

  .wv-frame .wv-frame__content__body__main__banner__icon {
    margin-right: 8px;
  }

  .wv-frame .wv-frame__content__body__main__wrapper {
    width: 100%;
    max-width: 1600px;
    height: 100%;
    padding: 24px 12px;
    margin: 0 auto;
    box-sizing: border-box;
  }
}

@media only screen and (min-width: 480px) {
  .wv-frame .wv-frame__content__body__main__wrapper {
    padding: 24px;
  }
}

@media only screen and (min-width: 1400px) {
  .wv-frame .wv-frame__content__body__main__wrapper {
    padding: 24px 64px;
  }
}

@media screen {
  .wv-frame
    .wv-frame__content__body__main__wrapper
    > :last-child:not(.wv-fullscreen-modal) {
    margin-bottom: 64px;
  }

  .wv-frame .wv-frame__content__body__main__wrapper--fixed {
    width: 1008px;
    padding: 24px;
  }

  .wv-frame .wv-frame__content__body__side {
    display: none;
    box-sizing: border-box;
    padding: 16px 16px 0 16px;
    background: white;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
  }
}

@media only screen and (min-width: 769px) {
  .wv-frame .wv-frame__content__body__side {
    min-width: 250px;
    border-right: 1px solid #d4dde3;
    padding: 32px 0;
  }
}

@media screen {
  .wv-frame .wv-frame__content__body__side.is-active {
    display: block;
  }

  .wv-frame
    .wv-frame__content__body__side.is-active
    + .wv-frame__content__body__main
    .wv-frame__content__body__main__wrapper--fixed {
    max-width: 748px;
  }
}

@media only screen and (max-width: 1234px) {
  .wv-frame--app .wv-frame__wrapper {
    left: -250px;
  }

  .wv-frame--app .wv-frame__content {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    left: 0;
  }

  .wv-frame--app .wv-frame__content__header {
    display: -webkit-flex;
    display: flex;
  }

  .wv-frame--app.is-open {
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .wv-frame--app.is-open .wv-frame__wrapper {
    left: 0;
  }

  .wv-frame--app.is-open .wv-frame__content {
    -webkit-transform: none;
    transform: none;
  }
}

@media only screen and (max-width: 1180px) {
  .wv-frame--app.is-open .wv-frame__navigation.is-active {
    display: -webkit-flex;
    display: flex;
  }
}

@media screen {
  .wv-frame--public .wv-frame__content {
    background: white;
  }

  .wv-frame--public .wv-frame__content__body__main__wrapper {
    padding: 16px;
  }

  .wv-frame--minimal .wv-frame__content {
    display: block;
    overflow-y: auto;
  }

  .wv-frame--minimal__body__main__wrapper {
    padding: 0;
  }
}

@media print {
  .wv-frame__navigation,
  .wv-frame__content__header,
  .wv-frame__content__body__side {
    display: none !important;
  }
}

html.wv-theme--partner .wv-partner-chrome__images {
  display: -webkit-flex;
  display: flex;
  height: 64px;
  -webkit-align-items: center;
  align-items: center;
  box-sizing: border-box;
  padding-top: 8px;
}

html.wv-theme--partner .wv-partner-chrome__partner-logo-brand {
  -webkit-flex: 1;
  flex: 1;
  height: 56px;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
}

/* html.wv-theme--partner .wv-partner-chrome__partner-logo-brand--rbc {
    background-image: url("images/rbc-logo.svg")
}

html.wv-theme--partner .wv-partner-chrome__partner-logo-brand--demo {
    background-image: url("images/demo-logo.png")
}
 */
html.wv-theme--partner .wv-partner-chrome__wave-logo-brand {
  -webkit-flex: 1;
  flex: 1;
  text-align: center;
  padding-left: 8px;
  height: 28px;
}

html.wv-theme--partner
  .wv-business-switcher
  .wv-business-switcher__toggle__logo {
  display: none;
}

html.wv-theme--partner
  .wv-business-switcher
  .wv-business-switcher__toggle__info {
  padding-left: 4px;
  width: 95%;
}

@media only screen and (max-height: 1120px) {
  html.wv-theme--partner .wv-nav__promo .wv-nav__promo__button {
    display: none;
  }

  html.wv-theme--partner .wv-nav__promo .wv-nav__promo__body {
    margin-bottom: 0;
  }
}

@media only screen and (max-height: 1077px) {
  html.wv-theme--partner .wv-nav__promo .wv-nav__promo__body {
    display: none;
  }

  html.wv-theme--partner .wv-nav__promo .wv-nav__promo__title--long {
    display: none;
  }

  html.wv-theme--partner .wv-nav__promo .wv-nav__promo__title,
  html.wv-theme--partner .wv-nav__promo .wv-nav__promo__title--short {
    display: block;
  }
}

@media only screen and (max-height: 1028px) {
  html.wv-theme--partner .wv-nav__promo {
    position: fixed;
    bottom: 70px;
    width: 218px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    min-height: 48px;
    padding: 8px;
    margin: 0;
    margin-left: 16px;
  }

  html.wv-theme--partner .wv-nav__promo .wv-nav__promo__icon--large {
    display: none;
  }

  html.wv-theme--partner .wv-nav__promo .wv-nav__promo__icon--small {
    height: 16px;
    margin-right: 10px;
    display: inline-block;
  }

  html.wv-theme--partner .wv-nav__promo .wv-nav__promo__title,
  html.wv-theme--partner .wv-nav__promo .wv-nav__promo__title--short {
    margin: 10px 0;
  }

  html.wv-theme--partner .wv-nav__promo .wv-nav__promo__body {
    display: none;
  }

  html.wv-theme--partner .wv-nav--primary.has-payments-promo {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 1234px) {
  html.wv-theme--partner .wv-nav__promo {
    bottom: 10px;
  }
}

html.wv-theme--adp {
  overflow: hidden;
  background-image: linear-gradient(to bottom right, #5298b9, #c2bda2);
  background-size: cover;
  background-position: top left;
  background-repeat: no-repeat;
  height: 100%;
}

html.wv-theme--adp body {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: none;
  min-width: 0;
}

html.wv-theme--adp body.standalone-page {
  min-width: 0;
}

html.wv-theme--adp .wv-wrap,
html.wv-theme--adp .wv-wrap__body {
  min-width: 1024px;
  margin: 0 auto;
}

html.wv-theme--adp .wv-wrap--fluid,
html.wv-theme--adp .wv-wrap--fluid .wv-wrap__body {
  min-width: 320px;
}

html.wv-theme--adp .wv-wrap {
  height: 100%;
  position: relative;
  box-sizing: border-box;
}

html.wv-theme--adp .wv-wrap__body {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 90px;
  bottom: 0;
  background: white;
}

html.wv-theme--adp .wv-wrap__topbar {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  -webkit-align-items: center;
  align-items: center;
  margin: 0 auto;
  padding: 32px 0 20px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
}

html.wv-theme--adp .wv-wrap__topbar__panel {
  -webkit-flex: 0 0 50%;
  flex: 0 0 50%;
}

html.wv-theme--adp .wv-wrap__topbar__panel + .wv-wrap__topbar__panel {
  text-align: right;
}

/* html.wv-theme--adp .wv-wrap__logo-adp {
    display: block;
    width: 200px;
    height: 45px;
    background-image: url("images/partners/adp/run-logo.png");
    background-size: contain;
    background-repeat: no-repeat;
    margin: 4px 0 0 12px
}
 */
html.wv-theme--adp .wv-wrap__logo-wave {
  margin-right: 12px;
}

html.wv-theme--adp .wv-frame {
  background: white;
}

html.wv-theme--adp .wv-business-switcher .wv-business-switcher__toggle__logo {
  display: none;
}

html.wv-theme--adp .wv-business-switcher .wv-business-switcher__toggle__info {
  padding-left: 4px;
  width: 95%;
}

@media only screen and (max-height: 768px) {
  html.wv-theme--adp .wv-wrap__topbar {
    padding-top: 16px;
  }

  html.wv-theme--adp .wv-wrap__body {
    top: 55px;
  }

  html.wv-theme--adp .wv-wrap__logo-adp {
    width: 137px;
    height: 30px;
  }

  html.wv-theme--adp .wv-wrap__logo-wave {
    width: 120px;
    height: 32px;
  }
}

html.wv-theme--adp .wv-footer--app {
  top: 55px;
}

@media only screen and (min-height: 769px) {
  html.wv-theme--adp .wv-footer--app {
    top: 90px;
  }
}

@media only screen and (max-width: 1234px) {
  html.wv-theme--adp .wv-footer--app {
    right: 20px;
  }
}

@media only screen and (max-width: 1104px) {
  html.wv-theme--adp .wv-footer--app {
    right: 0;
  }
}

@media only screen and (max-width: 1025px) {
  html.wv-theme--adp .wv-nav__promo--topbar {
    display: none;
  }
}

@media only screen and (min-width: 1480px) {
  html.wv-theme--adp .wv-wrap,
  html.wv-theme--adp .wv-wrap__topbar,
  html.wv-theme--adp .wv-wrap__body {
    max-width: 1480px;
  }
}

@media only screen and (max-width: 1480px) {
  html.wv-theme--adp .wv-wrap__topbar,
  html.wv-theme--adp .wv-wrap__body {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media only screen and (min-width: 1104px) {
  html.wv-theme--adp .wv-wrap__body {
    overflow: hidden;
  }
}

@media only screen and (max-width: 1104px) {
  html.wv-theme--adp body {
    overflow-x: scroll;
  }

  html.wv-theme--adp .wv-wrap__topbar,
  html.wv-theme--adp .wv-wrap__body {
    margin-left: 0;
    margin-right: 0;
  }
}

@media only screen and (max-height: 1145px) {
  html.wv-theme--adp .wv-nav__promo .wv-nav__promo__button {
    display: none;
  }

  html.wv-theme--adp .wv-nav__promo .wv-nav__promo__body {
    margin-bottom: 0;
  }
}

@media only screen and (max-height: 1105px) {
  html.wv-theme--adp .wv-nav__promo .wv-nav__promo__body {
    display: none;
  }

  html.wv-theme--adp .wv-nav__promo .wv-nav__promo__title--long {
    display: none;
  }

  html.wv-theme--adp .wv-nav__promo .wv-nav__promo__title,
  html.wv-theme--adp .wv-nav__promo .wv-nav__promo__title--short {
    display: block;
  }
}

@media only screen and (max-height: 1055px) {
  html.wv-theme--adp .wv-nav__promo {
    position: fixed;
    bottom: 70px;
    width: 218px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    min-height: 48px;
    padding: 8px;
    margin: 0;
    margin-left: 16px;
  }

  html.wv-theme--adp .wv-nav__promo .wv-nav__promo__icon--large {
    display: none;
  }

  html.wv-theme--adp .wv-nav__promo .wv-nav__promo__icon--small {
    height: 16px;
    margin-right: 10px;
    display: inline-block;
  }

  html.wv-theme--adp .wv-nav__promo .wv-nav__promo__title,
  html.wv-theme--adp .wv-nav__promo .wv-nav__promo__title--short {
    margin: 10px 0;
  }

  html.wv-theme--adp .wv-nav__promo .wv-nav__promo__body {
    display: none;
  }

  html.wv-theme--adp .wv-nav--primary.has-payments-promo {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 1234px) {
  html.wv-theme--adp .wv-nav__promo {
    bottom: 10px;
  }
}

html.wv-theme--adp .wv-nav__promo {
  position: absolute;
  width: 220px;
}

@media print {
  .wv-wrap__topbar {
    display: none !important;
  }
}
