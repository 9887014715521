@keyframes panel-slide-up {

    0%,
    30% {
        margin-top: 8px;
        opacity: 0
    }

    to {
        margin-top: 0;
        opacity: 1
    }
}

@keyframes panel-slide-down {

    0%,
    30% {
        margin-top: -8px;
        opacity: 0
    }

    to {
        margin-top: 0;
        opacity: 1
    }
}

@keyframes panel-slide-left {

    0%,
    30% {
        margin-left: 8px;
        opacity: 0
    }

    to {
        margin-left: 0;
        opacity: 1
    }
}

@keyframes panel-slide-right {

    0%,
    30% {
        margin-left: -8px;
        opacity: 0
    }

    to {
        margin-left: 0;
        opacity: 1
    }
}

.step-badge {
    color: #c6cccd;
    float: left;
    font-size: 25px;
    line-height: 50px;
    margin-right: 18px;
    text-align: center;
    border-width: 2px
}

.step-badge__inner {
    background: #fff;
    border: 2px solid #136acd;
    border-radius: 50%;
    color: #136acd;
    width: 50px;
    height: 50px;
    line-height: 46px;
    box-sizing: border-box
}

.step-badge__inner--disabled {
    background: #fff;
    border-color: #c6cccd;
    color: #c6cccd;
    line-height: 34px
}

.step-badge__inner--checked {
    color: #fff;
    background: #136acd;
    border-color: #136acd;
    line-height: 38px
}

.step-badge__inner--checked,
.step-badge__inner--disabled {
    height: 40px;
    width: 40px;
    margin: 0 6px;
    border-width: 2px;
    border-style: solid;
    line-height: 36px
}

.step-badge__inner--skipped {
    background: #dcdfe0;
    border: 2px solid #dcdfe0;
    color: #fff;
    height: 40px;
    line-height: 38px;
    margin: 5px 5px 0 6px;
    width: 40px
}

.step-badge.step-badge--small .step-badge__inner {
    height: 40px;
    width: 40px;
    margin: 0 6px;
    border-width: 2px;
    border-style: solid;
    display: flex;
    justify-content: center;
    align-items: center
}