@keyframes panel-slide-up {

    0%,
    30% {
        margin-top: 8px;
        opacity: 0
    }

    to {
        margin-top: 0;
        opacity: 1
    }
}

@keyframes panel-slide-down {

    0%,
    30% {
        margin-top: -8px;
        opacity: 0
    }

    to {
        margin-top: 0;
        opacity: 1
    }
}

@keyframes panel-slide-left {

    0%,
    30% {
        margin-left: 8px;
        opacity: 0
    }

    to {
        margin-left: 0;
        opacity: 1
    }
}

@keyframes panel-slide-right {

    0%,
    30% {
        margin-left: -8px;
        opacity: 0
    }

    to {
        margin-left: 0;
        opacity: 1
    }
}

.invoice-view-create-section__draft-options {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fcfbe3;
    margin: -17px -17px 16px;
    padding: 15px;
}

.invoice-view-create-section__box__header {
    display: flex;
    align-items: center
}

.invoice-view-create-section__box__body {
    padding-left: 70px
}

.invoice-view-create-section__box__options {
    display: flex;
    align-items: center;
    flex: 1
}

.invoice-view-create-section__box__options__create-info {
    flex: 1
}

.invoice-view-create-section__box__options__create-info__label .wv-heading--subtitle {
    margin: 0
}

.invoice-view-create-section__box__options__edit-button {
    margin-left: 10px
}

.invoice-view-create-section .wv-box__content {
    overflow: hidden
}

.invoice-view-create-section.variant .invoice-view-create-section__draft-options {
    border-radius: 8px 8px 0 0;
    border: 1px solid #b2c2cd
}