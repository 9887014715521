.payment-success__check {
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment-success__heading {
  text-align: center;
}

.payment-success__info {
  display: flex;
}

.payment-success__info__icon {
  display: flex;
  align-items: center;
  flex: none;
  justify-content: center;
  margin-right: 20px;
}

.payment-success__info__icon__inner {
  height: 50px;
  width: auto;
}

.payment-success__info__text {
  display: flex;
  align-items: center;
}
