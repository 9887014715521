.invoice-template-taxes__row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.invoice-template-taxes__row__amount {
  margin-left: 30px;
  min-width: 125px;
}

.invoice-template-taxes__divider--small-margin .wv-divider {
  margin: 10px auto !important;
}
