@keyframes panel-slide-up {

    0%,
    30% {
        margin-top: 8px;
        opacity: 0
    }

    to {
        margin-top: 0;
        opacity: 1
    }
}

@keyframes panel-slide-down {

    0%,
    30% {
        margin-top: -8px;
        opacity: 0
    }

    to {
        margin-top: 0;
        opacity: 1
    }
}

@keyframes panel-slide-left {

    0%,
    30% {
        margin-left: 8px;
        opacity: 0
    }

    to {
        margin-left: 0;
        opacity: 1
    }
}

@keyframes panel-slide-right {

    0%,
    30% {
        margin-left: -8px;
        opacity: 0
    }

    to {
        margin-left: 0;
        opacity: 1
    }
}

.invoice-payment-reminders-wrapper .wv-divider {
    margin: 20px auto
}

.invoice-payment-reminders-wrapper .wv-divider.small-bottom-margin {
    margin-bottom: 0
}

.invoice-payment-reminders-wrapper .customer-email-notification {
    margin-top: 13px
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders {
    margin-top: 18px
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders .reminders-header {
    display: flex;
    align-items: center;
    margin-bottom: 10.4px
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders .reminders-header__description {
    color: #4d6575;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders .reminders-header__tooltip-description {
    color: #4d6575;
    font-weight: 600;
    margin-top: .8px;
    margin-bottom: 0;
    margin-right: 4.8px
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders .reminders-header__alert {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    fill: #4d6575
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders .wv-button--link {
    margin-left: 0;
    text-align: left
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders__disabled {
    margin-top: 12px;
    margin-bottom: -10px
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders .invoice-payment-reminder-checkbox label.wv-checkbox {
    padding: 16px 0 16px 12px;
    width: 174px;
    border: 1px solid #b2c2cd;
    border-radius: 4px;
    box-sizing: border-box
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders .invoice-payment-reminder-checkbox label.wv-checkbox.is-disabled {
    background: #f1f3f3;
    border: 1px solid #c6cccd
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders .invoice-payment-reminder-checkbox label.wv-checkbox .wv-form-field__label {
    margin-right: 0
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders .invoice-payment-reminder-checkbox label.wv-checkbox .wv-radiocheck--faux {
    margin-top: 2px
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders .invoice-payment-reminder-checkbox label.wv-checkbox:not(.is-disabled) .wv-radiocheck--faux {
    background: none;
    border-color: #136acd
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders .invoice-payment-reminder-checkbox label.wv-checkbox:not(.is-disabled) .wv-radiocheck--faux:after {
    border-bottom: 2px solid #136acd;
    border-left: 2px solid #136acd
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders .invoice-payment-reminder-checkbox__spinner {
    position: absolute;
    top: 17px;
    left: 142px
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders__reminders {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders__reminders--disabled {
    opacity: .5
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders__reminders__row {
    display: flex;
    flex-flow: row nowrap
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders__reminders__row__item {
    display: flex;
    flex-flow: row nowrap;
    flex-grow: 1;
    flex-basis: 0;
    flex-direction: column
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders__reminders__row__item__reminder {
    position: relative;
    display: inline-block;
    margin-right: 5px
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders__reminders__row__item__reminder__spinner {
    position: absolute;
    top: -2px;
    left: -26px
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders__reminders__row__item__reminder__checkbox-label--past,
.invoice-payment-reminders-wrapper .invoice-payment-reminders__reminders__row__item__reminder__checkbox-label--sent {
    opacity: .5
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders__reminders__row__item .wv-form-element {
    display: inline-block
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders__reminders__row__item .wv-badge {
    margin-left: 6px;
    position: relative;
    top: -1px
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders__reminders__row__item .wv-text--block-label {
    margin-bottom: 5px
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders__tooltip-wrapper {
    display: inline-block
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders__description {
    margin-top: 10px
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders__description .wv-button--link {
    border: 0;
    vertical-align: top
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders__error {
    margin-top: 12px
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders__previously-sent {
    margin-top: 20px;
    color: #4d6575;
    display: flex;
    align-items: center;

    .mat-icon {

        font-size: 20px !important;
        width: 20px !important;
        height: 20px !important;
        min-width: 20px !important;
        min-height: 20px !important;
        line-height: 20px !important;
    }
}


.invoice-payment-reminders-wrapper .invoice-payment-reminders__previously-sent svg {
    height: 18px;
    margin-bottom: -3px;
    margin-right: 6px;
    width: 18px
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders .reminders-row-before-due-date {
    margin-top: 10px
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders .top-margin-align {
    margin-top: 10px;
    display: flex;
    align-items: center;
    height: 25px
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders .top-margin-align .reminders-header-item {
    display: inline-block;
    vertical-align: middle;
    margin-right: 9px
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders .top-margin-align .reminders-header-item.add-button {
    color: #136acd;
    margin-right: 6px !important
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders .top-margin-align .reminders-header-item.smaller-right-margin {
    margin-right: 4px
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders .top-margin-align .reminders-header-item.wave-text-top-bottom-margin {
    margin-top: 0;
    margin-bottom: 0
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders__skipped {
    display: flex;
    align-items: center;
    margin-bottom: 8px
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders__skipped svg {
    height: 18px;
    margin-right: 7px;
    width: 18px
}

.invoice-payment-reminders-wrapper .invoice-payment-reminders__skipped__description {
    margin-top: 0;
    margin-bottom: 0
}