#svgBisBody0,
#svgBisBody1,
#svgBisBody2 {
  opacity: 1;
}

.bisBodyShow {
  opacity: 1 !important;
}
.agr-text {
  font-family: Calibri;
  fill: #577e5d;
  fill-opacity: 1;
}
.cmr-rect,
.eur1-rect,
.atr-rect,
.inf4-rect {
  fill: #aaa;
  stroke: #000000;
  fill-opacity: 0;
  paint-order: markers fill stroke;
  stroke-dasharray: none;
  stroke-linejoin: miter;
  display: inline;
}

.eur1-rect {
  stroke-width: 1;
}
.inf4-rect {
  stroke-width: 0.2;
}

.active {
  .rect,
  .svg-path,
  .bis-rect,
  .bis-path,
  .eur1-rect,
  .inf4-rect,
  .eur1-path,
  .cmr-rect,
  .cmr-path,
  .agr-rect,
  .agr-path,
  .atr-rect,
  .atr-path {
    fill: #aaa;
    fill-opacity: 0.3;
  }
}

.activeError {
  .rect,
  .bis-rect,
  .eur1-rect,
  .inf4-rect,
  .cmr-rect,
  .agr-rect {
    stroke: red;
    stroke-opacity: 1;
    transition: 0.2s;
  }

  .svg-text,
  .bis-text,
  .eur1-text,
  .cmr-text,
  .agr-text {
    fill: red;
    fill-opacity: 0.8;
  }

  .svg-path,
  .bis-path,
  .eur1-path,
  .cmr-path,
  .agr-path {
    /* fill: red; */
    stroke: red;
    transition: 0.2s;
  }
}

.activeFocus {
  .rect,
  .bis-rect,
  .inf4-rect {
    fill: #aaa;
    stroke: #000000;
    stroke-opacity: 1;
    stroke-width: 0.5;
    transition: 0.2s;
    fill-opacity: 0.5;
  }
  .inf4-rect {
    stroke-width: 0.75;
  }

  .svg-text,
  .bis-text {
    color: #000;
    font-weight: 600;
    fill-opacity: 0.8;
    fill: #000;
  }

  .svg-path,
  .bis-path {
    fill: #aaa;
    stroke-width: 0.5 !important;
    transition: 0.2s;
    stroke-opacity: 1;
    fill-opacity: 0.5;
  }

  .eur1-rect,
  .cmr-rect,
  .agr-rect,
  .atr-rect {
    fill: #aaa;
    stroke: #000000;
    stroke-opacity: 1;
    stroke-width: 2.5 !important;
    transition: 0.2s;
  }

  .cmr-rect {
    stroke: red;
    stroke-width: 0.75 !important;
  }

  .eur1-text,
  .cmr-text,
  .atr-text {
    font-weight: 600;
  }

  .eur1-path,
  .cmr-path,
  .agr-path {
    fill: #aaa;
    stroke-width: 2.5 !important;
    transition: 0.2s;
  }

  .agr-text {
    fill: #000 !important;
    font-weight: 500;
  }
}

@media print {
  .svg-path,
  .cmr-path,
  .cmr-rect,
  .rect {
    stroke: #000000 !important;
  }
  .svg-text,
  .bis-text,
  .cmr-text {
    fill: #000000 !important;
    fill-opacity: 1 !important;
  }
  .cmr-watermark {
    opacity: 0.25 !important;
  }
}
