@keyframes panel-slide-up {
  0%,
  30% {
    margin-top: 8px;
    opacity: 0;
  }

  to {
    margin-top: 0;
    opacity: 1;
  }
}

@keyframes panel-slide-down {
  0%,
  30% {
    margin-top: -8px;
    opacity: 0;
  }

  to {
    margin-top: 0;
    opacity: 1;
  }
}

@keyframes panel-slide-left {
  0%,
  30% {
    margin-left: 8px;
    opacity: 0;
  }

  to {
    margin-left: 0;
    opacity: 1;
  }
}

@keyframes panel-slide-right {
  0%,
  30% {
    margin-left: -8px;
    opacity: 0;
  }

  to {
    margin-left: 0;
    opacity: 1;
  }
}

.invoice-add {
  margin-bottom: 18px !important;
  margin: 0 auto;
  width: 100%;
}

.invoice-add__page-spinner {
  height: 100%;
}

.invoice-add .secondary-nav-settings-toggle {
  float: right;
}

.invoice-add .secondary-nav-settings-toggle .wv-dropdown__menu {
  left: auto;
  right: 0;
}

.invoice-add .secondary-nav-settings-toggle .wv-dropdown__menu__item {
  text-transform: capitalize;
}

.invoice-add__actions {
  margin-top: 15px;
}

.invoice-add__actions--bottom .wv-dropdown__menu--right {
  top: auto;
  bottom: 100% !important;
}

.invoice-add__preview {
  margin-top: 24px;
}

.invoice-add__body {
  margin-top: 12px;
}

.invoice-add__body__center {
  padding: 15px;
}

.invoice-add__body__top-form {
  display: flex;
}

.invoice-add__body__top-form__customer {
  width: 61%;
}

.invoice-add__body__top-form__padding {
  width: 3%;
}

.invoice-add__body__top-form__metadata {
  width: 36%;
}

.invoice-add__body__items {
  margin-top: 10px;
  position: relative;
}

.invoice-add__body__items__customize-tab {
  display: inline-block;
  margin-left: 17px;
  background: #ecf0f3;
  padding: 6px 12px;
  border-radius: 6px 6px 0 0;
}

.invoice-add__body__items__customize-tab__icon {
  color: #136acd;
  margin-top: 2px;
  margin-right: 8px;
}

.invoice-add__body__items__customize-tab__button {
  vertical-align: top;
}

.invoice-add__body__items > .wv-table__body .wv-table__row:last-child {
  border-bottom-color: #d74242;
}

.invoice-add__body .error-banner-item-title {
  text-transform: capitalize;
}

.invoice-add.guided-variant {
  align-items: center;
  bottom: 72px;
  display: flex;
  flex-direction: column;
  left: 250px;
  margin-bottom: 0;
  overflow-y: auto;
  position: absolute;
  right: 0;
  top: 0;
}

.invoice-add.guided-variant .invoice-add__header {
  margin-top: 24px;
  width: 960px;
}

.invoice-add__preview__message {
  width: 80%;
}

.invoice-preview__body {
  width: 860px;
}

@media screen and (max-width: 1250px) {
  .invoice-add.guided-variant .invoice-add__header {
    left: 0;
    padding-left: 30px;
    position: absolute;
  }
}

.invoice-add.guided-variant .invoice-add__body {
  width: 960px;
}

@media screen and (min-width: 1250px) {
  .invoice-add.guided-variant .invoice-add__body {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (max-width: 1250px) {
  .invoice-add.guided-variant .invoice-add__body {
    left: 0;
    overflow-x: scroll;
    padding-left: 24px;
    padding-right: 24px;
    position: absolute;
    right: 0;
    top: 70px;
  }
}

.invoice-add-back-button {
  display: flex;
  align-items: center;
}
