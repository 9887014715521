.invoice-item-table {
    margin-left: -16px;
    margin-right: -16px
}

.invoice-item-table__column-title {
    overflow: hidden
}

.invoice-item-table .wv-table__cell,
.invoice-item-table .wv-table__cell--amount {
    overflow: visible !important;
    position: relative;
    background-color: inherit
}

.invoice-item-table__visibility-icon {
    position: absolute;
    top: 2px
}

.invoice-item-table__visibility-icon img {
    width: 24px
}

.invoice-item-table .wv-table__body>.wv-table__row .wv-table__body>.wv-table__row:first-child .wv-table__cell.invoice-item-table__item-name.read-only {
    padding-top: 26px
}

.invoice-item-table .wv-table__cell--amount .invoice-item-table__visibility-icon {
    right: 0
}

.invoice-item-table .wv-table,
.invoice-item-table .wv-table--plain {
    table-layout: fixed
}

.invoice-item-table>.wv-table>.wv-table__body>.wv-table__row>.wv-table__cell {
    padding: 0
}

.invoice-item-table>.wv-table>.wv-table__body>.wv-table__row:last-child .wv-button--link {

    padding: 13px 15px;
    margin: 0
}

.invoice-item-table>.wv-table>.wv-table__body>.wv-table__row:last-child .wv-button--link:focus {
    background: #f1f3f3;
    text-decoration: none
}

.invoice-item-table>.wv-table>.wv-table__body>.wv-table__row:last-child .wv-button--link:hover {
    background: #f1f3f3;
    text-decoration: none
}


.invoice-item-table .wv-table,
.invoice-item-table .wv-table__footer,
.invoice-item-table .wv-table__footer>.wv-table__row {
    border-bottom: 0
}

.invoice-item-table .wv-select {
    background: inherit
}

/* .invoice-item-table .wv-select>:first-child {
    background: #fff
} */
.invoice-item-table>.wv-table>.wv-table__body>.wv-table__row:not(.is-selected) {
    background-color: #fff
}

.invoice-item-table .wv-table__body>.wv-table__row .wv-input,
.invoice-item-table .wv-table__body>.wv-table__row .wv-textarea {
    width: 100%
}

.invoice-item-table .wv-table__body>.wv-table__row .wv-table__body>.wv-table__row:first-child .wv-table__cell:first-child,
.invoice-item-table .wv-table__body>.wv-table__row .wv-table__body>.wv-table__row:first-child .wv-table__cell:last-child {
    padding-left: 0;
    padding-right: 0
}

.invoice-item-table .wv-table__body>.wv-table__row .wv-table__body>.wv-table__row:first-child .wv-table__cell--amount:first-child,
.invoice-item-table .wv-table__body>.wv-table__row .wv-table__body>.wv-table__row:first-child .wv-table__cell:first-child {
    padding-top: 25px
}

.invoice-item-table .wv-table__body>.wv-table__row .wv-table__body>.wv-table__row:first-child .wv-table__cell--amount:nth-child(6),
.invoice-item-table .wv-table__body>.wv-table__row .wv-table__body>.wv-table__row:first-child .wv-table__cell:nth-child(6) {
   /*  padding-top: 25px */
}

.invoice-item-table .wv-table__body>.wv-table__row .wv-table__body>.wv-table__row:first-child .wv-table__cell--amount:nth-child(7),
.invoice-item-table .wv-table__body>.wv-table__row .wv-table__body>.wv-table__row:first-child .wv-table__cell:nth-child(7) {
    padding-top: 22px
}

.invoice-item-table .wv-table__body>.wv-table__row .wv-table__body>.wv-table__row:first-child .wv-table__cell,
.invoice-item-table .wv-table__body>.wv-table__row .wv-table__body>.wv-table__row:first-child .wv-table__cell--amount {
    vertical-align: top
}

.invoice-item-table .wv-table__body>.wv-table__row .wv-table__body>.wv-table__row:first-child .wv-input__message {
    white-space: nowrap
}

.invoice-item-table .wv-table__body>.wv-table__row .wv-table__body>.wv-table__row {
    background-color: inherit
}

.invoice-item-table .wv-table__body>.wv-table__row .wv-table__body>.wv-table__row:last-child>.wv-table__cell:last-child {
    padding-right: 0
}

.invoice-item-table .wv-table__header .wv-table__cell,
.invoice-item-table .wv-table__header .wv-table__cell--amount {
    padding-left: 4px;
    padding-right: 4px
}

.invoice-item-table .wv-table__body .wv-table__cell,
.invoice-item-table .wv-table__body .wv-table__cell--amount {
    padding: 8px 4px
}

.invoice-item-table .icon--drag {
    display: block;
    margin: 0 auto;
    opacity: .7;
    cursor: move;
    position: relative;
    top: 1px
}

.invoice-item-table .icon--drag:hover {
    opacity: 1
}

.invoice-item-table__item-icon {
    position: relative;
    top: 4px;
    margin-right: 8px
}

.invoice-item-table .wv-table__header .wv-table__row {
    background: #ecf0f3
}