.invoice-view-summary {
  display: flex;
  margin-bottom: 20px;
}

.invoice-view-summary__status {
  margin-right: 30px;
}

.invoice-view-summary__status [class^='wv-badge'] {
  min-width: 61px;
  text-align: center;
}

.invoice-view-summary__amount,
.invoice-view-summary__due-date {
  margin-left: 30px;
}

.invoice-view-summary__customer {
  flex-grow: 2;
}

.invoice-view-summary__text-value {
  position: relative;
  top: 3px;
}

.invoice-view-summary__customer__name__link-toggle {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 600px;
  font-size: 23px;
}

.invoice-view-summary__customer__name__link-toggle__icon {
  padding-left: 5px;
  position: relative;
  top: 2px;
}

.invoice-view-summary__customer__edit > button {
  margin-left: 0;
  margin-top: 22px;
}

.invoice-view-summary__customer .wv-popover__actions {
  margin: 0;
}

.invoice-view-summary .invoice-view-summary {
  width: 300px;
}

.invoice-view-summary .address__internal-notes {
  white-space: pre-line;
}
