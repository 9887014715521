@keyframes panel-slide-up {
  0%,
  30% {
    margin-top: 8px;
    opacity: 0;
  }

  to {
    margin-top: 0;
    opacity: 1;
  }
}

@keyframes panel-slide-down {
  0%,
  30% {
    margin-top: -8px;
    opacity: 0;
  }

  to {
    margin-top: 0;
    opacity: 1;
  }
}

@keyframes panel-slide-left {
  0%,
  30% {
    margin-left: 8px;
    opacity: 0;
  }

  to {
    margin-left: 0;
    opacity: 1;
  }
}

@keyframes panel-slide-right {
  0%,
  30% {
    margin-left: -8px;
    opacity: 0;
  }

  to {
    margin-left: 0;
    opacity: 1;
  }
}

.invoice-add-totals__totals {
  display: flex;
  margin: 16px 0;
  justify-content: flex-end;
}

.invoice-add-totals__totals__currency-conversion {
  margin-top: 8px;
}

.invoice-add-totals__totals__amounts {
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-right: 28px;
}

.invoice-add-totals__totals__amounts__line {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  line-height: 40px;
}

.invoice-add-totals__totals__amounts__line__label {
  max-width: 500px;
}

.invoice-add-totals__totals__amounts__line__label__currency-select {
  text-align: left;
  display: inline-block;
  margin-left: 10px;
}

.invoice-add-totals__totals__amounts__line__label__currency-select .wv-select {
  width: 240px;
}

.invoice-add-totals__totals__amounts__line__label__currency-select
  .wv-select
  :not(.is-open)
  > .wv-select__toggle {
  background-color: #fff;
}

.invoice-add-totals__totals__amounts__line__label__currency-select
  .wv-select.is-open {
  box-shadow: none;
}

.invoice-add-totals__totals__amounts__line__label__currency-select
  .wv-select__menu {
  min-width: 240px;
  width: 240px;
}

.invoice-add-totals__totals__amounts__line__amount {
  line-height: 20px;
  margin-left: 1px;
  overflow-wrap: break-word;
  width: 143px;
}

.invoice-add-totals__notes {
  display: flex;
  margin-bottom: 24px;
  justify-content: flex-start;
  font-style: italic;
}
