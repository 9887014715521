@keyframes panel-slide-up {
  0%,
  30% {
    margin-top: 8px;
    opacity: 0;
  }

  to {
    margin-top: 0;
    opacity: 1;
  }
}

@keyframes panel-slide-down {
  0%,
  30% {
    margin-top: -8px;
    opacity: 0;
  }

  to {
    margin-top: 0;
    opacity: 1;
  }
}

@keyframes panel-slide-left {
  0%,
  30% {
    margin-left: 8px;
    opacity: 0;
  }

  to {
    margin-left: 0;
    opacity: 1;
  }
}

@keyframes panel-slide-right {
  0%,
  30% {
    margin-left: -8px;
    opacity: 0;
  }

  to {
    margin-left: 0;
    opacity: 1;
  }
}

.invoice-list-table.tab-all-invoices
  .wv-table__body
  .wv-table__row
  td:nth-child(4)
  .customer-name,
.invoice-list-table.tab-draft
  .wv-table__body
  .wv-table__row
  td:nth-child(4)
  .customer-name,
.invoice-list-table.tab-unpaid
  .wv-table__body
  .wv-table__row
  td:nth-child(5)
  .customer-name,
.invoice-list-table .wv-table__body .wv-table__row .amount-due .wv-text {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}

.invoice-list-table.tab-all-invoices
  .wv-table__body
  .wv-table__row
  td:nth-child(4)
  .customer-name,
.invoice-list-table.tab-draft
  .wv-table__body
  .wv-table__row
  td:nth-child(4)
  .customer-name,
.invoice-list-table.tab-unpaid
  .wv-table__body
  .wv-table__row
  td:nth-child(5)
  .customer-name {
  max-width: 176px;
}

.invoice-list-table .wv-table__body .wv-table__row .amount-due .wv-text {
  max-width: 125px;
}

.invoice-list-table {
  position: relative;
}

.invoice-list-table__action-cell {
  text-align: right;
}

.invoice-list-table td:last-child a:not(.wv-dropdown__menu__link) {
  display: inline;
}

.invoice-list-table__actions-cell-content {
  white-space: nowrap;
}

.invoice-list-table.tab-unpaid .wv-table__header .wv-table__row th:first-child {
  width: 100px;
}

.invoice-list-table.tab-unpaid
  .wv-table__header
  .wv-table__row
  th:nth-child(2) {
  min-width: 138px;
}

.invoice-list-table.tab-unpaid
  .wv-table__header
  .wv-table__row
  th:nth-child(3) {
  min-width: 94px;
}

.invoice-list-table.tab-unpaid
  .wv-table__header
  .wv-table__row
  th:nth-child(4) {
  min-width: 82px;
}

.invoice-list-table.tab-unpaid
  .wv-table__header
  .wv-table__row
  th:nth-child(5) {
  min-width: 176px;
}

.invoice-list-table.tab-unpaid
  .wv-table__header
  .wv-table__row
  th:nth-child(6) {
  width: 125px;
}

.invoice-list-table.tab-unpaid
  .wv-table__header
  .wv-table__row
  th:nth-child(7) {
  min-width: 164px;
}

.invoice-list-table.tab-draft .wv-table__header .wv-table__row th:first-child {
  width: 100px;
}

.invoice-list-table.tab-draft .wv-table__header .wv-table__row th:nth-child(2) {
  min-width: 94px;
}

.invoice-list-table.tab-draft .wv-table__header .wv-table__row th:nth-child(3) {
  min-width: 82px;
}

.invoice-list-table.tab-draft .wv-table__header .wv-table__row th:nth-child(5) {
  width: 125px;
}

.invoice-list-table.tab-draft .wv-table__header .wv-table__row th:nth-child(6) {
  min-width: 164px;
}

.invoice-list-table.tab-all-invoices
  .wv-table__header
  .wv-table__row
  th:first-child {
  width: 100px;
}

.invoice-list-table.tab-all-invoices
  .wv-table__header
  .wv-table__row
  th:nth-child(2) {
  min-width: 94px;
}

.invoice-list-table.tab-all-invoices
  .wv-table__header
  .wv-table__row
  th:nth-child(3) {
  min-width: 82px;
}

.invoice-list-table.tab-all-invoices
  .wv-table__header
  .wv-table__row
  th:nth-child(5),
.invoice-list-table.tab-all-invoices
  .wv-table__header
  .wv-table__row
  th:nth-child(6) {
  width: 125px;
}

.invoice-list-table.tab-all-invoices
  .wv-table__header
  .wv-table__row
  th:nth-child(7) {
  min-width: 164px;
}

.invoice-list-table .anchorSpan {
  text-align: center;
}

.invoice-list-table .wv-badge,
.invoice-list-table .wv-badge--alert,
.invoice-list-table .wv-badge--danger,
.invoice-list-table .wv-badge--default,
.invoice-list-table .wv-badge--info,
.invoice-list-table .wv-badge--muted,
.invoice-list-table .wv-badge--success,
.invoice-list-table .wv-badge--warning {
  text-align: center;
  width: 100px;
}

.invoice-list-table .has-source-badge {
  margin: -8px 0;
}

.invoice-list-table .has-source-badge .source-badge {
  line-height: 16px;
}

.invoice-list-table .has-source-badge .wv-text--hint {
  margin: 0;
}

.invoice-list-table .overdue {
  color: #c22929;
}

.invoice-list-table .align-right {
  text-align: right;
}

.invoice-list-table [class^='wv-table__cell'] > a {
  text-decoration: none;
  color: inherit;
  display: block;
  padding: 8px;
}

.invoice-list-table .has-conversion {
  margin: -8px 0;
}

.invoice-list-table .has-conversion .wv-text--hint {
  display: block;
  margin: 0;
}

.invoice-list-table .wv-table__body .wv-table__row {
  cursor: pointer;
}

.invoice-list-table .wv-table__body .wv-table__row td {
  padding: 0;
}

.invoice-list-table
  .wv-table__body
  .wv-table__row
  td:last-child
  .wv-dropdown__toggle {
  margin-right: 0;
}

.invoice-list-table
  .wv-table__body
  .wv-table__row
  td:last-child
  .wv-text--link {
  position: relative;
  top: 2px;
  padding: 12px 0;
}

.invoice-list-table .wv-table__body .wv-table__row .wv-dropdown {
  margin: 0 8px;
}
