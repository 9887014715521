/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import third party library styles here.
/* ----------------------------------------------------------------------------------------------------- */

// /* Perfect scrollbar */
// @import '~perfect-scrollbar/css/perfect-scrollbar.css';

// /* Quill */
// @import '~quill/dist/quill.snow.css';

//Import syncfusion dropdown styles
//=================================================IMPORTANT ==================================================================
//CPMMENT OUT @import url("https://fonts.googleapis.com/css?family=Roboto:400,500");
//on each of these files for this to work

@import '~@syncfusion/ej2-angular-inputs/styles/material.css';
@import '~@syncfusion/ej2-angular-dropdowns/styles/material.css';
@import '~@syncfusion/ej2-angular-calendars/styles/material.css';
@import '~@syncfusion/ej2-base/styles/material.css';
@import '~@syncfusion/ej2-buttons/styles/material.css';
@import '~@syncfusion/ej2-calendars/styles/material.css';
@import '~@syncfusion/ej2-dropdowns/styles/material.css';
@import '~@syncfusion/ej2-inputs/styles/material.css';
@import '~@syncfusion/ej2-popups/styles/material.css';
@import '~@syncfusion/ej2-lists/styles/material.css';
@import '~@syncfusion/ej2-splitbuttons/styles/material.css';

@import '../assets/waveapps/add.css';
@import '../assets/waveapps/createSection.css';
@import '../assets/waveapps/income-account.css';
@import '../assets/waveapps/invoice-item-table.css';
@import '../assets/waveapps/invoice-list.scss';
@import '../assets/waveapps/main-app.css';
@import '../assets/waveapps/paymentButtons.scss';
@import '../assets/waveapps/paymentReminders.scss';
@import '../assets/waveapps/paymentSection.scss';
@import '../assets/waveapps/paymentSuccess.css';
@import '../assets/waveapps/sendSection.scss';
@import '../assets/waveapps/stepBadge.scss';
@import '../assets/waveapps/summary.scss';
@import '../assets/waveapps/tax.css';
@import '../assets/waveapps/taxes.scss';
@import '../assets/waveapps/totals.css';

@import '../assets/waveapps/tippy.css';

//tippy
@import '~tippy.js/dist/tippy.css';
@import '~tippy.js/animations/shift-away.css';
@import '~tippy.js/themes/material.css';
@import '~tippy.js/themes/light.css';

//toastr
@import '~ngx-toastr/toastr.css';
